import { useEffect, useReducer } from "react";
import { Divider, Drawer, List, Loader, Notification, Stack, Tag, TagGroup, Tooltip, useToaster } from "rsuite"
import apiHelper from "../../Helper/apiHelper";
import { getNotificationElement } from "../../Helper/notificationHelper";
import IMessageViewModel from "../Interfaces/Message/IMessageViewModel";
import messageManagerReducer from "./Reducer";

interface Props {
    showMessage: boolean,
    setShowMessage: (showMessage: boolean) => void,
    onGetData: () => void,
}

const MessageManager = (props: Props) => {
    const { reducer, initialState } = messageManagerReducer();
    const [state, dispatch] = useReducer(reducer, initialState);
    const { getApi } = apiHelper();
    const toaster = useToaster();

    useEffect(() => {
        if (props.showMessage)
            loadData();
    }, [props.showMessage]);

    const loadData = () => {
        setLoading(true);

        getApi<IMessageViewModel[]>('/Message/GetMessages')
            .then(handleGetResponse)
            .catch(handleFormError)
            .finally(() => setLoading(false));
    }

    const setLoading = (loading: boolean) => {
        dispatch({ type: "CHANGE_LOADING", loading: loading });
    }

    const handleGetResponse = (res: IMessageViewModel[]) => {
        dispatch({ type: "ON_GET_DATA", data: res });
        props.onGetData();
    }

    const handleFormError = (error: any) => {
        const message = error.response.status === 401 ? "Email or password is incorrect" : "Server error";

        const notificationElement = getNotificationElement("Load faild", message, "error");
        toaster.push(notificationElement, { placement: 'topEnd' });
    }

    return <Drawer size="sm" dialogClassName="message-drawer-dialog" style={{ boxShadow: 'none' }} open={props.showMessage} onClose={() => props.setShowMessage(false)}>
        <Drawer.Body>
            {state.loading &&
                <Loader backdrop content="loading..." vertical style={{ zIndex: 12 }} />
            }
            {state.data.map(i =>
                <>
                    <Notification type="info" header={i.title} style={{ width: '100%' }}>
                        {i.body}
                        <hr />
                        <TagGroup>
                            <Tag>Send at:{new Date(i.sendDate).toLocaleDateString()}</Tag>
                            {i.recieveDate && <Tag>Recieve at:{new Date(i.recieveDate).toLocaleDateString()}</Tag>}
                        </TagGroup>
                    </Notification>
                    <br />
                </>
            )}
            {state.data.length === 0 &&
                <Notification style={{ width: '100%' }} closable onClose={() => props.setShowMessage(false)}>
                    No data was found...
                </Notification>
            }
        </Drawer.Body>
    </Drawer>
}

export default MessageManager