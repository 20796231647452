import { IOrderPagingFilterViewModel } from "../../Interfaces/Order/IOrderPagingFilterViewModel";
import { OrderState } from "../../Interfaces/Order/OrderState";

const orderManagerReducer = () => {
    interface State {
        selectedId?: number,
        filter: IOrderPagingFilterViewModel,
        changedFilter: boolean,
    }

    type Action =
        | { type: 'REFRESH_DATA'; }
        | { type: 'ON_CHANGE_FILTER'; filter: IOrderPagingFilterViewModel }

    const initialState: State = {
        filter: {
            pageIndex: 1,
            pageSize: 10,
            state: OrderState.New,
        },
        changedFilter: false,
    }

    const reducer = (state: State, action: Action): State => {
        switch (action.type) {
            case 'REFRESH_DATA':
                return initialState;
            case 'ON_CHANGE_FILTER':
                return {
                    ...state,
                    filter: action.filter,
                    changedFilter: true,
                }
        }
    }

    return {
        reducer,
        initialState
    }

}

export default orderManagerReducer;