import { createRef, useEffect, useReducer } from "react";
import { Button, ButtonToolbar, Checkbox, Drawer, Form, FormInstance, Loader, Modal, Placeholder, Schema, useToaster } from "rsuite";
import apiHelper from "../../Helper/apiHelper";
import { getNotificationElement } from "../../Helper/notificationHelper";
import { IAdminModificationViewModel } from "../Interfaces/Admin/IAdminModificationViewModel";
import defineReducer from "./Reducer/defineReducer";

interface Props {
    open: boolean,
    onClose: () => void,
    refreshData: () => void,
    id?: number,
}

const Define = (props: Props) => {
    const formRef = createRef<FormInstance>();
    const { postApi, getApi } = apiHelper();
    const toaster = useToaster();
    const { reducer, initialState } = defineReducer();
    const [state, dispatch] = useReducer(reducer, initialState);
    const isEditMode = (props.id !== undefined && props.id !== 0);

    const model = Schema.Model<IAdminModificationViewModel>({
        email: Schema.Types.StringType().isRequired('Enter Email address').isEmail('Enter a valid Email address'),
        firstName: Schema.Types.StringType().isRequired('Enter Firstname'),
        lastName: Schema.Types.StringType().isRequired('Enter Lastname'),
        isActive: Schema.Types.BooleanType(),
        id: Schema.Types.NumberType(),
    });

    useEffect(() => {
        if (props.open) {
            if (isEditMode) {
                getApi<IAdminModificationViewModel>('/admin/GetModification?id=' + props.id)
                    .then(handleGetResponse)
                    .catch(handleFormError)
                    .finally(() => setLoading(false));
            }
            else {
                dispatch({ type: "ON_ADD_MODE_FORM_VALUE" });
            }
        }
    }, [props.open]);

    const CheckboxValued = (props: any) => {
        const { defaultValue, value, checked, ...rest } = props;
        return <Checkbox defaultChecked={defaultValue} checked={checked} value={value} {...rest} />
    };

    const handleSubmit = () => {
        if (!formRef?.current?.check())
            return;

        setLoading(true);

        if (isEditMode) {
            postApi<string>('/admin/Update', state.formValue)
                .then(handleFormResponse)
                .catch(handleFormError)
                .finally(() => setFormLoading(false));
        }
        else {
            postApi<string>('/admin/Insert', state.formValue)
                .then(handleFormResponse)
                .catch(handleFormError)
                .finally(() => setLoading(false));
        }
    }

    const setLoading = (loading: boolean) => {
        dispatch({ type: "CHANGE_LOADING", loading: loading });
    }

    const setFormLoading = (loading: boolean) => {
        dispatch({ type: "CHANGE_FORM_LOADING", loading: loading });
    }

    const handleChange = (value: IAdminModificationViewModel | any) => {
        dispatch({ type: "ON_CHANGE_FORM_VALUE", formValue: value });
    }

    const handleGetResponse = (res: IAdminModificationViewModel) => {
        dispatch({ type: "ON_GET_FORM_VALUE", formValue: res });
    }

    const clearForm = () => {
        dispatch({ type: "CLEAR_FORM_VALUE" });
    }

    const onClose = () => {
        clearForm();
        props.onClose();
    }

    const handleFormResponse = (res: string) => {
        const notificationElement = getNotificationElement("Insert successful", "", "success");
        toaster.push(notificationElement, { placement: 'topEnd' });

        onClose();
        props.refreshData();
    }

    const handleFormError = (error: any) => {
        const message = error.response.status === 401 ? "Email or password is incorrect" : "Server error";

        const notificationElement = getNotificationElement("Insert faild", message, "error");
        toaster.push(notificationElement, { placement: 'topEnd' });
    }

    return <Modal backdrop="static" open={props.open} onClose={onClose}>
        <Modal.Header>
            <Modal.Title>
                {isEditMode ? "Edit" : "Add"} Admin
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {state.formLoading &&
                <Loader backdrop content="loading..." vertical style={{ zIndex: 12 }} />
            }
            <Form fluid ref={formRef} model={model} onChange={handleChange} onSubmit={handleSubmit} formValue={state.formValue}>
                <Form.Group>
                    <Form.ControlLabel>Email: </Form.ControlLabel>
                    <Form.Control readOnly={isEditMode} name="email" />
                </Form.Group>
                <Form.Group>
                    <Form.ControlLabel>First Name: </Form.ControlLabel>
                    <Form.Control readOnly={isEditMode} name="firstName" />
                </Form.Group>
                <Form.Group>
                    <Form.ControlLabel>Last Name: </Form.ControlLabel>
                    <Form.Control readOnly={isEditMode} name="lastName" />
                </Form.Group>
                <Form.Group>
                    <Form.Control name="isActive" accepter={CheckboxValued} checked={state.formValue.isActive} value={!state.formValue.isActive} block>
                        Active
                    </Form.Control>
                </Form.Group>
                <Form.Group>
                    <ButtonToolbar>
                        <Button appearance="primary" type="submit" loading={state.loading}>Submit</Button>
                    </ButtonToolbar>
                </Form.Group>
            </Form>
        </Modal.Body>
    </Modal>
}

export default Define;

