import { PictorialLanguageOrderPagingFilterViewModel } from "../../Interfaces/PictorialLanguageOrder/PictorialLanguageOrderPagingFilterViewModel";

const coreReducer = () => {
    interface State {
        filter: PictorialLanguageOrderPagingFilterViewModel
    }

    const initialState: State = {
        filter: {
            pageIndex: 1,
            pageSize: 10
        }
    }

    type Action = { type: 'REFEREHS_TABLE'; }

    const reducer = (state: State, action: Action): State => {
        switch (action.type) {
            case "REFEREHS_TABLE":
                return initialState
        }
    }

    return {
        initialState,
        reducer
    }
}

export default coreReducer;