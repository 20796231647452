import { forwardRef } from "react";
import { Checkbox, Form } from "rsuite"

interface Props {
    name: string,
    label: string,
    checkedValue: boolean,
}

const CheckboxValued = (props: any) => {
    const { defaultValue, value, checked, ...rest } = props;
    return <Checkbox defaultChecked={defaultValue} checked={checked} value={value} {...rest} />
};

const FormCheckbox = forwardRef<HTMLDivElement, Props>((props, ref) => {
    const { ...rest } = props;

    return <Form.Group ref={ref}>
        <Form.Control {...rest} name={props.name} label={props.label} accepter={CheckboxValued} checked={props.checkedValue} value={!props.checkedValue}>
            {props.label}
        </Form.Control>
    </Form.Group>
})

export default FormCheckbox;