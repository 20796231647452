import WriteCoGrid from "../Base/Grid/grid";
import { IBlogPagingFilterViewModel } from "../Interfaces/Blog/IBlogPagingFilterViewModel";
import { IBlogViewModel } from "../Interfaces/Blog/IBlogViewModel";

export interface Props {
    filter: IBlogPagingFilterViewModel,
    onRowSelect: (rowData: IBlogViewModel) => void,
}
const BlogsTable = (props: Props) => {

    const { Column, Data } = WriteCoGrid
    const onRowSelect = (rowData: any) => {
        props.onRowSelect(rowData as IBlogViewModel);
    }
    return <WriteCoGrid>
        <Data apiUrl="/blog/Search" filter={props.filter} showSelectableColumn onSelectRow={onRowSelect}>
            <Column dataKey='id' title="Code" width={100} type="regular" />
            <Column dataKey="title" title="Title" width={150} type="regular" />
            <Column dataKey="authorName" title="AuthorName" width={150} type="regular" />
            <Column dataKey="likesCount" title="LikesCount" width={200} type="regular" />
            <Column dataKey="viewsCount" title="ViewsCount" width={200} type="regular" />
            <Column dataKey="isActive" title="IsActive" width={100} type="boolean" />
        </Data>
    </WriteCoGrid>
}

export default BlogsTable;
