import { Loader, InputPicker } from "rsuite";
import { ItemDataType } from "rsuite/esm/@types/common";
import { useEffect, useReducer } from "react";
import GlobalIcon from '@rsuite/icons/Global';
import languageSelectorReducer from "./Reducer/languageSelectorReducer";
import languageHelper from "../../../Helper/LanguageHelper";

const LanguageSelector = ({ onChange, isEditMode = false, value = 0, size }: any) => {
    const { getLanguageItemData } = languageHelper();
    const { reducer, initialState } = languageSelectorReducer();
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        getLanguageItemData(false)
            .then(handleResponse)
    }, [])


    useEffect(() => {
        if (value !== 0)
            dispatch({ type: "CHANGE_VALUE", value: value as number });
        else
            dispatch({ type: "CLEAR_VALUE" });
    }, [value]);

    const handleResponse = (model: ItemDataType[]) => {
        dispatch({ type: "CHANGE_LANGUAGES", languages: model });
    }

    const handleChangeValue = (value: any, event: React.SyntheticEvent) => {
        onChange(value);
    }

    return <InputPicker
        data={state.languages}
        placeholder={!state.loading && "Select languages"}
        block
        size={size}
        readOnly={isEditMode}
        value={state.value}
        onChange={handleChangeValue}
        renderValue={(value) => {
            if (state.loading)
                return <Loader center content="Loading..." />
            else
                return <div>
                    <span style={{ color: 'slategray' }}>
                        <GlobalIcon size="xs" /> Language :
                    </span>{' '}
                    {state.languages.find(i => i.value === value)?.label}
                </div>
        }}
    />
}

export default LanguageSelector; 