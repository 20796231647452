import React, { createRef, useEffect, useReducer } from "react"
import { Button, ButtonToolbar, Form, FormInstance, Loader, Schema, toaster } from "rsuite";
import apiHelper from "../../Helper/apiHelper";
import { EmailBodyParameters } from "../../Helper/Enumeration/EmailBodyParameters";
import { getNotificationElement } from "../../Helper/notificationHelper";
import HtmlEditor from "../Base/Editor/htmlEditor";
import { IConfigurationForgetPasswordConfirmViewModel } from "../Interfaces/Configuration/IConfigurationForgetPasswordConfirmViewModel";
import { IConfigurationSuccessfulPaymentViewModel } from "../Interfaces/Configuration/IConfigurationSuccessfulPaymentViewModel";
import SuccessfulPaymentConfigReducer from "./Reducer/successfulPaymentConfigReducer";
const SuccessfulPaymentEmailConfig = () => {

    const { reducer, initialState } = SuccessfulPaymentConfigReducer();
    const [state, dispatch] = useReducer(reducer, initialState);
    const formRef = createRef<FormInstance>();
    const { getApi, postApi } = apiHelper();

    const setLoading = (loading: boolean) => {
        dispatch({ type: "SET_LOADING", loading: loading })
    }

    useEffect(() => {
        getApi<IConfigurationSuccessfulPaymentViewModel>('/Configuration/GetSuccessfulPaymentEmail')
            .then((res: IConfigurationSuccessfulPaymentViewModel) => {
                dispatch({ type: "ON_GET_DATA", data: res })
            })
            .catch((error: any) => {
                const message = error.response.status === 401 ? "Email or password is incorrect" : "Server error";

                const notificationElement = getNotificationElement("Load failed", message, "error");
                toaster.push(notificationElement, { placement: "topEnd" });
            })
            .finally(() => setLoading(false))
    }, [])

    const bookmarkOptions: string[] = [
        EmailBodyParameters[EmailBodyParameters.UserFullName],
        EmailBodyParameters[EmailBodyParameters.TotalCost],
        EmailBodyParameters[EmailBodyParameters.PaymentId]
    ]

    const setButtonLoading = (loading: boolean) => {
        dispatch({ type: 'SET_BUTTON_LOADING', loading: loading })
    }


    const loadData = () => {
        getApi<IConfigurationForgetPasswordConfirmViewModel>('/Configuration/GetSuccessfulPaymentEmail')
            .then((res: IConfigurationForgetPasswordConfirmViewModel) => {
                dispatch({ type: "ON_GET_DATA", data: res });
            })
            .catch((error: any) => {
                const message = error.response.status === 401 ? "Email or password is incorrect" : "Server error";

                const notificationElement = getNotificationElement("Load faild", message, "error");
                toaster.push(notificationElement, { placement: 'topEnd' });
            })
            .finally(() => setLoading(false));
    }
    const handleSubmit = () => {
        if (!formRef.current?.check())
            return;

        setButtonLoading(true)

        postApi<boolean>('/Configuration/UpdateSuccessfulPaymentEmail', state.data)
            .then((res: boolean) => {
                const notificationElement = getNotificationElement("Update successful", "", "success");
                toaster.push(notificationElement, { placement: 'topEnd' });
            })
            .catch((error: any) => {
                const message = error.response.status === 401 ? "Email or password is incorrect" : "Server error";

                const notificationElement = getNotificationElement("Update faild", message, "error", handleRetry);
                toaster.push(notificationElement, { placement: 'topEnd' });
            })
            .finally(() => setButtonLoading(false))

    }

    const handleRetry = () => {
        loadData();
    }

    const handleChange = (res: any) => {
        const data = (res as any)
        dispatch({ type: 'ON_GET_DATA', data: data })
    }

    const model = Schema.Model<any>({
        body: Schema.Types.StringType(),
        subject: Schema.Types.StringType()
    })
    return <>
        {(state.loading || !!!state.data) && <Loader backdrop content="Loading..." vertical style={{ zIndex: 12 }} />}
        {!!state.data && <Form ref={formRef} model={model} formValue={state.data} onSubmit={handleSubmit} onChange={handleChange}>
            <Form.Group>
                <Form.Control name="subject" placeholder="Subject" />
            </Form.Group>
            <Form.Group>
                <Form.Control name="body" accepter={HtmlEditor} bookmarkOptions={bookmarkOptions} />
            </Form.Group>
            <Form.Group>
                <ButtonToolbar>
                    <Button appearance="primary" type="submit" loading={state.buttonLoading}>save</Button>
                </ButtonToolbar>
            </Form.Group>
        </Form>}
    </>
}

export default SuccessfulPaymentEmailConfig;
