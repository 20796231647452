import { Button, FlexboxGrid, Notification } from "rsuite";
import { MessageType } from "rsuite/esm/Notification/Notification";


export const getNotificationElement = (title: string, message: string, type: MessageType, retry?: () => void) => {
    return <Notification type={type} header={title} closable={!!retry}>
        <p>
            {message}
        </p>
        {!!retry &&
            <FlexboxGrid justify="center">
                <Button onClick={retry}>Retry</Button>
            </FlexboxGrid >
        }
    </Notification>
}