import { useCallback, useEffect, useMemo, useState } from "react";
import { createEditor, Descendant } from 'slate'
import { Editable, RenderElementProps, RenderLeafProps, Slate, withReact, } from "slate-react";
import Toolbar from "./toolbar";
import { withHistory } from "slate-history";
import { LeafElement, RenderElement } from "./element";
import { CustomElement } from "./custom-types";
import elementWrapper from "./elementWrapper";

const HtmlEditor = ({ value, onChange, bookmarkOptions }: any) => {
    const renderElement = useCallback((props: RenderElementProps) => <RenderElement {...props} />, [])
    const renderLeaf = useCallback((props: RenderLeafProps) => <LeafElement {...props} />, [])
    const editor = useMemo(() => withHistory(withReact(createEditor())), [])
    const { serialize, deserialize } = elementWrapper();

    const initialValue = value ? deserialize(value as any) : [
        {
            type: 'paragraph',
            children: [
                {
                    text: '',
                },
            ],
        },
    ]

    const handleChange = (value: Descendant[]) => {
        const html = value.map(i => serialize(i as CustomElement)).join('');
        onChange(html);
    }


    return <Slate editor={editor} onChange={handleChange} value={initialValue as any}>
        <Toolbar bookmarkOptions={bookmarkOptions} />
        <Editable
            autoFocus
            style={{ minHeight: 600 }}
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            spellCheck
            placeholder="Write something..."
        />
    </Slate>
}

export default HtmlEditor;