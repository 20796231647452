import { ItemDataType } from "rsuite/esm/@types/common";
import ITranslationCategoryViewModel from "../Components/Interfaces/TranslationCategory/ITranslationCategoryViewModel";
import apiHelper from "./apiHelper";

const translationCategoryHelper = () => {

    const { getApi } = apiHelper();

    const getTranslationCategoryItemData = async () => {
        const translationCategoryData = await getApi<ITranslationCategoryViewModel[]>('/TranslationCategory/GetAll');

        return translationCategoryData.map(i => {
            const data: ItemDataType = {
                value: i.id,
                label: i.title,
            }

            return data;
        });
    }

    return {
        getTranslationCategoryItemData
    }

}

export default translationCategoryHelper;