import { IUserPagingFilterViewModel } from "../../Interfaces/User/IUserPagingFilterViewModel";

const userManagerReducer = () => {
    interface State {
        filter: IUserPagingFilterViewModel,
        nameFilter?: string,
    }

    type Action =
        | { type: 'CHANGE_NAME_FILTER'; nameFilter: string }

    const initialState: State = {
        filter: {
            pageIndex: 1,
            pageSize: 10,
        },
    }

    const reducer = (state: State, action: Action): State => {
        switch (action.type) {
            case 'CHANGE_NAME_FILTER':
                return {
                    ...state,
                    nameFilter: action.nameFilter,
                    filter: { ...state.filter, name: action.nameFilter },
                }
        }
    }

    return {
        reducer,
        initialState
    }

}

export default userManagerReducer;