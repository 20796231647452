import { ItemDataType } from "rsuite/esm/@types/common";

const languageSelectorReducer = () => {
    interface State {
        languages: ItemDataType[],
        value?: number,
        loading: boolean,
    }

    type Action =
        | { type: 'CHANGE_LANGUAGES'; languages: ItemDataType[] }
        | { type: 'CHANGE_VALUE'; value: number }
        | { type: 'CLEAR_VALUE'; }

    const initialState: State = {
        languages: [],
        loading: true,
    }

    const reducer = (state: State, action: Action): State => {
        switch (action.type) {
            case 'CHANGE_LANGUAGES':
                return {
                    ...state,
                    languages: action.languages,
                    loading: false,
                }
            case 'CHANGE_VALUE':
                return {
                    ...state,
                    value: action.value,
                }
            case 'CLEAR_VALUE': {
                return {
                    ...state,
                    value: undefined,
                }
            }
        }
    }

    return {
        reducer,
        initialState
    }

}

export default languageSelectorReducer;