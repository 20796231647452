import { forwardRef, useEffect, useReducer } from "react";
import { Loader, toaster, Uploader } from "rsuite"
import { FileType } from "rsuite/esm/Uploader";
import apiHelper from "../../../Helper/apiHelper";
import { getNotificationElement } from "../../../Helper/notificationHelper";
import { getToken } from "../../../Helper/TokenHelper";
import imageUploaderReducer from "./Reducer/imageUploaderReducer";
import { Image } from "@rsuite/icons";
import FileRemoveViewModel from "../../Interfaces/File/FileRemoveViewModel";

const ImageUploader = forwardRef(({ onChange }: any) => {
    const token = getToken();
    const { reducer, initialState } = imageUploaderReducer();
    const [state, dispatch] = useReducer(reducer, initialState);
    const { getApiPath, postApi } = apiHelper();

    const postHeader = {
        Authorization: `Bearer ${token}`
    };

    useEffect(() => {
        onChange(state.tempFileName);
    }, [state.tempFileName]);

    const handleUpload = (file: FileType) => {
        enableUploading();

        if (file.blobFile)
            previewFile(file.blobFile, value => {
                setFileInfo(value as string);
            });
    }

    const handleChange = () => {
        if (!state.tempFileName)
            return;

        var fileRemoveViewModel: FileRemoveViewModel = {
            tempFileName: state.tempFileName
        };
        postApi('/File/Delete', fileRemoveViewModel);
    }

    const handleUploadSuccess = (res: any, file: FileType) => {
        const notificationElement = getNotificationElement("Image upload was succesful", "", "success");
        toaster.push(notificationElement, { placement: 'topEnd' });

        onSuccess(res as string);
    }

    const handleUploadError = (error: any) => {
        const message = error.response.status === 401 ? "Email or password is incorrect" : "Server error";

        const notificationElement = getNotificationElement("Image upload faild", message, "error");
        toaster.push(notificationElement, { placement: 'topEnd' });

        onError();
    }

    const previewFile = (file: File, callback: (result: string | ArrayBuffer | null) => void) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            callback(reader.result);
        };
        reader.readAsDataURL(file);
    }

    const enableUploading = () => {
        dispatch({ type: "ENABLE_UPLOADING" });
    }

    const setFileInfo = (fileInfo: string) => {
        dispatch({ type: "SET_FILE_INFO", fileInfo });
    }

    const onSuccess = (tempFileName: string) => {
        dispatch({ type: "ON_SUCCESS", tempFileName });
    }

    const onError = () => {
        dispatch({ type: "ON_ERROR" });
    }


    return <Uploader
        action={getApiPath() + "/File/Upload"}
        fileListVisible={false}
        listType="picture"
        headers={postHeader}
        onUpload={handleUpload}
        onSuccess={handleUploadSuccess}
        onError={handleUploadError}
        onChange={handleChange}
    >
        <div style={{ width: 150, height: 150, display: "flex", alignItems: "center", justifyContent: "center" }}>
            {state.uploading && <Loader backdrop center />}
            {state.fileInfo ? (
                <img src={state.fileInfo} width="100%" height="100%" />
            ) : (
                <Image style={{ fontSize: 80 }} />
            )}
        </div>
    </Uploader>
})

export default ImageUploader;