import { initializeApp } from "firebase/app";
import { getMessaging, getToken, MessagePayload, onMessage } from "firebase/messaging";
import { useEffect } from "react";
import { useToaster } from "rsuite";
import IFirebaseTokenViewModel from "../Components/Interfaces/Message/IFirebaseTokenViewModel";
import apiHelper from "./apiHelper";
import { getNotificationElement } from "./notificationHelper";

type OnGetMessge = (messagePayload: MessagePayload) => void;
export const useFirebaseApp = (onGetMessage: OnGetMessge) => {

    const firebaseConfig = {
        apiKey: "AIzaSyD0GafDsNSDfMoI8F5WBz9xiAjvbJ1cWEk",
        authDomain: "writeco-8e6b1.firebaseapp.com",
        projectId: "writeco-8e6b1",
        storageBucket: "writeco-8e6b1.appspot.com",
        messagingSenderId: "848973786283",
        appId: "1:848973786283:web:b7fb23ae3cc4303e10dbee",
        measurementId: "G-2D0B7JY2D2"
    };

    const tokenOptions = {
        vapidKey: 'BDbQpQZ9eAjVPVjEiekiGDSawjBFuJ4t_3kKfZKAHK3t_QxjCajvESyK-UAIhvOGyMPbMbRH4S5vGiFMqQc9MU4'
    }

    const { postApi } = apiHelper();
    const toaster = useToaster();

    useEffect(() => {
        initialize();
    }, []);

    const initialize = () => {
        const firebaseApp = initializeApp(firebaseConfig);
        const messaging = getMessaging(firebaseApp);
        onMessage(messaging, onGetMessage);

        Notification.requestPermission();

        getToken(messaging, tokenOptions).then(currentToken => {
            if (currentToken) {
                const tokenViewModel: IFirebaseTokenViewModel = {
                    token: currentToken,
                }

                postApi<string>('/Message/SetToken', tokenViewModel)
                    .then(handleResponse)
                    .catch(handleError);
            }
        });

        const handleResponse = (res: string) => {
            const notificationElement = getNotificationElement("Turn notification 'on' successful", "", "success");
            toaster.push(notificationElement, { placement: 'topEnd' });
        }

        const handleError = (error: any) => {
            const message = error.response.status === 401 ? "Email or password is incorrect" : "Server error";

            const notificationElement = getNotificationElement("Turn notification 'on' faild", message, "error");
            toaster.push(notificationElement, { placement: 'topEnd' });
        }
    }
}