import { useReducer, useRef } from "react";
import { Stack } from "rsuite";
import WriteCoForm from "../Base/Form/form";
import { ITranslationPlanViewModel } from "../Interfaces/TranslationPlan/ITranslationPlanViewModel";
import translationPlanManagerReducer from "./Reducer";
import ToolbarButtons from "./toolbarButton";
import TranslationPlanTable from "./translationPlanTable";

const ListManager = () => {
    const { Toolbar, Body } = WriteCoForm;
    const { reducer, initialState } = translationPlanManagerReducer();
    const [state, dispatch] = useReducer(reducer, initialState);
    const selectedId = useRef(0);

    const onRowSelect = (rowData: ITranslationPlanViewModel) => {
        selectedId.current = rowData.id;
    }

    const refreshData = () => {
        dispatch({ type: "REFRESH_DATA" });
    }

    const TranslationPlanToolbar = () => {
        return <Stack justifyContent="space-between">
            <ToolbarButtons selectedId={selectedId} refreshData={refreshData} />
        </Stack>
    }

    return <WriteCoForm type="Panel" panelHeader="Translation plan manager">
        <Toolbar>
            <TranslationPlanToolbar />
        </Toolbar>
        <Body>
            <TranslationPlanTable filter={state.filter} onRowSelect={onRowSelect} />
        </Body>
    </WriteCoForm>
}

export default ListManager;