import { useEffect, useReducer } from "react";
import { DatePicker } from "rsuite";

interface Props {
    value: Date,
    onChange: (date: Date) => void,
    readOnly: boolean
}

const CustomDatePicker = ({ readOnly, onChange, value }: Props) => {
    const handleChangeValue = (value: Date) => {
        onChange(value);
    }
    
    return <DatePicker oneTap isoWeek defaultValue={new Date(value)} onChangeCalendarDate={handleChangeValue} readOnly={readOnly} />
}

export default CustomDatePicker;