import React, { useEffect, useState } from 'react';
import { useToken } from '../../Helper/TokenHelper';

interface Props {
    children: any,
}

export const TokenContext = React.createContext({
    token: undefined as string | undefined,
    setToken: (_token: string, _saveInCookie: boolean) => { },
});

export const TokenContextProvider = (props: Props) => {
    const [token, setToken] = useToken();

    return (
        <TokenContext.Provider value={{ token: token, setToken: setToken }}>
            {props.children}
        </TokenContext.Provider>
    );
}