import ITranslationPlanPagingFilterViewModel from "../../Interfaces/TranslationPlan/ITranslationPlanPagingFilterViewModel";

const translationPlanManagerReducer = () => {
    interface State {
        filter: ITranslationPlanPagingFilterViewModel,
        nameFilter?: string,
        selectedId?: number,
    }

    type Action =
        | { type: 'REFRESH_DATA'; }

    const initialState: State = {
        filter: {
            pageIndex: 1,
            pageSize: 10,
        },
    }

    const reducer = (state: State, action: Action): State => {
        switch (action.type) {
            case 'REFRESH_DATA':
                return initialState;
        }
    }

    return {
        reducer,
        initialState
    }

}

export default translationPlanManagerReducer;