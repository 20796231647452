import WriteCoGrid from "../Base/Grid/grid";
import { ILanguagePagingFilterViewModel } from "../Interfaces/LanguageManager/ILanguagePagingFilterViewModel";
import { ILanguageViewModel } from "../Interfaces/LanguageManager/ILanguageViewModel";

interface Props {
    filter: ILanguagePagingFilterViewModel,
    onRowSelect: (rowData: ILanguageViewModel) => void,
}

const LanguageTable = (props: Props) => {
    const { Data, Column } = WriteCoGrid;

    const onRowSelect = (rowData: any) => {
        props.onRowSelect(rowData as ILanguageViewModel);
    }

    return <WriteCoGrid>
        <Data apiUrl="/language/Search" filter={props.filter} showSelectableColumn onSelectRow={onRowSelect}>
            <Column dataKey='id' title="Code" width={50} type="regular" />
            <Column dataKey="name" title="Name" width={100} type="regular" />
            <Column dataKey="acronym" title="Acronym" width={100} type="regular" />
            <Column dataKey="isActive" title="Active" width={50} type="boolean" />
        </Data>
    </WriteCoGrid>
}

export default LanguageTable;