import { ItemDataType } from "rsuite/esm/@types/common";
import { ITagViewModel } from "../../../Interfaces/Tag/ITagViewModel";

const tagSelectorReducer = () => {
    interface State {
        tags: ItemDataType[],
        tagValues: string[],
        loading: boolean,
    }

    type Action =
        | { type: 'CHANGE_TAGS'; tags: ItemDataType[] }
        | { type: 'CHANGE_VALUES'; value: ITagViewModel[] }
        | { type: 'CLEAR_VALUES'; }

    const initialState: State = {
        tags: [],
        tagValues: [],
        loading: true,
    }

    const reducer = (state: State, action: Action): State => {
        switch (action.type) {
            case 'CHANGE_TAGS':
                return {
                    ...state,
                    tags: action.tags,
                    loading: false,
                }
            case 'CHANGE_VALUES':
                return {
                    ...state,
                    tagValues: action.value.map(i => i.id > 0 ? i.id.toString() : i.title),
                }
            case 'CLEAR_VALUES': {
                return {
                    ...state,
                    tagValues: [],
                }
            }
        }
    }

    return {
        reducer,
        initialState
    }

}

export default tagSelectorReducer;