import { ITranslationCategoryPagingFilterViewModel } from "../../Interfaces/TranslationCategory/ITranslationCategoryPagingFilterViewModel";

const translationCategoryManagerReducer = () => {
    interface State {
        filter: ITranslationCategoryPagingFilterViewModel,
        selectedId?: number,
    }

    type Action =
        | { type: 'REFRESH_DATA'; }
        | { type: 'CHANGE_INCLUDE_DEACTIVE', includeDeactive: boolean; }

    const initialState: State = {
        filter: {
            pageIndex: 1,
            pageSize: 10,
            includeDeactive: false,
        },
    }

    const reducer = (state: State, action: Action): State => {
        switch (action.type) {
            case 'REFRESH_DATA':
                return initialState;
        }
        switch (action.type) {
            case 'CHANGE_INCLUDE_DEACTIVE':
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        includeDeactive: action.includeDeactive,
                    }
                };
        }
    }

    return {
        reducer,
        initialState
    }

}

export default translationCategoryManagerReducer;