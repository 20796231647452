import WriteCoGrid from "../Base/Grid/grid";
import { IOrderPagingFilterViewModel } from "../Interfaces/Order/IOrderPagingFilterViewModel";
import { IOrderViewModel } from "../Interfaces/Order/IOrderViewModel";

interface Props {
    filter: IOrderPagingFilterViewModel,
    onRowSelect: (rowData: IOrderViewModel) => void,
}

const TranslationFeesTable = (props: Props) => {
    const { Data, Column } = WriteCoGrid;

    const onRowSelect = (rowData: any) => {
        props.onRowSelect(rowData as IOrderViewModel);
    }

    return <WriteCoGrid>
        <Data apiUrl="/order/Search" filter={props.filter} showSelectableColumn onSelectRow={onRowSelect}>
            <Column dataKey='id' title="Code" width={50} type="regular" />
            <Column dataKey="state" title="State" width={100} type="regular" />
            <Column dataKey="price" title="Price" width={150} type="price" />
        </Data>
    </WriteCoGrid>
}

export default TranslationFeesTable;