import { ItemDataType } from "rsuite/esm/@types/common";
import ITranslatorModificationLanguageViewModel from "../../../Interfaces/TranslatorManager/ITranslatorModificationLanguageViewModel";

const languagePickerReducer = () => {
    interface State {
        languages: ItemDataType[],
        tagValues: string[],
        loading: boolean,
    }

    type Action =
        | { type: 'CHANGE_LANGUAGES'; languages: ItemDataType[] }
        | { type: 'CHANGE_VALUES'; value: ITranslatorModificationLanguageViewModel[] }
        | { type: 'CLEAR_VALUES'; }

    const initialState: State = {
        languages: [],
        tagValues: [],
        loading: true,
    }

    const reducer = (state: State, action: Action): State => {
        switch (action.type) {
            case 'CHANGE_LANGUAGES':
                return {
                    ...state,
                    languages: action.languages,
                    loading: false,
                }
            case 'CHANGE_VALUES':
                return {
                    ...state,
                    tagValues: action.value.map(i => i.fromLanguageId + "-" + i.toLanguageId),
                }
            case 'CLEAR_VALUES': {
                return {
                    ...state,
                    tagValues: [],
                }
            }
        }
    }

    return {
        reducer,
        initialState
    }

}

export default languagePickerReducer;