import ITranslationWorkflowModificaitonViewModel from "../../Interfaces/TranslationOrderManager/ITranslationWorkflowModificaitonViewModel";
import ITranslationWorkflowViewModel from "../../Interfaces/TranslationOrderManager/ITranslationWorkflowViewModel";

const translationWorkflowReducer = () => {
    interface State {
        loading: boolean,
        data: ITranslationWorkflowViewModel[],
        formValue?: ITranslationWorkflowModificaitonViewModel,
        lastData?: ITranslationWorkflowViewModel,
    }

    type Action =
        | { type: 'CHANGE_LOADING'; loading: boolean }
        | { type: 'ON_GET_DATA'; data: ITranslationWorkflowViewModel[] }
        | { type: 'ON_CHANGE_FORM_VALUE'; formValue: ITranslationWorkflowModificaitonViewModel }

    const initialState: State = {
        loading: true,
        data: [],
    }

    const reducer = (state: State, action: Action): State => {
        switch (action.type) {
            case 'CHANGE_LOADING':
                return {
                    ...state,
                    loading: action.loading,
                }
            case 'ON_GET_DATA':
                const lastData = action.data[action.data.length - 1];
                return {
                    ...state,
                    data: action.data,
                    loading: false,
                    formValue: {
                        endDate: lastData.endDate,
                        guid: lastData.guid,
                        price: lastData.price,
                        state: lastData.state,
                        translationOrderId: lastData.translationOrderId,
                        wordCount: lastData.wordCount,
                        translatorEndDate: lastData.translatorEndDate,
                        translatorPrice: lastData.translatorPrice,
                        comment: '',
                        translatorFullName: lastData.translatorFullName,
                        userDescription: lastData.userDescription,
                    },
                    lastData: lastData,
                }
            case 'ON_CHANGE_FORM_VALUE':
                return {
                    ...state,
                    formValue: action.formValue,
                }
        }
    }

    return {
        reducer,
        initialState
    }

}

export default translationWorkflowReducer;