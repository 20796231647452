const toolbarReducer = () => {

    interface State {
        showAddForm: boolean,
        showApprovementForm: boolean,
        selectedId: number,
        showAlert: boolean
    }

    const initialState: State = {
        showAddForm: false,
        showApprovementForm: false,
        selectedId: 0,
        showAlert: false
    }

    type Action = | { type: 'TOGGLE_OPEN_ADD_FORM', open: boolean }
        | { type: "REFRESH_DATA" }
        | { type: 'TOGGLE_OPEN_APPROVEMENT_FORM', open: boolean, selectedId: number }
        | { type: "OPEN_ALERT" }


    const reducer = (state: State, action: Action): State => {
        switch (action.type) {
            case 'TOGGLE_OPEN_ADD_FORM':
                return {
                    ...state,
                    showAddForm: action.open
                }
            case 'TOGGLE_OPEN_APPROVEMENT_FORM':
                return {
                    ...state,
                    showApprovementForm: action.open,
                    selectedId: action.selectedId
                }
            case 'OPEN_ALERT':
                return {
                    ...state,
                    showAlert: true
                }
            case 'REFRESH_DATA':
                return initialState;

        }
    }

    return {
        initialState,
        reducer
    }
}

export default toolbarReducer;