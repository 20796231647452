import IMessageViewModel from "../../Interfaces/Message/IMessageViewModel";

const messageManagerReducer = () => {
    interface State {
        data: IMessageViewModel[],
        loading: boolean,
    }

    type Action =
        | { type: 'ON_GET_DATA'; data: IMessageViewModel[] }
        | { type: 'CHANGE_LOADING'; loading: boolean }

    const initialState: State = {
        data: [],
        loading: true,
    }

    const reducer = (state: State, action: Action): State => {
        switch (action.type) {
            case 'ON_GET_DATA':
                return {
                    ...state,
                    data: action.data,
                    loading: false,
                }
            case 'CHANGE_LOADING':
                return {
                    ...state,
                    loading: action.loading,
                }
        }
    }

    return {
        reducer,
        initialState
    }

}

export default messageManagerReducer;