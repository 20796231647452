import ITranslationOrderSearchResultViewModel from "../../Interfaces/TranslationOrderManager/ITranslationOrderSearchResultViewModel";

const toolbarReducer = () => {
    interface State {
        showForm: boolean,
        selectedId: number,
        selectedData?: ITranslationOrderSearchResultViewModel,
    }

    type Action =
        | { type: 'SHOW_FORM'; id: number }
        | { type: 'CLOSE_FORM'; }
        | { type: 'SET_SELECTED_DATA', data: ITranslationOrderSearchResultViewModel; }

    const initialState: State = {
        showForm: false,
        selectedId: 0,
    }

    const reducer = (state: State, action: Action): State => {
        switch (action.type) {
            case 'SHOW_FORM':
                return {
                    ...state,
                    showForm: true,
                    selectedId: action.id,
                }
            case 'CLOSE_FORM':
                return {
                    ...state,
                    showForm: false,
                }
            case 'SET_SELECTED_DATA':
                return {
                    ...state,
                    selectedData: action.data,
                }
        }
    }

    return {
        reducer,
        initialState
    }

}

export default toolbarReducer;