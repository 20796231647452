import { useEffect, useReducer } from "react";
import { Loader, Tag, TagPicker, toaster } from "rsuite";
import { ItemDataType } from "rsuite/esm/@types/common";
import apiHelper from "../../../Helper/apiHelper";
import { getNotificationElement } from "../../../Helper/notificationHelper";
import { ITagViewModel } from "../../Interfaces/Tag/ITagViewModel";
import tagSelectorReducer from "./Reducer";
import TagIcon from '@rsuite/icons/Tag';

const TagSelector = ({ onChange, value, type }: any) => {
    const { reducer, initialState } = tagSelectorReducer();
    const [state, dispatch] = useReducer(reducer, initialState);
    const { getApi } = apiHelper();

    useEffect(() => {
        getApi<ITagViewModel[]>('/tag/GetAll?referenceType=' + type)
            .then((res: ITagViewModel[]) => {
                handleResponse(res.map(item => {
                    return {
                        label: item.title,
                        value: item.id.toString(),
                    }
                }))
            })
            .catch((error: any) => {
                const message = error.response.status === 401 ? "Email or password is incorrect" : "Server error";
                const notificationElement = getNotificationElement("Loading Tags failed", message, "error");
                toaster.push(notificationElement, { placement: 'topEnd' });
            })
    }, [])


    useEffect(() => {
        if (value)
            dispatch({ type: "CHANGE_VALUES", value: value as ITagViewModel[] });
        else
            dispatch({ type: "CLEAR_VALUES" });
    }, [value]);

    const handleResponse = (model: ItemDataType[]) => {
        dispatch({ type: "CHANGE_TAGS", tags: model });
    }

    const handleChangeValue = (value: any, event: React.SyntheticEvent) => {
        onChange(onChangePicker(value));
    }

    const onChangePicker = (values: string[]) => {
        return values.map(i => {
            const oldData = state.tags.find(d => d.value === i);
            const res: ITagViewModel = {
                id: isNaN(Number(i)) ? 0 : Number(i),
                title: isNaN(Number(i)) ? i : (!!oldData && oldData.label) ? oldData.label.toString() : "",
            }
            return res;
        });
    }

    return <TagPicker
        creatable
        data={state.tags}
        style={{ width: '100%' }}
        menuStyle={{ width: 300 }}
        onChange={handleChangeValue}
        value={state.tagValues}
        renderValue={(values,) => {
            if (state.loading)
                return <Loader center content="Loading..." />
            else
                return values.map((tag: any, index: any) => {
                    const tagValue = state.tags.find(i => i.value === tag);
                    return <Tag key={index}>
                        <TagIcon /> {tagValue ? tagValue.label : tag}
                    </Tag>
                });
        }}
    />
}

export default TagSelector;