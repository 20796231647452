import WriteCoGrid from "../Base/Grid/grid";
import ITranslationPlanPagingFilterViewModel from "../Interfaces/TranslationPlan/ITranslationPlanPagingFilterViewModel";
import { ITranslationPlanViewModel } from "../Interfaces/TranslationPlan/ITranslationPlanViewModel";

interface Props {
    filter: ITranslationPlanPagingFilterViewModel,
    onRowSelect: (rowData: ITranslationPlanViewModel) => void,
}

const TranslationPlanTable = (props: Props) => {
    const { Data, Column } = WriteCoGrid;

    const onRowSelect = (rowData: any) => {
        props.onRowSelect(rowData as ITranslationPlanViewModel);
    }

    return <WriteCoGrid>
        <Data apiUrl="/translationPlan/Search" filter={props.filter} showSelectableColumn onSelectRow={onRowSelect}>
            <Column dataKey='id' title="Code" width={50} type="regular" />
            <Column dataKey="title" title="Title" width={100} type="regular" />
            <Column dataKey="increasePrice" title="Increase price" width={150} type="price" />
        </Data>
    </WriteCoGrid>
}

export default TranslationPlanTable;