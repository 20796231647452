import cookies from "react-cookies";
import { CookieSerializeOptions } from 'cookie';
import React, { useEffect, useState } from "react";
import ITokenData from "../Components/Interfaces/ITokenData";

const tokenSessionKey = "WriteCoToken";
const tokenCookieName = "WriteCoTokenCookie";

type TokenDispather = (token: string, saveInCookie: boolean) => void;

export const useToken = (): [string | undefined, TokenDispather] => {
    const [token, setToken] = useState<string>();

    useEffect(() => {
        const tokenInSession = getToken();
        if (tokenInSession) {
            setToken(tokenInSession);
        }
    }, []);

    const saveTokenInStorage = (token: string, saveInCookie: boolean) => {
        saveToken(token, saveInCookie);
        setToken(token);
    }

    return [token, saveTokenInStorage];
}

export const getToken = (): string | null => {
    let tokenSession = sessionStorage.getItem(tokenSessionKey) as string | null;

    if (!!!tokenSession)
        tokenSession = cookies.load(tokenCookieName) as string | null;

    return tokenSession;
}

export function getTokenModel() {
    const token = getToken();
    if (!token) { return; }
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64)) as ITokenData;
}

export const isUserLogin = () => {
    const token = getTokenModel();
    return !!token && new Date(token.exp * 1000) > new Date();
}

const saveToken = (token: string, saveInCookie: boolean): void => {
    if (saveInCookie) {
        const opt: CookieSerializeOptions = {
            path: "/",
        }
        cookies.save(tokenCookieName, token, opt);
    }
    else {
        sessionStorage.setItem(tokenSessionKey, token);
    }
}