import { EnumValues } from "enum-values";
import { OrderState } from "../Interfaces/Order/OrderState";
import { ItemDataType } from "rsuite/esm/@types/common";
import { useEffect, useReducer } from "react";
import { InputPicker } from "rsuite";
import orderStateSelectorStateReducer from "./Reducer/orderStateSelectorStateReducer";

const OrderStateSelector = ({ onChange, value, size }: any) => {
    const { reducer, initialState } = orderStateSelectorStateReducer();
    const [state, dispatch] = useReducer(reducer, initialState);
    const data = EnumValues.getNamesAndValues<OrderState>(OrderState).map(i => {
        const res: ItemDataType = {
            label: i.name,
            value: i.value,
        };
        return res;
    });

    useEffect(() => {
        dispatch({ type: "CHANGE_VALUE", value: value as number });
    }, [value]);

    const handleChangeValue = (value: any, event: React.SyntheticEvent) => {
        onChange(value);
    }

    return <InputPicker
        data={data}
        value={state.value}
        size={size}
        onChange={handleChangeValue}
    />
}

export default OrderStateSelector; 