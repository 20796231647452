import { forwardRef } from "react";
import { Form } from "rsuite";

interface Props {
    title: string,
    name: string,
    type?: string
}

const FormTextField = forwardRef<HTMLDivElement, Props>((props, ref) => {
    const { ...rest } = props;

    return <Form.Group ref={ref}>
        <Form.ControlLabel>{props.title}: </Form.ControlLabel>
        <Form.Control {...rest} />
    </Form.Group>
});

export default FormTextField;