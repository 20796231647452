export enum EmailBodyParameters {
    EndDate,
    OldEndDate,
    UserFullName,
    OldWordCount,
    WordCount,
    Comment,
    ExpiredDate,
    UserDescription,
    TotalCost,
    PaymentId
}