import WriteCoForm from "../Base/Form/form";
import WriteCoGrid from "../Base/Grid/grid";
import { useReducer, useRef } from "react";
import coreReducer from "./Reducer/coreReducer";
import Toolbar from "./toolbar";
import { Stack, Toggle } from "rsuite";

const Core = () => {

    const { reducer, initialState } = coreReducer();
    const [state, dispatch] = useReducer(reducer, initialState);
    const selectedId = useRef(0);
    const onRowSelect = (rowData: any) => {
        selectedId.current = rowData.id
    }

    const refereshTable = () => {
        dispatch({ type: "REFEREHS_TABLE" })
    }

    const changeSold = (sold: boolean) => {
        dispatch({ type: "CHANGE_SOLD", sold: !sold });
    }

    const handleToggleChange = (checked: boolean) => {
        changeSold(checked);
    }


    return <WriteCoForm type="Panel">
        <WriteCoForm.Toolbar>
            <Stack justifyContent="space-between">
                <Toolbar selectedRecordId={selectedId} onTableReferesh={refereshTable} />
                <Toggle checked={!state.filter.sold} onChange={handleToggleChange} checkedChildren="Not sold" unCheckedChildren="Sold" />
            </Stack>
        </WriteCoForm.Toolbar>
        <WriteCoForm.Body>
            <WriteCoGrid>
                <WriteCoGrid.Data apiUrl="/pictorialLanguage/Search" filter={state.filter} showSelectableColumn onSelectRow={onRowSelect}>
                    <WriteCoGrid.Column dataKey='id' title="Code" width={100} type="regular" />
                    <WriteCoGrid.Column dataKey="title" title="Title" width={150} type="regular" />
                    <WriteCoGrid.Column dataKey="description" title="Description" width={150} type="regular" />
                    <WriteCoGrid.Column dataKey="price" title="Price" width={200} type="price" />
                    <WriteCoGrid.Column dataKey="imageUrl" title="Image" width={200} type="image" />
                    <WriteCoGrid.Column dataKey="isApproved" title="IsApproved" width={100} type="boolean" />
                    <WriteCoGrid.Column dataKey="creatorUsername" title="Creator" width={150} type="regular" />
                </WriteCoGrid.Data>
            </WriteCoGrid>
        </WriteCoForm.Body>
    </WriteCoForm >
}

export default Core;