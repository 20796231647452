import { useContext, useEffect, useState } from "react";
import { isUserLogin } from "../../Helper/TokenHelper";
import { TokenContext } from "../Contexts/TokenContext";

interface IAuthorizedProps {
    children: any,
}

interface IUnAuthorizedProps {
    children: any,
}

export const Authorized = (props: IAuthorizedProps) => {
    const tokenContext = useContext(TokenContext);
    const isLogin = isUserLogin();
    const [show, setShow] = useState(false);

    useEffect(() => {
        setShow(!!isLogin);
    }, [tokenContext.token])

    if (show)
        return props.children;
}

export const UnAuthorized = (props: IUnAuthorizedProps) => {
    const tokenContext = useContext(TokenContext);
    const [show, setShow] = useState(false);
    const isLogin = isUserLogin();

    useEffect(() => {
        setShow(!isLogin);
    }, [tokenContext.token])

    if (show)
        return props.children;
}
