import { IOrderPagingFilterViewModel } from "../../Interfaces/Order/IOrderPagingFilterViewModel";

const searchToolbarReducer = (filter: IOrderPagingFilterViewModel) => {
    interface State {
        loading: boolean,
        formValue: IOrderPagingFilterViewModel,
    }

    type Action =
        | { type: 'ON_CHANGE_FORM_VALUE'; formValue: IOrderPagingFilterViewModel }

    const initialState: State = {
        loading: false,
        formValue: filter,
    }

    const reducer = (state: State, action: Action): State => {
        switch (action.type) {
            case 'ON_CHANGE_FORM_VALUE':
                return {
                    ...state,
                    formValue: action.formValue,
                }
        }
    }

    return {
        reducer,
        initialState
    }

}

export default searchToolbarReducer;