import { useReducer, useRef } from "react";
import WriteCoForm from "../Base/Form/form";
import orderManagerReducer from "./Reducer";
import { IOrderViewModel } from "../Interfaces/Order/IOrderViewModel";
import SearchToolbar from "./searchToolbar";
import { IOrderPagingFilterViewModel } from "../Interfaces/Order/IOrderPagingFilterViewModel";
import OrdersTable from "./ordersTable";

const ListManager = () => {
    const { Toolbar, Body } = WriteCoForm;
    const { reducer, initialState } = orderManagerReducer();
    const [state, dispatch] = useReducer(reducer, initialState);
    const selectedId = useRef(0);

    const onChangeFilter = (filter: IOrderPagingFilterViewModel) => {
        dispatch({ type: "ON_CHANGE_FILTER", filter: filter });
    }

    const onRowSelect = (rowData: IOrderViewModel) => {
        selectedId.current = rowData.id;
    }

    const refreshData = () => {
        dispatch({ type: "REFRESH_DATA" });
    }

    return <WriteCoForm type="Default">
        <Toolbar>
            <WriteCoForm type="Panel" panelHeader="Search" panelCollapsible panelDefaultExpanded={state.changedFilter}>
                <Body>
                    <SearchToolbar onChangeFilter={onChangeFilter} filter={state.filter} />
                </Body>
            </WriteCoForm>
        </Toolbar>
        <Body>
            <WriteCoForm type="Panel" panelHeader="Order manager">
                <Body>
                    <OrdersTable filter={state.filter} onRowSelect={onRowSelect} />
                </Body>
            </WriteCoForm>
        </Body>
    </WriteCoForm >

}

export default ListManager;