import { TagPicker, Tag, Loader } from "rsuite";
import { ItemDataType } from "rsuite/esm/@types/common";
import { useEffect, useReducer } from "react";
import GlobalIcon from '@rsuite/icons/Global';
import languageHelper from "../../../Helper/LanguageHelper";
import languagePickerReducer from "./Reducer/languagePickerReducer";
import ITranslatorModificationLanguageViewModel from "../../Interfaces/TranslatorManager/ITranslatorModificationLanguageViewModel";

const LanguagePicker = ({ onChange, value = [] }: any) => {
    const { getLanguageItemData } = languageHelper();
    const { reducer, initialState } = languagePickerReducer();
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        getLanguageItemData(true)
            .then(handleResponse)
    }, [])


    useEffect(() => {
        if (value.length > 0)
            dispatch({ type: "CHANGE_VALUES", value: value as ITranslatorModificationLanguageViewModel[] });
        else
            dispatch({ type: "CLEAR_VALUES" });
    }, [value]);

    const handleResponse = (model: ItemDataType[]) => {
        dispatch({ type: "CHANGE_LANGUAGES", languages: model });
    }

    const handleChangeValue = (value: any, event: React.SyntheticEvent) => {
        onChange(getReocrdValue(value));
    }

    const getReocrdValue = (value: string[]) => {
        return value.map(i => {
            const split = i.split('-');

            const result: ITranslatorModificationLanguageViewModel = {
                fromLanguageId: Number(split[0]),
                toLanguageId: Number(split[1])
            }

            return result;
        });
    }
    
    return <TagPicker
        data={state.languages}
        groupBy="parentName"
        placeholder={!state.loading && "Select languages"}
        block
        value={state.tagValues}
        onChange={handleChangeValue}
        renderValue={(values,) => {
            if (state.loading)
                return <Loader center content="Loading..." />
            else
                return values.map((tag: any, index: any) => {
                    const language = state.languages.find(i => i.value === tag);
                    return <Tag key={index}>
                        <GlobalIcon /> {language?.parentName + "/" + language?.label}
                    </Tag>
                });
        }}
    />
}

export default LanguagePicker; 