import { createRef, forwardRef } from "react";
import { Button, ButtonToolbar, Form, FormInstance, IconButton, Input, Schema, useToaster } from "rsuite";
import apiHelper from "../../Helper/apiHelper";
import { TranslationWorkflowGeneralState } from "../../Helper/Enumeration/TranslationWorkflowGeneralState";
import { TranslationWorkflowState } from "../../Helper/Enumeration/TranslationWorkflowState";
import { getNotificationElement } from "../../Helper/notificationHelper";
import { getTokenModel } from "../../Helper/TokenHelper";
import CustomDatePicker from "../Base/CustomDatePicker/customDatePicker";
import ITranslationWorkflowModificaitonViewModel from "../Interfaces/TranslationOrderManager/ITranslationWorkflowModificaitonViewModel";
import ITranslationWorkflowViewModel from "../Interfaces/TranslationOrderManager/ITranslationWorkflowViewModel";
import AttachmentIcon from '@rsuite/icons/Attachment'

interface Props {
    data: ITranslationWorkflowViewModel,
    formValue: ITranslationWorkflowModificaitonViewModel,
    loadData: () => void,
    setLoading: (loading: boolean) => void,
    setFormValue: (value: ITranslationWorkflowModificaitonViewModel) => void,
}

const TranslationWorkflowFrom = (props: Props) => {
    const formRef = createRef<FormInstance>();
    const { postApi, getFileApi } = apiHelper();
    const toaster = useToaster();
    const token = getTokenModel();
    const isOwner = props.data.ownerId == token?.Id || props.data.ownerId === 0;
    const allowChangeUserData = props.data.state === TranslationWorkflowState.CheckUserDataState;
    const hasTranslatorData = props.data.generalState === TranslationWorkflowGeneralState.Translating || props.data.generalState === TranslationWorkflowGeneralState.End;
    const hasTranalstedFile = props.data.state === TranslationWorkflowState.CheckTranslateByAdminState || props.data.state === TranslationWorkflowState.Complete;
    const hasUserDescription = props.data.userDescription !== "";
    const hasUserFile = props.data.hasUserFile;

    const model = Schema.Model<ITranslationWorkflowModificaitonViewModel>({
        endDate: Schema.Types.DateType(),
        wordCount: Schema.Types.NumberType(),
        translationOrderId: Schema.Types.NumberType(),
        guid: Schema.Types.StringType(),
        price: Schema.Types.NumberType(),
        state: Schema.Types.NumberType(),
        comment: Schema.Types.StringType(),
        userDescription: Schema.Types.StringType(),
    });

    const handleChange = (value: ITranslationWorkflowModificaitonViewModel | any) => {
        props.setFormValue(value);
    }

    const handleApprove = () => {
        if (!formRef?.current?.check())
            return;

        props.setLoading(true);

        postApi<string>('/TranslationWorkflow/Approve', props.formValue)
            .then(handleFormResponse)
            .catch(handleFormError)
            .finally(props.loadData);
    }

    const handleReject = () => {
        if (!formRef?.current?.check())
            return;

        props.setLoading(true);

        postApi<string>('/TranslationWorkflow/Reject', props.formValue)
            .then(handleFormResponse)
            .catch(handleFormError)
            .finally(props.loadData);
    }

    const handleFormResponse = (res: string) => {
        const notificationElement = getNotificationElement("Insert successful", "", "success");
        toaster.push(notificationElement, { placement: 'topEnd' });
    }

    const handleFormError = (error: any) => {
        const message = error.response.status === 401 ? "Email or password is incorrect" : "Server error";

        const notificationElement = getNotificationElement("Insert faild", message, "error");
        toaster.push(notificationElement, { placement: 'topEnd' });
    }


    const handleDownloadUserFile = () => {
        getFileApi('/TranslationOrder/GetUserFile?translationOrderId=' + props.data.translationOrderId)
            .then(res => {
                var binaryData = [];
                binaryData.push(res);
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(new Blob(binaryData, { type: "application/zip" }));
                link.setAttribute('download', "user-" + props.data.guid + ".zip",);
                document.body.appendChild(link);
                link.click();
                link.parentNode?.removeChild(link);
            });
    }

    const handleDownloadTranslatedFile = () => {
        getFileApi('/TranslationOrder/GetTranslatedFile?translationOrderId=' + props.data.translationOrderId)
            .then(res => {
                var binaryData = [];
                binaryData.push(res);
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(new Blob(binaryData, { type: "application/zip" }));
                link.setAttribute('download', "translated-" + props.data.guid + ".zip",);
                document.body.appendChild(link);
                link.click();
                link.parentNode?.removeChild(link);
            });
    }

    const Textarea = forwardRef<HTMLTextAreaElement, any>((props, ref) => <Input {...props} as="textarea" ref={ref} />);

    return <Form fluid ref={formRef} model={model} formValue={props.formValue} onChange={handleChange} layout="horizontal">
        <Form.Group>
            <Form.ControlLabel>Price: </Form.ControlLabel>
            <Form.Control name="price" readOnly={!!!isOwner || !!!allowChangeUserData} />
        </Form.Group>
        <Form.Group>
            <Form.ControlLabel>Words count: </Form.ControlLabel>
            <Form.Control name="wordCount" readOnly={!!!isOwner || !!!allowChangeUserData} />
        </Form.Group>
        <Form.Group>
            <Form.ControlLabel>End date: </Form.ControlLabel>
            <Form.Control name="endDate" accepter={CustomDatePicker} readOnly={!!!isOwner || !!!allowChangeUserData} />
        </Form.Group>
        {hasTranslatorData &&
            <Form.Group>
                <Form.ControlLabel>Translator: </Form.ControlLabel>
                <Form.Control name="translatorFullName" readOnly />
            </Form.Group>
        }
        {hasTranslatorData &&
            <Form.Group>
                <Form.ControlLabel>Translator price: </Form.ControlLabel>
                <Form.Control name="translatorPrice" readOnly />
            </Form.Group>
        }
        {hasTranslatorData &&
            <Form.Group>
                <Form.ControlLabel>Translator end date: </Form.ControlLabel>
                <Form.Control name="translatorEndDate" accepter={CustomDatePicker} readOnly />
            </Form.Group>
        }
        <Form.Group>
            <Form.ControlLabel>Comment: </Form.ControlLabel>
            <Form.Control name="comment" accepter={Textarea} readOnly={!!!isOwner} rows={5} />
        </Form.Group>
        {hasUserDescription &&
            <Form.Group>
                <Form.ControlLabel>User description: </Form.ControlLabel>
                <Form.Control name="userDescription" accepter={Textarea} readOnly rows={5} />
            </Form.Group>
        }
        {hasUserFile &&
            <Form.Group>
                <IconButton appearance="default" onClick={handleDownloadUserFile} icon={<AttachmentIcon />}>
                    User files
                </IconButton>
            </Form.Group>
        }
        {hasTranalstedFile &&
            <Form.Group>
                <IconButton appearance="default" onClick={handleDownloadTranslatedFile} icon={<AttachmentIcon />}>
                    Translated file
                </IconButton>
            </Form.Group>
        }
        <hr />
        <Form.Group>
            <ButtonToolbar>
                <Button appearance="primary" onClick={handleApprove} disabled={!!!isOwner}>Approve</Button>
                <Button onClick={handleReject} disabled={!!!isOwner}>Reject</Button>
            </ButtonToolbar>
        </Form.Group>
    </Form>
}

export default TranslationWorkflowFrom;