import { createRef, useReducer } from "react";
import { Input, InputGroup, Stack } from "rsuite";
import WriteCoForm from "../Base/Form/form";
import userManagerReducer from "./Reducer";
import UserTable from "./userTable";
import SearchIcon from '@rsuite/icons/Search';

const ListManager = () => {
    const { Toolbar, Body } = WriteCoForm;
    const { reducer, initialState } = userManagerReducer();
    const searchRef = createRef<HTMLInputElement>();
    const [state, dispatch] = useReducer(reducer, initialState);

    const handleSearchClick = () => {
        if (searchRef.current)
            dispatch({ type: "CHANGE_NAME_FILTER", nameFilter: searchRef.current.value });
    }

    const UserToolbar = () => {
        return <Stack spacing={6} justifyContent="flex-end">
            <InputGroup size="sm">
                <Input defaultValue={state.nameFilter} ref={searchRef} />
                <InputGroup.Button onClick={handleSearchClick}>
                    <SearchIcon />
                </InputGroup.Button>
            </InputGroup>
        </Stack>
    }

    return <WriteCoForm type="Panel" panelHeader="User manager">
        <Toolbar>
            <UserToolbar />
        </Toolbar>
        <Body>
            <UserTable filter={state.filter} />
        </Body>
    </WriteCoForm>
}

export default ListManager;