import { EmptyOfType, FormattedText, FormattedTextType, } from './custom-types';
import { jsx } from 'slate-hyperscript'

type HtmlTag = 'u' | 'em' | 'code' | 'strong' | 'mark' | 's';

interface HtmlStringProps {
    text: string,
}

interface ElementProps {
    children: any,
}

interface LeafWrapper {
    htmlTag: HtmlTag,
    getHtmlString: (props: HtmlStringProps) => string,
    getElement: (props: ElementProps) => JSX.Element,
}

const leafWrapper = () => {

    const serializeLeaf = (node: FormattedText) => serialize(getFormattedTextTypes(node), 0, node.text);

    const serialize = (formatedTexts: (FormattedTextType)[], index: number, text: string): string => {
        if (index < formatedTexts.length) {
            const r = getLeafElement(formatedTexts[index]).getHtmlString({ text: serialize(formatedTexts, index + 1, text) })
            return r;
        }

        return text;
    }

    const deserializeLeaf = (el: HTMLElement, olderAttributes = {}) => {
        if (el.nodeType === Node.TEXT_NODE) {
            return jsx('text', olderAttributes, el.textContent)
        } else if (el.nodeType !== Node.ELEMENT_NODE) {
            return undefined
        }

        const nodeAttributes = { ...olderAttributes } as FormattedText

        if (isHtmlTag(el.nodeName.toLowerCase()))
            nodeAttributes[elementTypeFromTag(el.nodeName.toLowerCase() as HtmlTag)] = true as never;

        return nodeAttributes;
    }

    return {
        serializeLeaf,
        deserializeLeaf,
    }
}

export const getFormattedTextTypes = (key: FormattedText): FormattedTextType[] => {
    return Object.keys(key).filter(i => isFormattedTextType(i)).map(i => i as FormattedTextType);
}

export const getLeafElement = (key: FormattedTextType): LeafWrapper => {
    return getElementByHtmlTag(elementTagFromType(key));
}

const isFormattedTextType = (key: string): key is FormattedTextType => key in emptyFormattedTextType;
const isHtmlTag = (tag: string): tag is HtmlTag => tag in emptyHtmlTag;

const getElementByHtmlTag = (htmlTag: HtmlTag): LeafWrapper => {
    switch (htmlTag) {
        case 'code':
            return {
                htmlTag: "code",
                getElement: ({ children }) => <code>{children}</code>,
                getHtmlString: (props) => getHtmlString('code', props),
            };
        case 'em':
            return {
                htmlTag: "em",
                getElement: ({ children }) => <em>{children}</em>,
                getHtmlString: (props) => getHtmlString('em', props),
            }
        case 'strong':
            return {
                htmlTag: "strong",
                getElement: ({ children }) => <strong>{children}</strong>,
                getHtmlString: (props) => getHtmlString('strong', props),
            }
        case 'u':
            return {
                htmlTag: "u",
                getElement: ({ children }) => <u>{children}</u>,
                getHtmlString: (props) => getHtmlString('u', props),
            }
        case 'mark':
            return {
                htmlTag: "mark",
                getElement: ({ children }) => <mark>{children}</mark>,
                getHtmlString: (props) => getHtmlString('mark', props),
            }
        case 's':
            return {
                htmlTag: "s",
                getElement: ({ children }) => <s>{children}</s>,
                getHtmlString: (props) => getHtmlString('s', props),
            }
    }
}

const getHtmlString = (htmlTag: HtmlTag, { text }: HtmlStringProps): string => {
    return "<" + htmlTag + ">" + text + "</" + htmlTag + ">";
}

const elementTagFromType = (key: FormattedTextType): HtmlTag => {
    switch (key) {
        case 'bold':
            return "strong";
        case 'code':
            return "code";
        case 'italic':
            return 'em';
        case 'underline':
            return 'u';
        case 'mark':
            return 'mark';
        case 'strikethrough':
            return 's';
    }
}

const elementTypeFromTag = (htmlTag: HtmlTag): FormattedTextType => {
    switch (htmlTag) {
        case 'strong':
            return "bold";
        case 'code':
            return "code";
        case 'em':
            return 'italic';
        case 'u':
            return 'underline';
        case 'mark':
            return 'mark';
        case 's':
            return 'strikethrough';
    }
}

const emptyFormattedTextType: EmptyOfType<FormattedTextType> = {
    bold: '',
    code: '',
    italic: '',
    underline: '',
    mark: '',
    strikethrough: '',
}

const emptyHtmlTag: EmptyOfType<HtmlTag> = {
    code: '',
    em: '',
    strong: '',
    u: '',
    mark: '',
    s: '',
}

export default leafWrapper;