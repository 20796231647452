import { RenderElementProps, RenderLeafProps } from "slate-react";
import { getAlignment } from "./custom-types";
import { getElement } from "./elementWrapper";
import { getFormattedTextTypes, getLeafElement } from "./leafWrapper";


export const RenderElement = ({ attributes, children, element }: RenderElementProps) => {
    const align = getAlignment(element.alignmet);

    const style = {
        textAlign: align,
    };

    return getElement(element.type).getElement({ attributes, children, style });
}

export const LeafElement = ({ attributes, children, leaf }: RenderLeafProps) => {
    getFormattedTextTypes(leaf)
        .filter(i => leaf[i] === true)
        .map(i => {
            children = getLeafElement(i).getElement({ children })
        });

    return <span {...attributes}>{children}</span>
}