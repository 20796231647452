import react, { ReactElement, useEffect } from "react";
import { Container, Content, Header } from "rsuite";
import { SidebarPanel } from "../Components/Sidebar/sidebarPanel";
import { navbarItems } from "../Helper/Enumeration/NavbarItems";
import { LanguageManager } from '../Components/LanguageManager/index';
import { TranslatorManager } from "../Components/TranslatorManager";
import { Blog } from '../Components/BlogManager/index';
import { TranslationFeeManager } from "../Components/TranslationFeeManager";
import { OrderManager } from "../Components/OrderManager";
import { UserManager } from "../Components/UserManager";
import { DesignersManager } from "../Components/DesignerManager";
import { AdminManager } from "../Components/AdminManager";
import { TranslationOrderManager } from "../Components/TranslationOrderManager";
import { LocalizationManager } from "../Components/LocalizationManager";
import { ConfigurationManager } from "../Components/ConfigurationManager";
import { PictorialProductsManager } from "../Components/PictorialProductsManager";
import { MessageManager } from "../Components/MessageManager";
import { TranslationCategoryManager } from "../Components/TranslationCategoryManager";
import { TranslationPlanManager } from "../Components/TranslationPlanManager";
import { PictorialLanguageOrderManager } from "../Components/PictorialLanguageOrderManager";

interface Props {
    unreadMessageCount: number,
    setUnreadMessageCount: (unreadMessageCount: number) => void,
}

const HomePage = (props: Props) => {
    const [activeKey, setActiveKey] = react.useState<string>(navbarItems.TranslationOrders.toString());
    const [element, setElement] = react.useState<ReactElement>();
    const [showMessages, setShowMessages] = react.useState(false);

    useEffect(() => {
        switch (activeKey) {
            case navbarItems.Users.toString():
                setElement(<UserManager />);
                break;
            case navbarItems.Languages.toString():
                setElement(<LanguageManager />);
                break;
            case navbarItems.Blog.toString():
                setElement(<Blog />);
                break;
            case navbarItems.Translators.toString():
                setElement(<TranslatorManager />);
                break;
            case navbarItems.TranslationFee.toString():
                setElement(<TranslationFeeManager />);
                break;
            case navbarItems.Orders.toString():
                setElement(<OrderManager />);
                break;
            case navbarItems.Designers.toString():
                setElement(<DesignersManager />);
                break;
            case navbarItems.Admins.toString():
                setElement(<AdminManager />);
                break;
            case navbarItems.TranslationOrders.toString():
                setElement(<TranslationOrderManager />);
                break;
            case navbarItems.ConfigurationManager.toString():
                setElement(<ConfigurationManager />);
                break;
            case navbarItems.TranslationCategory.toString():
                setElement(<TranslationCategoryManager />);
                break;
            case navbarItems.TranslationPlan.toString():
                setElement(<TranslationPlanManager />);
                break;
            case navbarItems.PictorialProducts.toString():
                setElement(<PictorialProductsManager />);
                break;
            case navbarItems.PictorialLanguageOrders.toString():
                setElement(<PictorialLanguageOrderManager />);
                break;
        }
    }, [activeKey])

    const contentStyle = {
        paddingLeft: '1%',
        paddingRight: '1%',
        margin: '1%'
    }

    return <>
        <Container>
            <Header>
                <SidebarPanel activeKey={activeKey} setActiveKey={setActiveKey} unreadMessageCount={props.unreadMessageCount} setMessageShow={setShowMessages} />
            </Header>
            <Content style={contentStyle}>
                {element}
            </Content>
        </Container>
        <MessageManager onGetData={() => props.setUnreadMessageCount(0)} setShowMessage={setShowMessages} showMessage={showMessages} />
    </>
}

export default HomePage;