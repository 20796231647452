import SuccessfulPaymentEmailConfig from "../../Components/ConfigurationManager/successfulPaymentEmailConfig";

export enum ConfigurationKeys {
    Email = 1,
    UserDataEmail = 2,
    TranslatorRequestEmail = 3,
    TranslatingEmail = 4,
    TranslatorEndEmail = 5,
    Firebase = 6,
    SignupConfirmation = 7,
    ForgetPasswordConfirmation = 8,
    SuccessfulPaymentEmail = 9
}