import { useReducer, useRef } from "react";
import { Stack } from "rsuite";
import WriteCoForm from "../Base/Form/form";
import { ILanguageViewModel } from "../Interfaces/LanguageManager/ILanguageViewModel";
import LanguageTable from "./languagesTable";
import languageManagerReducer from "./Reducer";
import ToolbarButtons from "./toolbarButtons";

const ListManager = () => {
    const { Toolbar, Body } = WriteCoForm;
    const { reducer, initialState } = languageManagerReducer();
    const [state, dispatch] = useReducer(reducer, initialState);
    const selectedId = useRef(0);

    const onRowSelect = (rowData: ILanguageViewModel) => {
        selectedId.current = rowData.id;
    }

    const refreshData = () => {
        dispatch({ type: "REFRESH_DATA" });
    }

    const AdminToolbar = () => {
        return <Stack justifyContent="space-between">
            <ToolbarButtons selectedId={selectedId} refreshData={refreshData} />
        </Stack>
    }

    return <WriteCoForm type="Panel" panelHeader="Language manager">
        <Toolbar>
            <AdminToolbar />
        </Toolbar>
        <Body>
            <LanguageTable filter={state.filter} onRowSelect={onRowSelect} />
        </Body>
    </WriteCoForm>
}

export default ListManager;