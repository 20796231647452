import { Button, Stack, useToaster } from "rsuite";
import toolbarReducer from "./Reducer/toolbarReducer";
import { MutableRefObject, useReducer } from "react";
import { getNotificationElement } from "../../Helper/notificationHelper";
import apiHelper from "../../Helper/apiHelper";
import convertorHelper from "../../Helper/convertorHelper";
import { ITranslationRequestWorkflowSearchResultViewModel } from "../Interfaces/TranslationOrderManager/ITranslationRequestWorkflowSearchResultViewModel";

interface Props {
    selectedData: MutableRefObject<ITranslationRequestWorkflowSearchResultViewModel | undefined>,
    refreshData: () => void,
}

const ToolbarButtons = (props: Props) => {
    const { reducer, initialState } = toolbarReducer();
    const [state, dispatch] = useReducer(reducer, initialState);
    const { postApi } = apiHelper();
    const { convertToGuidViewModel } = convertorHelper();
    const toaster = useToaster();

    const handleApproveClick = () => {
        if (props.selectedData.current && props.selectedData.current.guid !== '') {
            setLoading(true);

            postApi<string>('/TranslationRequestWorkflow/SimpleApprove', convertToGuidViewModel(props.selectedData.current.guid))
                .then(handleFormResponse)
                .catch(handleFormError)
                .finally(() => setLoading(false));
        }
    }

    const handleFormResponse = (res: string) => {
        const notificationElement = getNotificationElement("Approve successful", "", "success");
        toaster.push(notificationElement, { placement: 'topEnd' });

        props.refreshData();
    }

    const handleFormError = (error: any) => {
        const message = error.response.status === 401 ? "Email or password is incorrect" : "Server error";

        const notificationElement = getNotificationElement("Approve faild", message, "error");
        toaster.push(notificationElement, { placement: 'topEnd' });
    }

    const setLoading = (loading: boolean) => {
        dispatch({ type: "SET_LOADING", loading: loading });
    }

    return <Stack spacing={6} justifyContent="flex-start">
        <Button onClick={handleApproveClick} size="sm" loading={state.loading}>Approve</Button>
    </Stack>
}

export default ToolbarButtons;