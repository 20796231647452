import axios, { AxiosRequestHeaders } from "axios";
import { getToken } from "./TokenHelper";

const apiHelper = () => {
    const getApiPath = () => {
        return process.env.NODE_ENV === 'production' ? "https://panelapi.translationwrite.com/api" : "https://localhost:7097/api"
    }

    const getHeaderRequest = () => {
        const header: AxiosRequestHeaders = {};
        const token = getToken();
        if (token) {
            header['Authorization'] = `Bearer ${token}`;
        }

        return header;
    }

    const postApi = async <T extends any>(url: string, body: any): Promise<T> => {
        const { data } = await axios.post(getApiPath() + url, body, {
            method: 'POST',
            headers: getHeaderRequest(),
        });

        return data as T;
    }

    const getApi = async <T extends any>(url: string): Promise<T> => {
        const { data } = await axios.get(getApiPath() + url, {
            method: 'GET',
            headers: getHeaderRequest(),
        });

        return data as T;
    }

    const getFileApi = async (url: string) => {
        const { data } = await axios.get(getApiPath() + url, {
            method: 'GET',
            headers: getHeaderRequest(),
            responseType: 'blob',
        });

        return data;
    }

    return {
        getApi,
        postApi,
        getApiPath,
        getFileApi,
    }
}

export default apiHelper;
