import { Badge, Button, ButtonGroup, Content, Dropdown, IconButton, Popover, Stack, Whisper } from "rsuite";
import { navbarItems as eventKeys } from '../../Helper/Enumeration/NavbarItems';
import GearIcon from '@rsuite/icons/Gear';
import MessageIcon from '@rsuite/icons/Message';
import { forwardRef, useRef } from "react";
import { OverlayTriggerInstance } from "rsuite/esm/Overlay/OverlayTrigger";

interface Props {
    unreadMessageCount: number,
    activeKey: string,
    setActiveKey: (activeKey: string) => void,
    setMessageShow: (show: boolean) => void,
}

const contentStyle = {
    paddingLeft: '1%',
    paddingRight: '1%',
    margin: '1%'
}

export const SidebarPanel = (props: Props) => {
    const orderWhisperRref = useRef<OverlayTriggerInstance>(null);
    const userWhisperRref = useRef<OverlayTriggerInstance>(null);
    const definitionWhisperRref = useRef<OverlayTriggerInstance>(null);

    const handleSelectOrder = (eventKey: string, event: any) => {
        props.setActiveKey(eventKey);
        orderWhisperRref.current?.close();
    }

    const handleSelectUser = (eventKey: string, event: any) => {
        props.setActiveKey(eventKey);
        userWhisperRref.current?.close();
    }

    const handleSelectDefinition = (eventKey: string, event: any) => {
        props.setActiveKey(eventKey);
        definitionWhisperRref.current?.close();
    }

    const OrderPopOver = forwardRef<HTMLDivElement, any>(({ onSelect, ...rest }, ref) => (
        <Popover ref={ref} {...rest} full>
            <Dropdown.Menu onSelect={onSelect}>
                <Dropdown.Item active={props.activeKey === eventKeys.TranslationOrders.toString()} eventKey={eventKeys.TranslationOrders.toString()}>
                    Translation
                </Dropdown.Item>
                <Dropdown.Item active={props.activeKey === eventKeys.PictorialLanguageOrders.toString()} eventKey={eventKeys.PictorialLanguageOrders.toString()}>
                    Pictorial Language
                </Dropdown.Item>
            </Dropdown.Menu>
        </Popover>
    ));

    const UserPopOver = forwardRef<HTMLDivElement, any>(({ onSelect, ...rest }, ref) => (
        <Popover ref={ref} {...rest} full>
            <Dropdown.Menu onSelect={onSelect}>
                <Dropdown.Item active={props.activeKey === eventKeys.Translators.toString()} eventKey={eventKeys.Translators.toString()}>
                    Translators
                </Dropdown.Item>
                <Dropdown.Item active={props.activeKey === eventKeys.Designers.toString()} eventKey={eventKeys.Designers.toString()}>
                    Designers
                </Dropdown.Item>
                <Dropdown.Item active={props.activeKey === eventKeys.Users.toString()} eventKey={eventKeys.Users.toString()}>
                    Users
                </Dropdown.Item>
                <Dropdown.Item active={props.activeKey === eventKeys.Admins.toString()} eventKey={eventKeys.Admins.toString()}>
                    Admins
                </Dropdown.Item>
            </Dropdown.Menu>
        </Popover>
    ));

    const DefinitionPopOver = forwardRef<HTMLDivElement, any>(({ onSelect, ...rest }, ref) => (
        <Popover ref={ref} {...rest} full>
            <Dropdown.Menu onSelect={onSelect}>
                <Dropdown.Item active={props.activeKey === eventKeys.TranslationFee.toString()} eventKey={eventKeys.TranslationFee.toString()}>
                    Translation fee
                </Dropdown.Item>
                <Dropdown.Item active={props.activeKey === eventKeys.TranslationCategory.toString()} eventKey={eventKeys.TranslationCategory.toString()}>
                    Translation Category
                </Dropdown.Item>
                <Dropdown.Item active={props.activeKey === eventKeys.TranslationPlan.toString()} eventKey={eventKeys.TranslationPlan.toString()}>
                    Translation Plan
                </Dropdown.Item>
                <Dropdown.Item active={props.activeKey === eventKeys.Languages.toString()} eventKey={eventKeys.Languages.toString()}>
                    Languages
                </Dropdown.Item>
            </Dropdown.Menu>
        </Popover>
    ));

    return <Content style={contentStyle}>
        <Stack justifyContent="space-between">
            <Stack>
                <Button appearance="primary">
                    Write co.
                </Button>
            </Stack>
            <ButtonGroup size="md">
                <Button appearance="primary" active={props.activeKey === eventKeys.Dashboard.toString()} onClick={() => props.setActiveKey(eventKeys.Dashboard.toString())}>Dashboard</Button>
                <Button appearance="primary" active={props.activeKey === eventKeys.Blog.toString()} onClick={() => props.setActiveKey(eventKeys.Blog.toString())}>Blog</Button>
                <Button appearance="primary" active={props.activeKey === eventKeys.PictorialProducts.toString()} onClick={() => props.setActiveKey(eventKeys.PictorialProducts.toString())}>Pictorial Language</Button>
                <Whisper
                    placement="bottomStart"
                    controlId="order-id-with-dropdown"
                    trigger="click"
                    ref={orderWhisperRref}
                    speaker={<OrderPopOver onSelect={handleSelectOrder} />}
                >
                    <Button
                        active={
                            props.activeKey === eventKeys.Orders.toString()
                            || props.activeKey === eventKeys.TranslationOrders.toString()
                            || props.activeKey === eventKeys.PictorialLanguageOrders.toString()
                        }
                        appearance="primary">Orders</Button>
                </Whisper>
                <Whisper
                    placement="bottomStart"
                    controlId="user-id-with-dropdown"
                    trigger="click"
                    ref={userWhisperRref}
                    speaker={<UserPopOver onSelect={handleSelectUser} />}
                >
                    <Button
                        active={
                            props.activeKey === eventKeys.Translators.toString()
                            || props.activeKey === eventKeys.Designers.toString()
                            || props.activeKey === eventKeys.Users.toString()
                            || props.activeKey === eventKeys.Admins.toString()
                        }
                        appearance="primary">Users</Button>
                </Whisper>
                <Whisper
                    placement="bottomStart"
                    controlId="definition-id-with-dropdown"
                    trigger="click"
                    ref={definitionWhisperRref}
                    speaker={<DefinitionPopOver onSelect={handleSelectDefinition} />}
                >
                    <Button
                        active={
                            props.activeKey === eventKeys.TranslationFee.toString()
                            || props.activeKey === eventKeys.Languages.toString()
                            || props.activeKey === eventKeys.TranslationCategory.toString()
                            || props.activeKey === eventKeys.TranslationPlan.toString()
                        }
                        appearance="primary">Definition</Button>
                </Whisper>
            </ButtonGroup>
            <Stack spacing={6}>
                <Badge content={props.unreadMessageCount > 0 ? props.unreadMessageCount : false}>
                    <IconButton size="md" appearance="primary" onClick={() => props.setMessageShow(true)} icon={<MessageIcon />} />
                </Badge>
                <IconButton size="md" appearance="primary" onClick={() => props.setActiveKey(eventKeys.ConfigurationManager.toString())} icon={<GearIcon />} />
            </Stack>
        </Stack>
    </Content>
}