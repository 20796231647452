import { IconButton, Stack, toaster } from "rsuite";
import PlusIcon from '@rsuite/icons/Plus';
import { useReducer } from "react";
import toolbarReducer from "./Reducer/toolbarReducer";
import apiHelper from "../../Helper/apiHelper";
import { getNotificationElement } from "../../Helper/notificationHelper";
import { Review, Trash } from "@rsuite/icons";
import Approvement from "./approvement";
import Define from "./define";
import WriteCoAlert from "../Base/Modals/alert";

interface Props {
    selectedRecordId: React.MutableRefObject<number>,
    onTableReferesh: () => void
}

const Toolbar = (props: Props) => {
    const { reducer, initialState } = toolbarReducer();
    const [state, dispatch] = useReducer(reducer, initialState);
    const { postApi, getApi, getApiPath } = apiHelper();

    const handleAddClick = () => {
        dispatch({ type: "TOGGLE_OPEN_ADD_FORM", open: true });
    }

    const handleCloseClick = () => {
        dispatch({ type: "TOGGLE_OPEN_ADD_FORM", open: false });
    }

    const handleApproveClick = () => {
        if (props.selectedRecordId && props.selectedRecordId.current && props.selectedRecordId.current !== 0) {
            getApi<boolean>('/pictorialLanguage/GetApprovementStatus?id=' + props.selectedRecordId.current)
                .then((res: boolean) => {
                    if (res)
                        toaster.push(getNotificationElement('Approvement denied', 'This item is already approved', "error"), { placement: 'topEnd' });
                    else
                        dispatch({ type: "TOGGLE_OPEN_APPROVEMENT_FORM", open: true, selectedId: props.selectedRecordId.current })
                })
                .catch((error: any) => {
                    const message = error.response.status === 401 ? "Email or password is incorrect" : "Server error";

                    const notificationElement = getNotificationElement("Loading faild", message, "error");
                    toaster.push(notificationElement, { placement: 'topEnd' });
                });
        }
    }

    const handleDeleteClick = () => {
        if (props.selectedRecordId && props.selectedRecordId.current && props.selectedRecordId.current !== 0)
            dispatch({ type: "OPEN_ALERT" })
    }

    const handleConfirmRemove = () => {
        getApi<boolean>('/pictorialLanguage/Remove?id=' + props.selectedRecordId.current)
            .then((res: boolean) => {
                props.onTableReferesh();
            })
            .catch((error: any) => {
                let message = "";
                switch (error.response.status) {
                    case 401:
                        message = "Email or password is incorrect";
                        break;
                    case 400:
                        message = "Can't delete sold PictorialLanguage";
                        break;
                    default:
                        message = "Server error";
                }

                const notificationElement = getNotificationElement(message, message, "error");
                toaster.push(notificationElement, { placement: 'topEnd' });
                props.onTableReferesh();
            });
    }

    const handleApprovementFormClose = () => {
        dispatch({ type: "TOGGLE_OPEN_APPROVEMENT_FORM", open: false, selectedId: 0 });
        props.onTableReferesh();
    }

    return <Stack justifyContent="space-between">
        <Stack justifyContent="space-between">
            <Stack spacing={6} justifyContent="flex-start">
                <IconButton onClick={handleAddClick} size="sm" appearance="primary" icon={<PlusIcon />}></IconButton>
                <IconButton onClick={handleApproveClick} size="sm" icon={<Review />}></IconButton>
                <IconButton onClick={handleDeleteClick} size="sm" icon={<Trash />}></IconButton>
                <Define onClose={handleCloseClick} open={state.showAddForm} refreshData={props.onTableReferesh} />
                <Approvement open={state.showApprovementForm} onClose={handleApprovementFormClose} selectedId={props.selectedRecordId} />
                <WriteCoAlert onResult={handleConfirmRemove} open={state.showAlert}>
                    Are you sure?
                </WriteCoAlert>
            </Stack>
        </Stack>
    </Stack>

}

export default Toolbar;