import { createRef, useReducer, useRef } from "react";
import { Input, InputGroup, Stack } from "rsuite";
import WriteCoForm from "../Base/Form/form";
import IAdminViewModel from "../Interfaces/Admin/IAdminViewModel";
import AdminsTable from "./adminsTable";
import adminManagerReducer from "./Reducer";
import ToolbarButtons from "./toolbarButtons";
import SearchIcon from '@rsuite/icons/Search';

const ListManager = () => {
const { Toolbar, Body } = WriteCoForm;
    const { reducer, initialState } = adminManagerReducer();
    const searchRef = createRef<HTMLInputElement>();
    const [state, dispatch] = useReducer(reducer, initialState);
    const selectedId = useRef(0);

    const handleSearchClick = () => {
        if (searchRef.current)
            dispatch({ type: "CHANGE_NAME_FILTER", nameFilter: searchRef.current.value });
    }

    const onRowSelect = (rowData: IAdminViewModel) => {
        selectedId.current = rowData.id;
    }

    const refreshData = () => {
        dispatch({ type: "REFRESH_DATA" });
    }

    const AdminToolbar = () => {
        return <Stack justifyContent="space-between">
            <ToolbarButtons selectedId={selectedId} refreshData={refreshData} />
            <Stack spacing={6} justifyContent="flex-end">
                <InputGroup size="sm">
                    <Input defaultValue={state.nameFilter} ref={searchRef} />
                    <InputGroup.Button onClick={handleSearchClick}>
                        <SearchIcon />
                    </InputGroup.Button>
                </InputGroup>
            </Stack>
        </Stack>
    }

    return <WriteCoForm type="Panel" panelHeader="Admin manager">
        <Toolbar>
            <AdminToolbar />
        </Toolbar>
        <Body>
            <AdminsTable filter={state.filter} onRowSelect={onRowSelect} />
        </Body>
    </WriteCoForm>

}

export default ListManager;