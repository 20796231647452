const toolbarReducer = () => {
    interface State {
        loading: boolean,
    }

    type Action =
        | { type: 'SET_LOADING'; loading: boolean }

    const initialState: State = {
        loading: false,
    }

    const reducer = (state: State, action: Action): State => {
        switch (action.type) {
            case 'SET_LOADING':
                return {
                    ...state,
                    loading: action.loading,
                }
        }
    }

    return {
        reducer,
        initialState
    }

}

export default toolbarReducer;