import IPagedResultViewModel from "../../../Interfaces/IPagedResultViewModel"
import { IPagingFilterViewModel } from "../../../Interfaces/IPagingFilterViewModel";

const gridReducer = <T extends IPagingFilterViewModel>(filter: T) => {
    interface State {
        totalCount: number,
        model?: IPagedResultViewModel<any>,
        loading: boolean,
        selectedRowData?: any,
        filter: T,
    }

    type Action =
        | { type: 'SET_DATA'; model: IPagedResultViewModel<any> }
        | { type: 'SET_ERROR' }
        | { type: 'ON_PAGE_CHANGE'; pageIndex: number }
        | { type: 'ON_PAGE_SIZE_CHANGE'; pageSize: number }
        | { type: 'SET_SELECTED_ROW'; rowData: any }
        | { type: 'SET_LOADING'; loading: boolean }

    const initialState: State = {
        loading: true,
        totalCount: 0,
        filter: {
            ...filter,
            pageIndex: 1,
            pageSize: 10,
        },
    }

    const reducer = (state: State, action: Action): State => {
        switch (action.type) {
            case 'SET_DATA':
                return {
                    ...state,
                    loading: false,
                    model: action.model,
                    totalCount: action.model.count,
                    filter: { ...state.filter, pageIndex: action.model.pageIndex },
                }
            case 'SET_ERROR':
                return {
                    ...state,
                    loading: false,
                    model: undefined,
                }
            case 'ON_PAGE_CHANGE':
                return {
                    ...state,
                    filter: { ...state.filter, pageIndex: action.pageIndex }
                }
            case 'SET_LOADING':
                return {
                    ...state,
                    loading: action.loading,
                }
            case 'ON_PAGE_SIZE_CHANGE':
                return {
                    ...state,
                    filter: { ...state.filter, pageIndex: 1, pageSize: action.pageSize }
                }
            case 'SET_SELECTED_ROW':
                return {
                    ...state,
                    selectedRowData: action.rowData
                }
        }
    }

    return {
        reducer,
        initialState
    }

}

export default gridReducer;