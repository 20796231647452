import { useEffect, useReducer } from "react";
import { FlexboxGrid, Loader, Message, Modal, Panel, Progress, Steps, Timeline, useToaster } from "rsuite";
import apiHelper from "../../Helper/apiHelper";
import convertorHelper from "../../Helper/convertorHelper";
import { TranslationWorkflowGeneralState } from "../../Helper/Enumeration/TranslationWorkflowGeneralState";
import { TranslationWorkflowState } from "../../Helper/Enumeration/TranslationWorkflowState";
import { getNotificationElement } from "../../Helper/notificationHelper";
import translationWorkflowHelper from "../../Helper/translationWorkflowHelper";
import ITranslationWorkflowModificaitonViewModel from "../Interfaces/TranslationOrderManager/ITranslationWorkflowModificaitonViewModel";
import ITranslationWorkflowViewModel from "../Interfaces/TranslationOrderManager/ITranslationWorkflowViewModel";
import { TranslationRequestWorkflowManager } from "../TranslationRequestWorkflowManager";
import TranslationWorkflowFrom from "./from";
import translationWorkflowReducer from "./Reducer";

interface Props {
    open: boolean,
    onClose: () => void,
    refreshData: () => void,
    translationOrderId: number,
}

const Manger = (props: Props) => {
    const { getApi } = apiHelper();
    const { reducer, initialState } = translationWorkflowReducer();
    const [state, dispatch] = useReducer(reducer, initialState);
    const toaster = useToaster();
    const { convertToDateTime } = convertorHelper();
    const { getProgressStatus, getStateString, getGeneralState, getCurrentStatus } = translationWorkflowHelper();

    useEffect(() => {
        if (props.open && props.translationOrderId !== 0) {
            loadData();
        }
    }, [props.open]);

    const loadData = () => {
        setLoading(true);

        getApi<ITranslationWorkflowViewModel[]>('/TranslationWorkflow/GetAll?translationOrderId=' + props.translationOrderId)
            .then(handleGetResponse)
            .catch(handleFormError)
            .finally(() => setLoading(false));
    }

    const handleGetResponse = (res: ITranslationWorkflowViewModel[]) => {
        dispatch({ type: "ON_GET_DATA", data: res });
    }

    const setLoading = (loading: boolean) => {
        dispatch({ type: "CHANGE_LOADING", loading: loading });
    }

    const setFormValue = (value: ITranslationWorkflowModificaitonViewModel) => {
        dispatch({ type: "ON_CHANGE_FORM_VALUE", formValue: value });
    }

    const handleFormError = (error: any) => {
        const message = error.response.status === 401 ? "Email or password is incorrect" : "Server error";

        const notificationElement = getNotificationElement("Load faild", message, "error");
        toaster.push(notificationElement, { placement: 'topEnd' });
    }

    return <Modal open={props.open} onClose={props.onClose} size="full">
        <Modal.Header>
            <Modal.Title>Modal Title</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {state.data.length > 0 && state.formValue && state.lastData &&
                <FlexboxGrid>
                    <FlexboxGrid.Item colspan={4}>
                        <FlexboxGrid justify="center">
                            <FlexboxGrid.Item colspan={12}>
                                <div style={{ maxWidth: 120, margin: 10 }}>
                                    <Progress.Circle percent={state.lastData.percentage} status={getProgressStatus(state.lastData.state)} />
                                </div>
                            </FlexboxGrid.Item >
                        </FlexboxGrid>
                        <Timeline>
                            {state.data.map(i =>
                                <Timeline.Item>
                                    <p>{getStateString(i.state)}</p>
                                    <p style={{ color: "gray" }}>{convertToDateTime(i.date)}</p>
                                </Timeline.Item>
                            )}
                        </Timeline>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={20}>
                        <Panel bordered>
                            <Steps current={getGeneralState(state.lastData, state.data)} currentStatus={getCurrentStatus(state.lastData.state)}>
                                <Steps.Item stepNumber={TranslationWorkflowGeneralState.Register} title="Register" />
                                <Steps.Item stepNumber={TranslationWorkflowGeneralState.SendTranlatorState} title="Send to translators" />
                                <Steps.Item stepNumber={TranslationWorkflowGeneralState.Translating} title="Translating" />
                                <Steps.Item stepNumber={TranslationWorkflowGeneralState.End} title="End" />
                            </Steps>
                            <hr />
                            {state.lastData.comment !== "" &&
                                <>
                                    <Message showIcon type="info">
                                        Last comment: {state.lastData.comment}
                                    </Message>
                                    <br />
                                </>
                            }
                            {state.lastData.state !== TranslationWorkflowState.SendTranlatorState ?
                                <TranslationWorkflowFrom
                                    data={state.lastData}
                                    formValue={state.formValue}
                                    loadData={loadData}
                                    setFormValue={setFormValue}
                                    setLoading={setLoading} />
                                :
                                <TranslationRequestWorkflowManager
                                    translationWorkflowGuid={state.lastData.guid}
                                    refreshData={loadData}
                                />
                            }
                        </Panel>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            }
            {state.loading &&
                <Loader backdrop content="loading..." vertical style={{ zIndex: 12 }} />
            }
        </Modal.Body>
    </Modal>
}

export default Manger;