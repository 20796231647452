import { createRef, useEffect, useReducer } from "react";
import { Button, ButtonToolbar, Col, Form, FormInstance, Grid, Modal, Row, Schema, useToaster } from "rsuite";
import apiHelper from "../../Helper/apiHelper";
import { TagReferenceType } from "../../Helper/Enumeration/TagReferenceType";
import { getNotificationElement } from "../../Helper/notificationHelper";
import HtmlEditor from "../Base/Editor/htmlEditor";
import ImageUploader from "../Base/FileManager/imageUploader";
import FormTextField from "../Base/FormControls/FormTextField";
import LanguageSelector from "../Base/LanguagePicker/languageSelector";
import TagSelector from "../Base/Tag/tagSelector";
import { IBlogModificationViewModel } from "../Interfaces/Blog/IBlogModificationViewModel";
import defineReducer from "./Reducer/defineReducer";

interface Props {
    open: boolean,
    onClose: () => void,
    refreshData: () => void,
}

const Define = (props: Props) => {
    const { reducer, initialState } = defineReducer();
    const [state, dispatch] = useReducer(reducer, initialState);
    const formRef = createRef<FormInstance>();
    const { postApi } = apiHelper();
    const toaster = useToaster();

    const model = Schema.Model<IBlogModificationViewModel>({
        title: Schema.Types.StringType().isRequired('Enter blog title'),
        tempFileName: Schema.Types.StringType().isRequired('Select image'),
        languageId: Schema.Types.NumberType().isRequired('Enter language'),
        id: Schema.Types.NumberType(),
        content: Schema.Types.StringType().isRequired("Enter content"),
        isActive: Schema.Types.BooleanType(),
        tags: Schema.Types.ArrayType()
    });

    const handleChange = (formValue: IBlogModificationViewModel | any) => {
        dispatch({ type: 'ON_CHANGE_FORM_VALUE', formValue });
    }

    const setLoading = (loading: boolean) => {
        dispatch({ type: "CHANGE_LOADING", loading });
    }

    useEffect(() => {
        if (props.open)
            dispatch({ type: "CLEAR_FORM_VALUE" });
    }, [props.open])

    const handleSubmit = () => {
        if (!formRef?.current?.check())
            return;

        setLoading(true);

        postApi<string>('/blog/Insert', state.formValue)
            .then(handleFormResponse)
            .catch(handleSaveFormError)
            .finally(() => setLoading(false));
    }

    const handleFormResponse = () => {
        props.refreshData();
    }

    const handleSaveFormError = (error: any) => {
        const message = error.response.status === 401 ? "Email or password is incorrect" : "Server error";

        const notificationElement = getNotificationElement("Save failed", message, "error");
        toaster.push(notificationElement, { placement: 'topEnd' });
    }

    return <Modal size='full' open={props.open} onClose={props.onClose} backdrop="static" >
        <Modal.Header>
            <Modal.Title>Blog details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form fluid model={model} ref={formRef} onChange={handleChange} onSubmit={handleSubmit} formValue={state.formValue}>
                <Grid fluid>
                    <Row>
                        <Col xs={6}>
                            <Form.Group>
                                <Form.Control name="tempFileName" accepter={ImageUploader} />
                            </Form.Group>
                        </Col>
                        <Col xs={18}>
                            <Row>
                                <Col xs={24}>
                                    <FormTextField
                                        title="Title"
                                        name="title" />
                                </Col>
                                <Col xs={24}>
                                    <Form.Group>
                                        <Form.ControlLabel>Language:</Form.ControlLabel>
                                        <Form.Control name="languageId" accepter={LanguageSelector} block />
                                    </Form.Group>
                                </Col>
                                <Col xs={24}>
                                    <Form.Group>
                                        <Form.ControlLabel>Tag:</Form.ControlLabel>
                                        <Form.Control name="tags" accepter={TagSelector} type={TagReferenceType.Blog} />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24}>
                            <Form.Group>
                                <Form.Control name="content" accepter={HtmlEditor} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Grid>
                <Form.Group>
                    <ButtonToolbar>
                        <Button loading={state.loading} type="submit" appearance="primary">
                            Save
                        </Button>
                    </ButtonToolbar>
                </Form.Group>
            </Form>
        </Modal.Body>
    </Modal>
}

export default Define;