import { useReducer } from "react";
import { IconButton, Stack, useToaster } from "rsuite";
import toolbarReducer from "./Reducer/toolbarReducer";
import PlusIcon from '@rsuite/icons/Plus';
import TrashIcon from '@rsuite/icons/Trash';
import EditIcon from '@rsuite/icons/Edit'
import Define from "./define";
import apiHelper from "../../Helper/apiHelper";
import { getNotificationElement } from "../../Helper/notificationHelper";
import WriteCoAlert from "../Base/Modals/alert";

interface Props {
    refreshData: () => void,
    selectedId?: React.MutableRefObject<number>,
}

const ToolbarButtons = (props: Props) => {
    const { reducer, initialState } = toolbarReducer();
    const [state, dispatch] = useReducer(reducer, initialState);
    const { getApi } = apiHelper();
    const toaster = useToaster();
    const handleEditClick = () => {
        if (props.selectedId && props.selectedId.current && props.selectedId.current !== 0)
            dispatch({ type: "SHOW_FORM", id: props.selectedId.current });
    }

    const handleAddClick = () => {
        dispatch({ type: "SHOW_FORM", id: 0 });
    }

    const handleDeleteClick = () => {
        if (props.selectedId && props.selectedId.current && props.selectedId.current !== 0) {
            dispatch({ type: "OPEN_ALERT" })
        }
    }

    const onClose = () => {
        dispatch({ type: "CLOSE_FORM" });
    }

    const onAlertResult = (result: boolean) => {
        dispatch({ type: "ON_ALERT_RESULT", result });

        if (props.selectedId && props.selectedId.current && props.selectedId.current !== 0 && result) {
            setDeleteLoading(true);

            getApi("/designer/Delete?id=" + props.selectedId.current)
                .then(handleFormResponse)
                .catch(handleFormError)
                .finally(() => setDeleteLoading(false));
        }
    }

    const setDeleteLoading = (loading: boolean) => {
        dispatch({ type: "CHANGE_DELETE_LOADING", loading: loading });
    }

    const handleFormError = (error: any) => {
        const message = error.response.status === 401 ? "Email or password is incorrect" : "Server error";

        const notificationElement = getNotificationElement("Delete faild", message, "error");
        toaster.push(notificationElement, { placement: 'topEnd' });
    }

    const handleFormResponse = () => props.refreshData();


    return <Stack justifyContent="space-between">
        <Stack spacing={6} justifyContent="flex-start">
            {/* <IconButton onClick={handleAddClick} size="sm" appearance="primary" icon={<PlusIcon />}></IconButton> */}
            <IconButton onClick={handleEditClick} size="sm" icon={<EditIcon />}></IconButton>
            {/* <IconButton onClick={handleDeleteClick} size="sm" color="red" icon={<TrashIcon />}></IconButton> */}
            <Define onClose={onClose} open={state.showForm} id={state.selectedId} refreshData={props.refreshData} />
            <WriteCoAlert onResult={onAlertResult} open={state.showAlert}>
                Are you sure?
            </WriteCoAlert>
        </Stack>
    </Stack>
}

export default ToolbarButtons;