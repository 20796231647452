import WriteCoGrid from "../Base/Grid/grid";
import ITranslationFeePagingFilterViewModel from "../Interfaces/TranslationFeeManager/ITranslationFeePagingFilterViewModel";
import ITranslationFeeViewModel from "../Interfaces/TranslationFeeManager/ITranslationFeeViewModel";

interface Props {
    filter: ITranslationFeePagingFilterViewModel,
    onRowSelect: (rowData: ITranslationFeeViewModel) => void,
}

const TranslationFeesTable = (props: Props) => {
    const { Data, Column } = WriteCoGrid;

    const onRowSelect = (rowData: any) => {
        props.onRowSelect(rowData as ITranslationFeeViewModel);
    }

    return <WriteCoGrid>
        <Data apiUrl="/translationFee/Search" filter={props.filter} showSelectableColumn onSelectRow={onRowSelect}>
            <Column dataKey='id' title="Code" width={50} type="regular" />
            <Column dataKey="fromLanguage" title="From" width={100} type="regular" />
            <Column dataKey="toLanguage" title="To" width={100} type="regular" />
            <Column dataKey="price" title="Price" width={150} type="price" />
            <Column dataKey="translationCategory" title="Category" width={150} type="regular" />
        </Data>
    </WriteCoGrid>
}

export default TranslationFeesTable;