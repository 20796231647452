import { Button, ButtonGroup, ButtonToolbar, Dropdown, IconButton, Popover, Whisper } from "rsuite";
import { Transforms, Element as SlateElement, Editor } from 'slate'
import { useSlate } from "slate-react";
import { AlignmentType, CustomElement, ElementType, FormattedTextType, getAlignment } from "./custom-types";
import BoldIcon from '@rsuite/icons/legacy/Bold';
import ItalicIcon from '@rsuite/icons/legacy/Italic';
import CodeIcon from '@rsuite/icons/legacy/Code';
import UnderlineIcon from '@rsuite/icons/legacy/Underline';
import AlignLeftIcon from '@rsuite/icons/legacy/AlignLeft';
import AlignCenterIcon from '@rsuite/icons/legacy/AlignCenter';
import AlignRightIcon from '@rsuite/icons/legacy/AlignRight';
import ParagraphIcon from '@rsuite/icons/legacy/Paragraph';
import QuoteRightIcon from '@rsuite/icons/legacy/QuoteRight';
import PencilIcon from '@rsuite/icons/legacy/Pencil';
import BookmarkIcon from '@rsuite/icons/legacy/Bookmark';
import StrikethroughIcon from '@rsuite/icons/legacy/Strikethrough';
import UndoIcon from '@rsuite/icons/legacy/Undo';
import { forwardRef, useRef } from "react";
import { OverlayTriggerInstance } from "rsuite/esm/Picker";

interface Props {
    bookmarkOptions?: string[],
}

const Toolbar = (props: Props) => {
    const editor = useSlate();
    const bookmarkWhisperRref = useRef<OverlayTriggerInstance>(null);

    const toggleMark = (key: FormattedTextType) => !isActiveMark(key) ? Editor.addMark(editor, key, true) : Editor.removeMark(editor, key);
    const setAlignment = (alignment: AlignmentType) => Transforms.setNodes<SlateElement>(editor, { alignmet: alignment, });
    const setType = (type: ElementType) => Transforms.setNodes<SlateElement>(editor, { type: type, });

    const isTypeActive = (type: ElementType) => isActiveNode(n => n.type === type)
    const isAlignementActive = (alignment: AlignmentType) => isActiveNode(n => getAlignment(n.alignmet) === alignment)

    const bookmarkClick = (value: string) => Editor.insertText(editor, "@" + value + "@");

    const isActiveMark = (key: FormattedTextType) => {
        const marks = Editor.marks(editor)
        return marks ? marks[key] === true : false;
    }

    const isActiveNode = (validate: (element: CustomElement) => boolean) => {
        const { selection } = editor
        if (!selection) return false

        const [match] = Array.from(
            Editor.nodes(editor, {
                at: Editor.unhangRange(editor, selection),
                match: n =>
                    !Editor.isEditor(n) &&
                    SlateElement.isElement(n) &&
                    validate(n)
            })
        )

        return !!match
    }

    const handleSelectBookmark = (eventKey: string, event: any) => {
        bookmarkClick(eventKey);
        bookmarkWhisperRref.current?.close();
    }

    const BookmarkPopover = forwardRef<HTMLDivElement, any>(({ onSelect, ...rest }, ref) => (
        <Popover ref={ref} {...rest} full>
            <Dropdown.Menu onSelect={onSelect}>
                {props.bookmarkOptions?.map(i =>
                    <Dropdown.Item eventKey={i}>{i}</Dropdown.Item>
                )}
            </Dropdown.Menu>
        </Popover>
    ));

    return <ButtonToolbar style={{ position: "sticky", top: "0", background: "white", zIndex: 2 }}>
        <ButtonGroup>
            <IconButton onClick={() => toggleMark("bold")} active={isActiveMark("bold")} icon={<BoldIcon />} />
            <IconButton onClick={() => toggleMark("italic")} active={isActiveMark("italic")} icon={<ItalicIcon />} />
            <IconButton onClick={() => toggleMark("underline")} active={isActiveMark("underline")} icon={<UnderlineIcon />} />
            <IconButton onClick={() => toggleMark("strikethrough")} active={isActiveMark("strikethrough")} icon={<StrikethroughIcon />} />
            <IconButton onClick={() => toggleMark("mark")} active={isActiveMark("mark")} icon={<PencilIcon />} />
            <IconButton onClick={() => toggleMark("code")} active={isActiveMark("code")} icon={<CodeIcon />} />
        </ButtonGroup>
        <ButtonGroup>
            <IconButton onClick={() => setType("paragraph")} active={isTypeActive('paragraph')} icon={<ParagraphIcon />} />
            <Button onClick={() => setType("heading1")} active={isTypeActive('heading1')}><strong>H1</strong></Button>
            <Button onClick={() => setType("heading2")} active={isTypeActive('heading2')}><strong>H2</strong></Button>
            <Button onClick={() => setType("heading3")} active={isTypeActive('heading3')}><strong>H3</strong></Button>
            <IconButton onClick={() => setType("quote")} active={isTypeActive('quote')} icon={<QuoteRightIcon />} />
        </ButtonGroup>
        <ButtonGroup>
            <IconButton onClick={() => setAlignment("left")} active={isAlignementActive("left")} icon={<AlignLeftIcon />} />
            <IconButton onClick={() => setAlignment("center")} active={isAlignementActive("center")} icon={<AlignCenterIcon />} />
            <IconButton onClick={() => setAlignment("right")} active={isAlignementActive("right")} icon={<AlignRightIcon />} />
        </ButtonGroup>
        {props.bookmarkOptions &&
            <Whisper
                placement="bottomStart"
                controlId="control-id-with-dropdown"
                trigger="click"
                ref={bookmarkWhisperRref}
                speaker={<BookmarkPopover onSelect={handleSelectBookmark} />}
            >
                <IconButton icon={<BookmarkIcon />} />
            </Whisper>
        }
        <ButtonGroup>
            <IconButton onClick={() => editor.undo()} disabled={editor.history.undos.length === 0} icon={<UndoIcon />} />
            <IconButton onClick={() => editor.redo()} disabled={editor.history.redos.length === 0} icon={<UndoIcon style={{ transform: "scale(-1, 1)" }} />} />
        </ButtonGroup>
    </ButtonToolbar>
}

export default Toolbar;