import { useReducer, useRef } from "react";
import { Stack, Toggle } from "rsuite";
import WriteCoForm from "../Base/Form/form";
import ITranslationOrderSearchResultViewModel from "../Interfaces/TranslationOrderManager/ITranslationOrderSearchResultViewModel";
import translationOrderManagerReducer from "./Reducer";
import ToolbarButtons from "./toolbarButtons";
import TranslationOrderTable from "./translationOrderTable";

const ListManager = () => {
    const { Toolbar, Body } = WriteCoForm;
    const { reducer, initialState } = translationOrderManagerReducer();
    const [state, dispatch] = useReducer(reducer, initialState);
    const selectedData = useRef<ITranslationOrderSearchResultViewModel>();

    const onRowSelect = (rowData: ITranslationOrderSearchResultViewModel) => {
        selectedData.current = rowData;
    }

    const refreshData = () => {
        dispatch({ type: "REFRESH_DATA" });
    }

    const changeInProgress = (inProgress: boolean) => {
        dispatch({ type: "CHANGE_IN_PROGRESS", inPropgress: inProgress })
    }

    const handleToggleChange = (checked: boolean) => {
        changeInProgress(checked);
    }

    const TranslationOrderToolbar = () => {
        return <Stack justifyContent="space-between">
            <ToolbarButtons selectedData={selectedData} refreshData={refreshData} />
            <Toggle checked={state.filter.inProgress} onChange={handleToggleChange} checkedChildren="In progress" unCheckedChildren="Completed" />
        </Stack>
    }

    return <WriteCoForm type="Panel" panelHeader="Translation order manager">
        <Toolbar>
            <TranslationOrderToolbar />
        </Toolbar>
        <Body>
            <TranslationOrderTable filter={state.filter} onRowSelect={onRowSelect} />
        </Body>
    </WriteCoForm>
}

export default ListManager;