import { createRef, useReducer } from "react";
import { Button, ButtonToolbar, Form, FormInstance, Schema, SelectPicker } from "rsuite";
import LanguageSelector from "../Base/LanguagePicker/languageSelector";
import { IOrderPagingFilterViewModel } from "../Interfaces/Order/IOrderPagingFilterViewModel";
import OrderStateSelector from "./orderStateSelector";
import searchToolbarReducer from "./Reducer/searchToolbarReducer";

interface Props {
    onChangeFilter: (filter: IOrderPagingFilterViewModel) => void,
    filter: IOrderPagingFilterViewModel,
}

const SearchToolbar = (props: Props) => {
    const { reducer, initialState } = searchToolbarReducer(props.filter);
    const [state, dispatch] = useReducer(reducer, initialState);

    const formRef = createRef<FormInstance>();

    const model = Schema.Model<IOrderPagingFilterViewModel>({
        userId: Schema.Types.NumberType(),
        fromLanguageId: Schema.Types.NumberType(),
        toLanguageId: Schema.Types.NumberType(),
        state: Schema.Types.NumberType(),
        pageIndex: Schema.Types.NumberType(),
        pageSize: Schema.Types.NumberType(),
    });

    const handleChange = (value: IOrderPagingFilterViewModel | any) => {
        dispatch({ type: "ON_CHANGE_FORM_VALUE", formValue: value });
    }

    const handleSubmit = () => {
        props.onChangeFilter(state.formValue);
    }


    return <Form layout="inline" fluid ref={formRef} model={model} onChange={handleChange} onSubmit={handleSubmit} formValue={state.formValue}>
        <Form.Group>
            <Form.ControlLabel>State: </Form.ControlLabel>
            <Form.Control name="state" accepter={OrderStateSelector} />
        </Form.Group>
        <Form.Group>
            <Form.ControlLabel>From: </Form.ControlLabel>
            <Form.Control name="fromLanguageId" accepter={LanguageSelector} />
        </Form.Group>
        <Form.Group>
            <Form.ControlLabel>To: </Form.ControlLabel>
            <Form.Control name="toLanguageId" accepter={LanguageSelector} />
        </Form.Group>
        <Form.Group>
            <ButtonToolbar>
                <Button appearance="default" type="submit" loading={state.loading}>Search</Button>
            </ButtonToolbar>
        </Form.Group>
    </Form>
}

export default SearchToolbar;