import IConfigurationUserDataEmailViewModel from "../../Interfaces/Configuration/IConfigurationUserDataEmailViewModel copy 2";

const userDataEmailConfigReducer = () => {
    interface State {
        loading: boolean,
        buttonLoading: boolean,
        data?: IConfigurationUserDataEmailViewModel,
    }

    const initialState: State = {
        loading: true,
        buttonLoading: false,
    }

    type Action =
        | { type: 'ON_GET_DATA'; data: IConfigurationUserDataEmailViewModel }
        | { type: 'SET_LOADING'; loading: boolean }
        | { type: 'SET_BUTTON_LOADING'; loading: boolean }

    const reducer = (state: State, action: Action): State => {
        switch (action.type) {
            case 'ON_GET_DATA':
                return {
                    ...state,
                    data: action.data,
                    loading: false,
                }
            case 'SET_LOADING':
                return {
                    ...state,
                    loading: action.loading,
                }
            case 'SET_BUTTON_LOADING':
                return {
                    ...state,
                    buttonLoading: action.loading,
                }
        }
    }

    return {
        reducer,
        initialState
    }

}

export default userDataEmailConfigReducer;