import { IconButton, Stack } from "rsuite";
import toolbarReducer from "./Reducer/toolbarReducer";
import { useReducer } from "react";
import PlusIcon from '@rsuite/icons/Plus';
import EditIcon from '@rsuite/icons/Edit'
import Define from "./define";

interface Props {
    refreshData: () => void,
    selectedId?: React.MutableRefObject<number>,
}

const ToolbarButtons = (props: Props) => {
    const { reducer, initialState } = toolbarReducer();
    const [state, dispatch] = useReducer(reducer, initialState);

    const handleEditClick = () => {
        if (props.selectedId && props.selectedId.current && props.selectedId.current !== 0)
            dispatch({ type: "SHOW_FORM", id: props.selectedId.current });
    }

    const handleAddClick = () => {
        dispatch({ type: "SHOW_FORM", id: 0 });
    }

    const onClose = () => {
        dispatch({ type: "CLOSE_FORM" });
    }
    return <Stack spacing={6} justifyContent="flex-start">
        <IconButton onClick={handleAddClick} size="sm" appearance="primary" icon={<PlusIcon />}></IconButton>
        <IconButton onClick={handleEditClick} size="sm" icon={<EditIcon />}></IconButton>
        <Define onClose={onClose} open={state.showForm} id={state.selectedId} refreshData={props.refreshData} />
    </Stack>
}

export default ToolbarButtons;