import { forwardRef, Ref } from "react";
import { Popover, Progress, Table, Whisper } from "rsuite";
import convertorHelper from "../../Helper/convertorHelper";
import translationWorkflowHelper from "../../Helper/translationWorkflowHelper";
import WriteCoGrid, { GridCustomColumnProps } from "../Base/Grid/grid";
import ITranslationOrderPagingFilterViewModel from "../Interfaces/TranslationOrderManager/ITranslationOrderPagingFilterViewModel";
import ITranslationOrderSearchResultViewModel from "../Interfaces/TranslationOrderManager/ITranslationOrderSearchResultViewModel";

interface Props {
    filter: ITranslationOrderPagingFilterViewModel
    onRowSelect: (rowData: ITranslationOrderSearchResultViewModel) => void,
}

const TranslationOrderTable = (props: Props) => {
    const { Data, Column } = WriteCoGrid;
    const { getProgressStatus, getStateString } = translationWorkflowHelper();
    const { convertToPrice, convertToNumber } = convertorHelper();

    const onRowSelect = (rowData: any) => {
        props.onRowSelect(rowData as ITranslationOrderSearchResultViewModel);
    }

    const BenefitCell = forwardRef((props: GridCustomColumnProps, ref: Ref<HTMLDivElement>) => {
        return <Table.Cell {...props} ref={ref}>
            {rowData =>
                <Whisper placement="auto" speaker={
                    <Popover>
                        <p>
                            <b>Translator pice:</b> {convertToPrice(rowData.translatorPrice)}
                        </p>
                        <p>
                            <b>Price:</b> {convertToPrice(rowData.price)}
                        </p>
                        <p>
                            <b>Word count:</b> {convertToNumber(rowData.wordCount)}
                        </p>
                        <p>
                            <b>Total translator price:</b> {convertToPrice(rowData.totalTranslatorPrice)}
                        </p>
                        <p>
                            <b>Increase plan price:</b> {convertToPrice(rowData.increasePlanPrice)}
                        </p>
                        <p>
                            <b>Total price:</b> {convertToPrice(rowData.totalPrice)}
                        </p>
                    </Popover>
                }>
                    <b style={rowData.benefit && { color: rowData.benefit > 0 ? "green" : "red" }}>{rowData.benefit && (rowData.benefit > 0 ? "+" : "")}{convertToPrice(rowData.benefit)}</b>
                </Whisper>
            }
        </Table.Cell >

    });

    const RemainDaysCell = forwardRef((props: GridCustomColumnProps, ref: Ref<HTMLDivElement>) => {
        const { convertToNumber } = convertorHelper();

        return <Table.Cell {...props} ref={ref} align="center">
            {rowData =>
                rowData.remainDays < 4 ?
                    <b style={{ color: "red" }}>{convertToNumber(rowData.remainDays)}</b>
                    :
                    <span>{convertToNumber(rowData.remainDays)}</span>
            }
        </Table.Cell >

    });

    const ProgressCell = forwardRef((props: GridCustomColumnProps, ref: Ref<HTMLDivElement>) => {
        return <Table.Cell {...props} ref={ref}>
            {rowData => <Progress showInfo={false} percent={rowData.percentage} status={getProgressStatus(rowData.translationWorkflowState)} />}
        </Table.Cell>

    });

    const UserCell = forwardRef((props: GridCustomColumnProps, ref: Ref<HTMLDivElement>) => {
        return <Table.Cell {...props} ref={ref} align="center">
            {rowData =>
                <Whisper placement="auto" speaker={
                    <Popover>
                        <p>
                            <b>First name:</b> {rowData.userFirstName}
                        </p>
                        <p>
                            <b>Last name:</b> {rowData.userLastName}
                        </p>
                        <p>
                            <b>Email:</b> {rowData.userEmail}
                        </p>
                    </Popover>
                }>
                    <span>{rowData.userFullName}</span>
                </Whisper>
            }
        </Table.Cell>

    });

    return <WriteCoGrid>
        <Data apiUrl="/translationOrder/Search" filter={props.filter} showSelectableColumn onSelectRow={onRowSelect}>
            <Column dataKey="fromLanguage" title="From" width={100} type="regular" />
            <Column dataKey="toLanguage" title="To" width={100} type="regular" />
            <BenefitCell title="Beneift" width={100} />
            <Column dataKey="translationWorkflowState" title="State" width={100} type="regular" getValue={getStateString} />
            <Column dataKey="lastDate" title="Last changes" width={100} type="date" />
            <RemainDaysCell title="Remain days" width={100} />
            <ProgressCell title="" width={200} />
            <UserCell title="User" width={150} />
        </Data>
    </WriteCoGrid>
}

export default TranslationOrderTable;