const imageUploaderReducer = () => {
    interface State {
        uploading: boolean,
        fileInfo?: string,
        tempFileName?: string,
    }

    const initialState: State = {
        uploading: false,
    }

    type Action =
        | { type: 'ENABLE_UPLOADING' }
        | { type: 'SET_FILE_INFO', fileInfo: string }
        | { type: 'ON_SUCCESS', tempFileName: string }
        | { type: 'ON_ERROR' }

    const reducer = (state: State, action: Action): State => {
        switch (action.type) {
            case 'ENABLE_UPLOADING':
                return {
                    ...state,
                    uploading: true,
                }
            case 'SET_FILE_INFO':
                return {
                    ...state,
                    fileInfo: action.fileInfo,
                }
            case 'ON_SUCCESS':
                return {
                    ...state,
                    tempFileName: action.tempFileName,
                    uploading: false,
                }
            case 'ON_ERROR':
                return {
                    ...state,
                    tempFileName: undefined,
                    fileInfo: undefined,
                    uploading: false,
                }
        }
    }

    return {
        initialState,
        reducer
    }
}

export default imageUploaderReducer;