import { PictorialLanguageCreationViewModel } from "../../Interfaces/PictorialLanguage/PictorialLanguageCreationViewModel";

const defineReducer = () => {

    interface State {
        formData?: PictorialLanguageCreationViewModel,
        loading: boolean,
    }

    const initialState: State = {
        loading: false,
    }

    type Action =
        | { type: 'SET_ADD_FORM_DATA', formData: PictorialLanguageCreationViewModel }
        | { type: 'SET_LOADING', loading: boolean }
        | { type: 'CLEAR_FORM_VALUE' }

    const reducer = (state: State, action: Action): State => {
        switch (action.type) {
            case 'SET_ADD_FORM_DATA':
                return {
                    ...state,
                    formData: action.formData
                }
            case 'SET_LOADING':
                return {
                    ...state,
                    loading: action.loading,
                }
            case 'CLEAR_FORM_VALUE':
                return initialState;
        }
    }

    return {
        initialState,
        reducer
    }
}

export default defineReducer;