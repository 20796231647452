const toolbarReducer = () => {
    interface State {
        showForm: boolean,
        showAlert: boolean,
        deleteLoading: boolean,
        alertResult?: boolean,
        selectedId?: number,
    }

    type Action =
        | { type: 'SHOW_FORM'; id?: number }
        | { type: 'CLOSE_FORM'; }
        | { type: 'OPEN_ALERT'; }
        | { type: 'ON_ALERT_RESULT'; result: boolean }
        | { type: 'CHANGE_DELETE_LOADING'; loading: boolean }


    const initialState: State = {
        showForm: false,
        showAlert: false,
        deleteLoading: false,
    }

    const reducer = (state: State, action: Action): State => {
        switch (action.type) {
            case 'SHOW_FORM':
                return {
                    ...state,
                    showForm: true,
                    selectedId: action.id,
                }
            case 'CLOSE_FORM':
                return {
                    ...state,
                    showForm: false,
                }
            case 'OPEN_ALERT':
                return {
                    ...state,
                    showAlert: true,
                }
            case 'ON_ALERT_RESULT':
                return {
                    ...state,
                    showAlert: false,
                    alertResult: action.result,
                }
            case 'CHANGE_DELETE_LOADING':
                return {
                    ...state,
                    deleteLoading: action.loading,
                }
        }
    }

    return {
        reducer,
        initialState
    }

}

export default toolbarReducer;