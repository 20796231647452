import { ITranslationRequestWorkflowPagingFilterViewModel } from "../../Interfaces/TranslationOrderManager/ITranslationRequestWorkflowPagingFilterViewModel";
import { ITranslationRequestWorkflowSearchResultViewModel } from "../../Interfaces/TranslationOrderManager/ITranslationRequestWorkflowSearchResultViewModel";

const translationRequestWorkflowManagerReducer = (translationWorkflowGuid: string) => {
    interface State {
        filter: ITranslationRequestWorkflowPagingFilterViewModel,
        selectedData?: ITranslationRequestWorkflowSearchResultViewModel
    }

    type Action =
        | { type: 'REFRESH_DATA'; }
        | { type: 'SET_TRANSLATION_WORKFLOW_GUID', guid: string; }
        | { type: 'SET_SELECTED_DATA'; data: ITranslationRequestWorkflowSearchResultViewModel }

    const initialState: State = {
        filter: {
            pageIndex: 1,
            pageSize: 50,
            translationWorkflowGuid: translationWorkflowGuid,
        },
    }

    const reducer = (state: State, action: Action): State => {
        switch (action.type) {
            case 'REFRESH_DATA':
                return initialState;
            case 'SET_SELECTED_DATA':
                return {
                    ...state,
                    selectedData: action.data,
                }
            case 'SET_TRANSLATION_WORKFLOW_GUID':
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        translationWorkflowGuid: action.guid,
                    }
                }
        }
    }

    return {
        reducer,
        initialState
    }

}

export default translationRequestWorkflowManagerReducer;