const orderStateSelectorStateReducer = () => {
    interface State {
        value?: number,
    }

    type Action =
        | { type: 'CHANGE_VALUE'; value: number }

    const initialState: State = {

    }

    const reducer = (state: State, action: Action): State => {
        switch (action.type) {
            case 'CHANGE_VALUE':
                return {
                    ...state,
                    value: action.value,
                }
        }
    }

    return {
        reducer,
        initialState
    }

}

export default orderStateSelectorStateReducer;