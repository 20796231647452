import * as react from 'react';
import { Button, ButtonToolbar, Form, FormInstance, Schema, useToaster } from 'rsuite';
import { getNotificationElement } from '../../Helper/notificationHelper';
import ILoginViewModel from '../Interfaces/ILogonViewModel';
import FormTextField from '../Base/FormControls/FormTextField';
import FormCheckbox from '../Base/FormControls/FormCheckbox';
import { TokenContext } from '../Contexts/TokenContext';
import apiHelper from '../../Helper/apiHelper';

const Login = () => {
    const tokenContext = react.useContext(TokenContext);

    const [loading, setLoading] = react.useState(false);
    const toaster = useToaster();
    const formRef = react.createRef<FormInstance>();
    const { postApi } = apiHelper();

    const model = Schema.Model<ILoginViewModel>({
        email: Schema.Types.StringType().isRequired('Enter your Email address').isEmail('Enter a valid Email address'),
        password: Schema.Types.StringType().isRequired('Enter your password'),
        rememberMe: Schema.Types.BooleanType(),
    });

    const [formValue, setFormValue] = react.useState<ILoginViewModel>({
        email: '',
        password: '',
        rememberMe: false,
    });

    const handleSubmit = () => {
        if (!formRef?.current?.check())
            return;

        setLoading(true);

        postApi<string>('/user/Login', formValue)
            .then(handleFormResponse)
            .catch(handleFormError)
            .finally(() => {
                setLoading(false);
            })
    }

    const handleChange = (value: ILoginViewModel | any) => {
        setFormValue(value);
    }

    const handleFormResponse = (res: string) => {
        tokenContext.setToken(res, formValue.rememberMe);

        const notificationElement = getNotificationElement("Sign in successful", "Redirecting...", "success");
        toaster.push(notificationElement, { placement: 'topEnd' });
    }

    const handleFormError = (error: any) => {
        const message = error.response.status === 401 ? "Email or password is incorrect" : "Server error";

        const notificationElement = getNotificationElement("Login faild", message, "error");
        toaster.push(notificationElement, { placement: 'topEnd' });
    }

    return <>
        <Form ref={formRef} model={model} onChange={handleChange} onSubmit={handleSubmit} formValue={formValue}>
            <FormTextField name="email" title="Email" />
            <FormTextField name="password" title="Password" type='password' />
            <FormCheckbox name="rememberMe" label="Remeber me" checkedValue={formValue.rememberMe} />
            <ButtonToolbar>
                <Button appearance="primary" type="submit" loading={loading}>
                    Login
                </Button>
            </ButtonToolbar>
        </Form>
    </>

}

export default Login;