import WriteCoGrid from "../Base/Grid/grid";
import { IUserPagingFilterViewModel } from "../Interfaces/User/IUserPagingFilterViewModel";

interface Props {
    filter: IUserPagingFilterViewModel,
}

const UserTable = (props: Props) => {

    const { Data, Column } = WriteCoGrid;

    return <WriteCoGrid>
        <Data apiUrl="/user/Search" filter={props.filter}>
            <Column dataKey='id' title="Code" width={100} type="regular" />
            <Column dataKey="firstName" title="FirstName" width={150} type="regular" />
            <Column dataKey="lastName" title="LastName" width={150} type="regular" />
            <Column dataKey="email" title="Email" width={200} type="regular" />
            <Column dataKey="isActive" title="IsActive" width={100} type="boolean" />
        </Data>
    </WriteCoGrid>
}

export default UserTable;