export enum navbarItems {
    Dashboard = 1,
    Blog = 2,
    Orders = 3,
    Translators = 4,
    Designers = 5,
    Users = 6,
    Admins = 7,
    Languages = 9,
    TranslationFee = 10,
    TranslationOrders = 11,
    ConfigurationManager = 12,
    TranslationCategory = 13,
    TranslationPlan = 14,
    PictorialProducts = 15,
    PictorialLanguageOrders = 16
}