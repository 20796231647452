import { CSSProperties } from 'react';
import { Descendant, Text } from 'slate'
import { AlignmentType, CustomElement, ElementType, FontSizeType, FormattedText, getAlignment } from './custom-types';
import { jsx } from 'slate-hyperscript'
import leafWrapper from './leafWrapper';

type HtmlTag = 'h1' | 'h2' | 'h3' | 'blockquote' | 'p';

interface HtmlStringProps {
    children: string,
    style: string,
}

interface ElementProps {
    children: any,
    style: CSSProperties,
    attributes: {
        'data-slate-node': 'element';
        'data-slate-inline'?: true;
        'data-slate-void'?: true;
        dir?: 'rtl';
        ref: any;
    },
}

interface ElementSlateProps {
    children: any,
    alignmet: AlignmentType,
    fontSize: FontSizeType,
}

interface ElementWrapper {
    htmlTag: HtmlTag,
    getHtmlString: (props: HtmlStringProps) => string,
    getElement: (props: ElementProps) => JSX.Element,
    getElementSlate: (props: ElementSlateProps) => CustomElement,
}

const elementWrapper = () => {
    const { serializeLeaf, deserializeLeaf } = leafWrapper();

    const serialize = (node: CustomElement) => {
        const children = node.children.map(n => {
            const res: string = !Text.isText(n) ? serialize(n) : serializeLeaf(n);
            return res;
        }).join('');

        const align = getAlignment(node.alignmet);

        const style = "text-align: " + align;

        return getElement(node.type).getHtmlString({ children, style });
    }

    const deserialize = (html: string) => {
        const document = new DOMParser().parseFromString(html, 'text/html')
        return deserializeFromNode(document.body);
    }

    const deserializeFromNode = (el: HTMLElement, markAttributes = {}) => {
        if (el.nodeType === Node.TEXT_NODE) {
            return jsx('text', markAttributes, el.textContent)
        } else if (el.nodeType !== Node.ELEMENT_NODE) {
            return undefined
        }

        const nodeAttributes = deserializeLeaf(el, markAttributes);

        const children = Array.from(el.childNodes)
            .map(n => {
                const res: CustomElement | FormattedText | Descendant[] = deserializeFromNode(n as HTMLElement, nodeAttributes) as CustomElement | FormattedText | Descendant[];
                return res;
            })
            .flat()

        if (children.length === 0) {
            children.push(jsx('text', nodeAttributes, ''))
        }

        const htmlTag = getHtmlTagFromString(el.nodeName.toLowerCase());
        if (!!!htmlTag) {
            if (el.nodeName === 'BODY')
                return jsx('fragment', {}, children)

            return children;
        }

        return getElementByHtmlTag(htmlTag ? htmlTag : "p").getElementSlate({
            children,
            fontSize: el.style.fontSize as FontSizeType,
            alignmet: el.style.textAlign as AlignmentType,
        });
    }

    return {
        serialize,
        deserialize,
    }
}

export const getElement = (key: ElementType): ElementWrapper => {
    return getElementByHtmlTag(elementTagFromKey(key));
}

const getHtmlTagFromString = (htmlTag: string): HtmlTag | undefined => {
    switch (htmlTag) {
        case 'h1':
            return "h1";
        case 'h2':
            return 'h2';
        case 'h3':
            return 'h3';
        case 'blockquote':
            return 'blockquote';
        case 'p':
            return 'p';
    }
}

const getElementByHtmlTag = (htmlTag: HtmlTag): ElementWrapper => {
    switch (htmlTag) {
        case 'h1':
            return {
                htmlTag: "h1",
                getElement: ({ style, attributes, children }) => <h1 style={style} {...attributes}>{children}</h1>,
                getHtmlString: (props) => getHtmlString('h1', props),
                getElementSlate: (props) => getElementSlate('h1', props),
            };
        case 'h2':
            return {
                htmlTag: "h2",
                getElement: ({ style, attributes, children }) => <h2 style={style} {...attributes}>{children}</h2>,
                getHtmlString: (props) => getHtmlString('h2', props),
                getElementSlate: (props) => getElementSlate('h2', props),
            }
        case 'h3':
            return {
                htmlTag: "h3",
                getElement: ({ style, attributes, children }) => <h3 style={style} {...attributes}>{children}</h3>,
                getHtmlString: (props) => getHtmlString('h3', props),
                getElementSlate: (props) => getElementSlate('h3', props),
            }
        case 'blockquote':
            return {
                htmlTag: "blockquote",
                getElement: ({ style, attributes, children }) => <blockquote style={style} {...attributes}>{children}</blockquote>,
                getHtmlString: (props) => getHtmlString('blockquote', props),
                getElementSlate: (props) => getElementSlate('blockquote', props),
            }
        case 'p':
            return {
                htmlTag: "p",
                getElement: ({ style, attributes, children }) => <p style={style} {...attributes}>{children}</p>,
                getHtmlString: (props) => getHtmlString('p', props),
                getElementSlate: (props) => getElementSlate('p', props),
            }
    }
}

const getHtmlString = (htmlTag: HtmlTag, { children, style }: HtmlStringProps): string => {
    return "<" + htmlTag + " style='" + style + "'>" + children + "</" + htmlTag + ">";
}


const getElementSlate = (htmlTag: HtmlTag, { alignmet, children, fontSize }: ElementSlateProps): CustomElement => {
    return jsx('element', {
        type: elementKeyFromTag(htmlTag),
        fontSize,
        alignmet
    }, children);
}

export const elementFontSizeTitle = (fonstSize: FontSizeType): string => {
    switch (fonstSize) {
        case 'large':
            return 'Large';
        case 'larger':
            return 'Larger';
        case 'medium':
            return 'Medium';
        case 'small':
            return 'Small';
        case 'smaller':
            return 'Smaller';
    }
}

export const elementTitleFromKey = (key: ElementType): string => {
    switch (key) {
        case 'heading1':
            return 'Heading 1';
        case "heading2":
            return "Heading 2";
        case "heading3":
            return "Heading 3";
        case "paragraph":
            return "Paragraph";
        case 'quote':
            return 'Quote';
    }
}

const elementTagFromKey = (key: ElementType): HtmlTag => {
    switch (key) {
        case 'heading1':
            return 'h1';
        case "heading2":
            return "h2";
        case "heading3":
            return "h3";
        case "paragraph":
            return "p";
        case 'quote':
            return 'blockquote';
    }
}

const elementKeyFromTag = (htmlTag: HtmlTag): ElementType => {
    switch (htmlTag) {
        case 'h1':
            return "heading1";
        case 'h2':
            return "heading2";
        case 'h3':
            return 'heading3';
        case 'blockquote':
            return 'quote';
        case 'p':
            return 'paragraph';
    }
}

export default elementWrapper;