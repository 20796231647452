import { ItemDataType } from "rsuite/esm/@types/common";
import { ILanguageViewModel } from "../Components/Interfaces/LanguageManager/ILanguageViewModel";
import apiHelper from "./apiHelper";


const languageHelper = () => {

    const { getApi } = apiHelper();

    const getChildren = (languageData: ILanguageViewModel[], id: number, parent: string) => {
        return languageData.filter(i => i.id !== id).map(i => {
            const data: ItemDataType = {
                value: id + "-" + i.id,
                label: i.name,
                parentName: parent
            };

            return data;
        });
    }

    const getLanguageItemData = async (getChildrenForTag: boolean) => {
        const languageData = await getLanguages();

        if (!getChildrenForTag) {
            return languageData.map(i => {
                const data: ItemDataType = {
                    value: i.id,
                    label: i.name,
                }

                return data;
            });
        }
        else {
            const result: ItemDataType[] = [];
            languageData.forEach(i => result.push(...getChildren(languageData, i.id, i.name)));

            return result;
        }
    }

    const getLanguages = async () => {
        return await getApi<ILanguageViewModel[]>('/Language/GetAll');
    }

    return {
        getLanguageItemData,
        getLanguages,
    }

}

export default languageHelper;