import { MessagePayload } from "firebase/messaging"
import { useEffect, useState } from "react"
import { useToaster } from "rsuite"
import IFirebaseMessageBodyViewModel from "../Components/Interfaces/Message/IFirebaseMessageBodyViewModel"
import ISetRecieveMessageViewModel from "../Components/Interfaces/Message/ISetRecieveMessageViewModel"
import apiHelper from "../Helper/apiHelper"
import { useFirebaseApp } from "../Helper/firebaseHelper"
import { getNotificationElement } from "../Helper/notificationHelper"
import HomePage from "./homePage"

const MainPage = () => {
    const toaster = useToaster();
    const { postApi, getApi } = apiHelper();
    const [unreadMessageCount, setUnreadMessageCount] = useState(0);

    useEffect(() => {
        getApi<number>('/Message/GetUnreadCount')
            .then(handleResponse);
    }, []);

    const handleResponse = (res: number) => setUnreadMessageCount(res);

    const setRecieveMessage = (messageId: number) => {
        const setRecieveMessageViewModel: ISetRecieveMessageViewModel = {
            messageId: messageId,
        };

        postApi('/Message/SetRecieveMessage', setRecieveMessageViewModel);
    }

    const onGetMessage = (message: MessagePayload) => {
        if (message.data && message.data.title && message.data.body) {
            const messageBody: IFirebaseMessageBodyViewModel = JSON.parse(message.data.body);

            const notificationElement = getNotificationElement(messageBody.title, messageBody.body, "info");
            toaster.push(notificationElement, { placement: 'topEnd' });

            setRecieveMessage(messageBody.messageId);
            setUnreadMessageCount(messageBody.unreadCount);
        }
    }

    useFirebaseApp(onGetMessage);

    return <HomePage unreadMessageCount={unreadMessageCount} setUnreadMessageCount={setUnreadMessageCount} />
}

export default MainPage