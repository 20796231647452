// This example is for an Editor with `ReactEditor` and `HistoryEditor`
import { BaseEditor } from 'slate'
import { ReactEditor } from 'slate-react'
import { HistoryEditor } from 'slate-history'
import { forEachLeadingCommentRange } from 'typescript'

export type CustomEditor = BaseEditor & ReactEditor & HistoryEditor

export type ElementType = 'paragraph' | 'heading1' | 'heading2' | 'heading3' | 'quote'
export type AlignmentType = 'left' | 'right' | 'center'
export type FontSizeType = "small" | "smaller" | "medium" | "large" | "larger"
export type FormattedTextType = KeyNotOfType<FormattedText, "text">

type KeyNotOfType<T, V> = keyof {
  [P in keyof T as V extends P ? never : P]: any
}

export type EmptyOfType<T extends string> = {
  [P in T]: string
}

export type ParagraphElement = {
  type: ElementType,
  children: CustomText[],
  alignmet?: AlignmentType,
}

export const getAlignment = (alignmet?: AlignmentType) => !!alignmet ? alignmet : "left";

export type CustomElement = ParagraphElement

export type FormattedText = { text: string; bold?: boolean, italic?: boolean, underline?: boolean, code?: boolean, mark?: boolean, strikethrough?: boolean }

export type CustomText = FormattedText

declare module 'slate' {
  interface CustomTypes {
    Editor: CustomEditor
    Element: CustomElement
    Text: CustomText
  }
}