import { IBlogModificationViewModel } from "../../Interfaces/Blog/IBlogModificationViewModel";

const defineReducer = () => {
    interface State {
        loading: boolean,
        formValue?: IBlogModificationViewModel,
    }

    type Action =
        | { type: 'CHANGE_LOADING'; loading: boolean }
        | { type: 'CLEAR_FORM_VALUE'; }
        | { type: 'ON_CHANGE_FORM_VALUE'; formValue: IBlogModificationViewModel }

    const initialState: State = {
        loading: false,
    }

    const reducer = (state: State, action: Action): State => {
        switch (action.type) {
            case 'CHANGE_LOADING':
                return {
                    ...state,
                    loading: action.loading,
                }
            case 'CLEAR_FORM_VALUE':
                return initialState;
            case 'ON_CHANGE_FORM_VALUE':
                return {
                    ...state,
                    formValue: action.formValue,
                }
        }
    }

    return {
        reducer,
        initialState
    }

}

export default defineReducer;