import { useState } from "react"
import { Button, Modal } from "rsuite"

interface Props {
    open: boolean,
    onResult: (result: boolean) => void,
    children: React.ReactNode,
}

const WriteCoAlert = (props: Props) => {
    const handleClose = () => props.onResult(false);
    const handleOk = () => props.onResult(true);

    return <Modal backdrop="static" role="alertdialog" open={props.open} onClose={handleClose} size="xs">
        <Modal.Body>
            {props.children}
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={handleOk} appearance="primary">
                Ok
            </Button>
            <Button onClick={handleClose} appearance="subtle">
                Cancel
            </Button>
        </Modal.Footer>
    </Modal>
}

export default WriteCoAlert;