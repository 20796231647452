import { useEffect, useReducer, useRef } from "react";
import WriteCoForm from "../Base/Form/form";
import TranslationRequestWorkflowTable from "./translationRequestWorkflowTable";
import { ITranslationRequestWorkflowSearchResultViewModel } from "../Interfaces/TranslationOrderManager/ITranslationRequestWorkflowSearchResultViewModel";
import { Stack } from "rsuite";
import ToolbarButtons from "./toolbarButtons";
import translationRequestWorkflowManagerReducer from "./Reducer";


interface Props {
    translationWorkflowGuid: string,
    refreshData: () => void,
}

const ListManager = (props: Props) => {
    const { Toolbar, Body } = WriteCoForm;
    const { reducer, initialState } = translationRequestWorkflowManagerReducer(props.translationWorkflowGuid);
    const [state, dispatch] = useReducer(reducer, initialState);
    const selectedData = useRef<ITranslationRequestWorkflowSearchResultViewModel>();

    const onRowSelect = (rowData: ITranslationRequestWorkflowSearchResultViewModel) => {
        selectedData.current = rowData;
    }

    const TranslatorToolbar = () => {
        return <Stack justifyContent="space-between">
            <ToolbarButtons selectedData={selectedData} refreshData={props.refreshData} />
        </Stack>
    }

    useEffect(() => {
        dispatch({ type: "SET_TRANSLATION_WORKFLOW_GUID", guid: props.translationWorkflowGuid });
    }, [props.translationWorkflowGuid])

    return <WriteCoForm type="Default">
        <Toolbar>
            <TranslatorToolbar />
        </Toolbar>
        <Body>
            <TranslationRequestWorkflowTable filter={state.filter} onRowSelect={onRowSelect} />
        </Body>
    </WriteCoForm>
}

export default ListManager;