import ITranslationOrderPagingFilterViewModel from "../../Interfaces/TranslationOrderManager/ITranslationOrderPagingFilterViewModel";

const translationOrderManagerReducer = () => {
    interface State {
        filter: ITranslationOrderPagingFilterViewModel,
        selectedId?: number,
    }

    type Action =
        | { type: 'REFRESH_DATA'; }
        | { type: 'CHANGE_IN_PROGRESS', inPropgress: boolean; }

    const initialState: State = {
        filter: {
            pageIndex: 1,
            pageSize: 10,
            inProgress: true,
        },
    }

    const reducer = (state: State, action: Action): State => {
        switch (action.type) {
            case 'REFRESH_DATA':
                return initialState;
        }
        switch (action.type) {
            case 'CHANGE_IN_PROGRESS':
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        inProgress: action.inPropgress,
                    }
                };
        }
    }

    return {
        reducer,
        initialState
    }

}

export default translationOrderManagerReducer;