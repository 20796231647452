import { createRef, useEffect, useReducer } from "react";
import { Button, ButtonToolbar, Col, Drawer, Form, FormInstance, Grid, Input, Loader, Row, Schema, toaster } from "rsuite";
import apiHelper from "../../Helper/apiHelper";
import convertorHelper from "../../Helper/convertorHelper";
import { getNotificationElement } from "../../Helper/notificationHelper";
import { PictorialLanguagePriceViewModel } from "../Interfaces/PictorialLanguage/PictorialLanguagePriceViewModel";
import approvementReducer from "./Reducer/approvementReducer";

interface Props {
    open: boolean,
    onClose: () => void,
    selectedId: React.MutableRefObject<number>
}

const Approvement = (props: Props) => {
    const formRef = createRef<FormInstance>();
    const { postApi, getApi } = apiHelper();
    const model = Schema.Model<PictorialLanguagePriceViewModel>({
        id: Schema.Types.NumberType(),
        adminPrice: Schema.Types.NumberType(),
        description: Schema.Types.StringType(),
        imageUrl: Schema.Types.StringType(),
        price: Schema.Types.NumberType(),
        title: Schema.Types.StringType()

    });

    const { initialState, reducer } = approvementReducer();
    const { convertToPrice } = convertorHelper();
    const [state, dispatch] = useReducer(reducer, initialState);

    const styles = {
        padding: 10
    }

    useEffect(() => {
        if (props.open) {
            setFormLoading(true);

            getApi<PictorialLanguagePriceViewModel>('/pictorialLanguage/GetPriceData?id=' + props.selectedId.current)
                .then((res: PictorialLanguagePriceViewModel) => {
                    dispatch({ type: "SET_FORM_VALUE", formValue: res });
                })
                .catch((error: any) => {
                    const message = error.response.status === 401 ? "Email or password is incorrect" : "Server error";

                    const notificationElement = getNotificationElement("Loading faild", message, "error");
                    toaster.push(notificationElement, { placement: 'topEnd' });
                })
                .finally(() => setFormLoading(false));
        }
    }, [props.open])

    const handleChange = (formData: PictorialLanguagePriceViewModel | any) => {
        dispatch({ type: "SET_SAVE_FORM_VALUE", formValue: formData });
    }

    const handleSubmit = () => {
        if (!formRef?.current?.check())
            return;

        setLoading(true);

        postApi<string>('/pictorialLanguage/ApprovePrice', state.formValue)
            .then((res: string) => {
                const notificationElement = getNotificationElement("Price successfully approved", "", "success");
                toaster.push(notificationElement, { placement: 'topEnd' });

                dispatch({ type: "CLEAR_FORM_VALUE" });
                props.onClose();
            })
            .catch((error: any) => {
                const message = error.response.status === 401 ? "Email or password is incorrect" : "Server error";

                const notificationElement = getNotificationElement("Insert faild", message, "error");
                toaster.push(notificationElement, { placement: 'topEnd' });
            })
            .finally(() => setLoading(false));
    }

    const setLoading = (loading: boolean) => {
        dispatch({ type: "SET_LOADING", loading });
    }

    const setFormLoading = (formLoading: boolean) => {
        dispatch({ type: "SET_FROM_LOADING", formLoading });
    }

    return <Drawer open={props.open} onClose={props.onClose}>
        <Drawer.Header>
            {state.formValue.title}
        </Drawer.Header>
        <Drawer.Body>
            {state.formLoading &&
                <Loader backdrop content="loading..." vertical style={{ zIndex: 12 }} />
            }
            <Form fluid ref={formRef} model={model} onChange={handleChange} onSubmit={handleSubmit} formValue={state.formValue}>
                <Grid fluid>
                    <Row>
                        <Col xs={24} sm={24} md={8} style={styles} />
                        <Col xs={24} sm={12} md={6} style={styles}>
                            <img
                                src={state.formValue.imageUrl}
                                alt={state.formValue.title}
                                style={{ width: '200%', borderRadius: '5%' }} />
                        </Col>
                        <Col xs={24} sm={24} md={8} style={styles} />
                        <Col xs={24} sm={24} md={24} style={styles}>
                            <label>Description:</label>
                            <Input readOnly value={state.formValue.description} />
                        </Col>
                        <Col xs={24} sm={24} md={24} style={styles}>
                            <label>Price:</label>
                            <Input readOnly value={convertToPrice(state.formValue.price)} />
                        </Col>
                        <Col xs={24} sm={24} md={24} style={styles}>
                            <Form.Group>
                                <Form.ControlLabel>Admin's price: </Form.ControlLabel>
                                <Form.Control name="adminPrice" />
                            </Form.Group>
                            <Form.Group>
                                <ButtonToolbar>
                                    <Button appearance="primary" type="submit" loading={state.loading}>Submit</Button>
                                </ButtonToolbar>
                            </Form.Group>
                        </Col>
                    </Row>
                </Grid>
            </Form>
        </Drawer.Body>
    </Drawer >
}

export default Approvement;