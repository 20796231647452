import React, { ReactElement, useReducer } from "react";
import { forwardRef, Ref, useEffect } from "react";
import { Button, Pagination, Table, useToaster, Grid, Row, Col, Checkbox } from "rsuite";
import apiHelper from "../../../Helper/apiHelper";
import { getNotificationElement } from "../../../Helper/notificationHelper";
import IPagedResultViewModel from "../../Interfaces/IPagedResultViewModel";
import CheckIcon from '@rsuite/icons/Check';
import CloseIcon from '@rsuite/icons/Close';
import gridReducer from "./Reducer";
import convertorHelper from "../../../Helper/convertorHelper";
import { IPagingFilterViewModel } from "../../Interfaces/IPagingFilterViewModel";

interface GridProps {
    children: React.ReactNode,
}

interface GridDataProps<T extends IPagingFilterViewModel> {
    apiUrl: string,
    filter: T,
    children: ReactElement<GridColumnProps | GridColumnButtonProps | GridCustomColumnProps>[],
    onSelectRow?: (rowData: any) => void,
    showSelectableColumn?: boolean,
}

interface GridColumnProps {
    title: string,
    width: number,
    dataKey: string,
    type: 'regular' | 'boolean' | 'price' | 'number' | 'date' | 'dateTime' | 'image',
    getValue?: (value: any) => string,
}

interface GridColumnButtonProps {
    title: string,
    width: number,
    text: string,
    onClick: (rowData: any) => void,
}

export interface GridCustomColumnProps {
    title: string,
    width: number,
}

const WriteCoGrid = (props: GridProps) => {
    return <div> {props.children}</div>;
}

const GridData = <T extends IPagingFilterViewModel>(props: GridDataProps<T>) => {
    const { postApi } = apiHelper();
    const { reducer, initialState } = gridReducer(props.filter);
    const toaster = useToaster();
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        fetchData();
    }, [state.filter.pageIndex, state.filter.pageSize])

    const setResult = (result: IPagedResultViewModel<any>) => {
        dispatch({ type: "SET_DATA", model: result });
    }

    const handleError = (error: any) => {
        dispatch({ type: "SET_ERROR" });

        const message = error.response.status === 401 ? "Authentication faild, please login again" : "Server error, please try again later";

        const notificationElement = getNotificationElement("Load faild", message, "error", handleRetry);
        toaster.push(notificationElement, { placement: 'topEnd' });
    }

    const fetchData = () => {
        setLoading(true);

        postApi<IPagedResultViewModel<any>>(props.apiUrl, state.filter)
            .then(setResult)
            .catch(handleError);
    };

    const handlePageSize = (limit: number) => {
        dispatch({ type: "ON_PAGE_SIZE_CHANGE", pageSize: limit });
    }

    const handleChangePage = (pageIndex: number) => {
        dispatch({ type: "ON_PAGE_CHANGE", pageIndex: pageIndex });
    }

    const setLoading = (loading: boolean) => {
        dispatch({ type: "SET_LOADING", loading });
    }

    const handleRetry = () => {
        fetchData();
    }

    const handleSelectRow = (rowData: any) => {
        dispatch({ type: "SET_SELECTED_ROW", rowData: rowData });

        if (props.onSelectRow)
            props.onSelectRow(rowData);
    }

    return <Grid fluid style={{ minHeight: '50vh' }}>
        <Row>
            <Col xs={24}>
                <Row>
                    <Col xs={24}>
                        <Table loading={state.loading} onRowClick={handleSelectRow} affixHorizontalScrollbar data={state.model?.result} autoHeight bordered hover showHeader>
                            {props.showSelectableColumn &&
                                <Table.Column width={50} align="center">
                                    <Table.HeaderCell> </Table.HeaderCell>
                                    <Table.Cell style={{ padding: 0 }}>
                                        {rowData =>
                                            <div style={{ lineHeight: '46px' }}>
                                                <Checkbox
                                                    inline
                                                    onChange={() => handleSelectRow(rowData)}
                                                    checked={state.selectedRowData == rowData}
                                                />
                                            </div>
                                        }
                                    </Table.Cell>
                                </Table.Column>
                            }
                            {props.children.map((i, index) =>
                                <Table.Column width={i.props.width} align="left" resizable>
                                    <Table.HeaderCell>{i.props.title}</Table.HeaderCell>
                                    {props.children[index]}
                                </Table.Column>
                            )}
                        </Table>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24}>
                        <Pagination
                            prev
                            next
                            first
                            last
                            ellipsis
                            boundaryLinks
                            maxButtons={5}
                            size="xs"
                            layout={['total', '-', 'limit', '|', 'pager', 'skip']}
                            total={state.totalCount}
                            limitOptions={[10, 30, 50]}
                            limit={state.filter.pageSize}
                            activePage={state.filter.pageIndex}
                            onChangePage={handleChangePage}
                            onChangeLimit={handlePageSize} />
                    </Col>
                </Row>
            </Col>
        </Row>
    </Grid >
}

const GridColumn = (props: GridColumnProps, ref: Ref<HTMLDivElement>) => {
    const { convertToPrice, convertToNumber, convertToDate, convertToDateTime } = convertorHelper();
    const key = props.dataKey.toString();

    const getValue = (value: any) => {
        if (props.getValue) {
            return props.getValue(value);
        }
        else
            return value;
    }

    switch (props.type) {
        case 'boolean':
            return <Table.Cell align="center" {...props} ref={ref} dataKey={key}>
                {
                    rowData => rowData[key] ? <CheckIcon /> : <CloseIcon />
                }
            </Table.Cell>
        case 'price':
            return <Table.Cell {...props} ref={ref} dataKey={key}>
                {
                    rowData => getValue(convertToPrice(rowData[key]))
                }
            </Table.Cell>
        case 'number':
            return <Table.Cell {...props} ref={ref} dataKey={key}>
                {
                    rowData => getValue(convertToNumber(rowData[key]))
                }
            </Table.Cell>
        case 'date':
            return <Table.Cell {...props} ref={ref} dataKey={key}>
                {
                    rowData => getValue(convertToDate(rowData[key]))
                }
            </Table.Cell>
        case 'dateTime':
            return <Table.Cell {...props} ref={ref} dataKey={key}>
                {
                    rowData => getValue(convertToDateTime(rowData[key]))
                }
            </Table.Cell>
        case 'image':
            return <Table.Cell {...props} ref={ref} dataKey={key}>
                {
                    rowData => <img src={getValue(rowData[key])} alt='blog' width="40" />
                }
            </Table.Cell>
        default:
            return <Table.Cell {...props} ref={ref} dataKey={key}>
                {
                    rowData => getValue(rowData[key])
                }
            </Table.Cell>
    }
}

const GridButtonColumn = (props: GridColumnButtonProps, ref: Ref<HTMLDivElement>) => {
    return <Table.Cell {...props} ref={ref}>
        {
            rowData => <Button size="xs" appearance="subtle" onClick={() => props.onClick(rowData)}>{props.text}</Button>
        }
    </Table.Cell>
}

WriteCoGrid.Data = GridData;
WriteCoGrid.Column = forwardRef(GridColumn);
WriteCoGrid.ButtonColumn = forwardRef(GridButtonColumn);

export default WriteCoGrid;