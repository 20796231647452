import { createRef, useEffect, useReducer } from "react";
import { Button, ButtonToolbar, Form, FormInstance, Loader, Modal, Placeholder, Schema, useToaster } from "rsuite";
import apiHelper from "../../Helper/apiHelper";
import { getNotificationElement } from "../../Helper/notificationHelper";
import LanguageSelector from "../Base/LanguagePicker/languageSelector";
import TranslationCategoryPickerSelector from "../Base/TranslationCategoryPicker/translationCategorySelector";
import ITranslationFeeModificationViewModel from "../Interfaces/TranslationFeeManager/ITranslationFeeModificationViewModel";
import defineReducer from "./Reducer/defineReducer";

interface Props {
    open: boolean,
    onClose: () => void,
    refreshData: () => void,
    id?: number,
}

const Define = (props: Props) => {
    const formRef = createRef<FormInstance>();
    const { postApi, getApi } = apiHelper();
    const toaster = useToaster();
    const { reducer, initialState } = defineReducer();
    const [state, dispatch] = useReducer(reducer, initialState);
    const isEditMode = (props.id !== undefined && props.id !== 0);

    const model = Schema.Model<ITranslationFeeModificationViewModel>({
        fromLanguageId: Schema.Types.NumberType().isRequired('Enter From language'),
        toLanguageId: Schema.Types.NumberType().isRequired('Enter From language'),
        price: Schema.Types.NumberType().isRequired('Enter Price'),
        translationCategoryId: Schema.Types.NumberType().isRequired('Select category'),
        id: Schema.Types.NumberType(),
    });

    useEffect(() => {
        if (props.open) {
            if (isEditMode) {
                getApi<ITranslationFeeModificationViewModel>('/translationFee/GetModification?id=' + props.id)
                    .then(handleGetResponse)
                    .catch(handleFormError)
                    .finally(() => setFormLoading(false));
            }
            else {
                dispatch({ type: "ON_ADD_MODE_FORM_VALUE" });
            }
        }
    }, [props.open]);

    const handleSubmit = () => {
        if (!formRef?.current?.check())
            return;

        setLoading(true);

        if (isEditMode) {
            postApi<string>('/translationFee/Update', state.formValue)
                .then(handleFormResponse)
                .catch(handleFormError)
                .finally(() => setLoading(false));
        }
        else {
            postApi<string>('/translationFee/Insert', state.formValue)
                .then(handleFormResponse)
                .catch(handleFormError)
                .finally(() => setLoading(false));
        }
    }

    const setLoading = (loading: boolean) => {
        dispatch({ type: "CHANGE_LOADING", loading: loading });
    }

    const setFormLoading = (loading: boolean) => {
        dispatch({ type: "CHANGE_FORM_LOADING", loading: loading });
    }

    const handleChange = (value: ITranslationFeeModificationViewModel | any) => {
        dispatch({ type: "ON_CHANGE_FORM_VALUE", formValue: value });
    }

    const handleGetResponse = (res: ITranslationFeeModificationViewModel) => {
        dispatch({ type: "ON_GET_FORM_VALUE", formValue: res });
    }

    const clearForm = () => {
        dispatch({ type: "CLEAR_FORM_VALUE" });
    }

    const onClose = () => {
        clearForm();
        props.onClose();
    }

    const handleFormResponse = (res: string) => {
        const notificationElement = getNotificationElement("Insert successful", "", "success");
        toaster.push(notificationElement, { placement: 'topEnd' });

        onClose();
        props.refreshData();
    }

    const handleFormError = (error: any) => {
        const message = error.response.status === 401 ? "Email or password is incorrect" : "Server error";

        const notificationElement = getNotificationElement("Insert faild", message, "error");
        toaster.push(notificationElement, { placement: 'topEnd' });
    }

    return <Modal backdrop="static" open={props.open} onClose={onClose}>
        <Modal.Header>
            <Modal.Title>
                {isEditMode ? "Edit" : "Add"} Translation fee
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {state.formLoading &&
                <Loader backdrop content="loading..." vertical style={{ zIndex: 12 }} />
            }
            <Form fluid ref={formRef} model={model} onChange={handleChange} onSubmit={handleSubmit} formValue={state.formValue}>
                <Form.Group>
                    <Form.ControlLabel>From: </Form.ControlLabel>
                <Form.Control name="fromLanguageId" accepter={LanguageSelector} isEditMode={isEditMode} block />
                </Form.Group>
                <Form.Group>
                    <Form.ControlLabel>To: </Form.ControlLabel>
                    <Form.Control name="toLanguageId" accepter={LanguageSelector} isEditMode={isEditMode} block />
                </Form.Group>
                <Form.Group>
                    <Form.ControlLabel>Price: </Form.ControlLabel>
                    <Form.Control name="price" />
                </Form.Group>
                <Form.Group>
                    <Form.Control name="translationCategoryId" accepter={TranslationCategoryPickerSelector} isEditMode={isEditMode} block />
                </Form.Group>
                <Form.Group>
                    <ButtonToolbar>
                        <Button appearance="primary" type="submit" loading={state.loading}>Submit</Button>
                    </ButtonToolbar>
                </Form.Group>
            </Form>
        </Modal.Body>
    </Modal>
}

export default Define;

