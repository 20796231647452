import WriteCoGrid from "../Base/Grid/grid";
import { ITranslationCategoryPagingFilterViewModel } from "../Interfaces/TranslationCategory/ITranslationCategoryPagingFilterViewModel";
import ITranslationCategoryViewModel from "../Interfaces/TranslationCategory/ITranslationCategoryViewModel";

interface Props {
    filter: ITranslationCategoryPagingFilterViewModel,
    onRowSelect: (rowData: ITranslationCategoryViewModel) => void,
}

const TranslationCategoryTable = (props: Props) => {
    const { Data, Column } = WriteCoGrid;

    const onRowSelect = (rowData: any) => {
        props.onRowSelect(rowData as ITranslationCategoryViewModel);
    }

    return <WriteCoGrid>
        <Data apiUrl="/translationCategory/Search" filter={props.filter} showSelectableColumn onSelectRow={onRowSelect}>
            <Column dataKey='id' title="Code" width={50} type="regular" />
            <Column dataKey="title" title="Title" width={100} type="regular" />
            <Column dataKey="isActive" title="Active" width={100} type="boolean" />
        </Data>
    </WriteCoGrid>
}

export default TranslationCategoryTable;