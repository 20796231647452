import { forwardRef, Ref, useReducer } from "react";
import { Popover, Table, Whisper } from "rsuite";
import convertorHelper from "../../Helper/convertorHelper";
import WriteCoForm from "../Base/Form/form";
import WriteCoGrid, { GridCustomColumnProps } from "../Base/Grid/grid";
import coreReducer from "./Reducer/coreReducer";

const Core = () => {

    const { reducer, initialState } = coreReducer();
    const [state, dispatch] = useReducer(reducer, initialState);
    const { convertToPrice } = convertorHelper();

    const BenefitCell = forwardRef((props: GridCustomColumnProps, ref: Ref<HTMLDivElement>) => {
        return <Table.Cell {...props} ref={ref}>
            {rowData =>
                <Whisper placement="auto" speaker={
                    <Popover>
                        <p>
                            <b>Admin's pice:</b> {convertToPrice(rowData.adminPrice)}
                        </p>
                        <p>
                            <b>Price:</b> {convertToPrice(rowData.price)}
                        </p>
                    </Popover>
                }>
                    <b style={{ color: rowData.benefit > 0 ? "green" : "red" }}>{(rowData.benefit > 0 ? "+" : "")}{convertToPrice(rowData.benefit)}</b>
                </Whisper>
            }
        </Table.Cell >

    });

    return <WriteCoForm type="Panel">
        <WriteCoForm.Body>
            <WriteCoGrid>
                <WriteCoGrid.Data apiUrl="/pictorialLanguageOrder/Search" filter={state.filter} showSelectableColumn>
                    <WriteCoGrid.Column dataKey='id' title="Code" width={100} type="regular" />
                    <WriteCoGrid.Column dataKey="title" title="Title" width={150} type="regular" />
                    <BenefitCell title="Beneift" width={150} />
                    <WriteCoGrid.Column dataKey="fileUrl" title="Image" width={200} type="image" />
                    <WriteCoGrid.Column dataKey="buyerName" title="Buyer" width={150} type="regular" />
                    <WriteCoGrid.Column dataKey="paymentState" title="State" width={100} type="regular" />
                </WriteCoGrid.Data>
            </WriteCoGrid>
        </WriteCoForm.Body>
    </WriteCoForm >
}

export default Core;