import { useReducer, useRef } from "react";
import { Stack } from "rsuite";
import WriteCoForm from "../Base/Form/form";
import translationFeeManagerReducer from "./Reducer";
import ToolbarButtons from "./toolbarButtons";
import ITranslationFeeViewModel from "../Interfaces/TranslationFeeManager/ITranslationFeeViewModel";
import TranslationFeesTable from "./translationFeesTable";

const ListManager = () => {
    const { Toolbar, Body } = WriteCoForm;
    const { reducer, initialState } = translationFeeManagerReducer();
    const [state, dispatch] = useReducer(reducer, initialState);
    const selectedId = useRef(0);

    const onRowSelect = (rowData: ITranslationFeeViewModel) => {
        selectedId.current = rowData.id;
    }

    const refreshData = () => {
        dispatch({ type: "REFRESH_DATA" });
    }

    const TranslationFeeToolbar = () => {
        return <Stack justifyContent="space-between">
            <ToolbarButtons selectedId={selectedId} refreshData={refreshData} />
        </Stack>
    }

    return <WriteCoForm type="Panel" panelHeader="Translation fee manager">
        <Toolbar>
            <TranslationFeeToolbar />
        </Toolbar>
        <Body>
            <TranslationFeesTable filter={state.filter} onRowSelect={onRowSelect} />
        </Body>
    </WriteCoForm>
}

export default ListManager;