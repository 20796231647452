import "./Css/rsuite.css"
import { Authorized, UnAuthorized } from './Components/Base/Authorization';
import { TokenContextProvider } from './Components/Contexts/TokenContext';
import LoginPage from './Pages/loginPage';
import MainPage from './Pages/mainPage';

function App() {
  return <TokenContextProvider>
    <Authorized>
      <MainPage />
    </Authorized>
    <UnAuthorized>
      <LoginPage />
    </UnAuthorized>
  </TokenContextProvider>
}

export default App;