import { ItemDataType } from "rsuite/esm/@types/common";

const translationSelectorReducer = () => {
    interface State {
        translationCategory: ItemDataType[],
        value?: number,
        loading: boolean,
    }

    type Action =
        | { type: 'CHANGE_TRANSLATION_CATEGORY'; translationCategory: ItemDataType[] }
        | { type: 'CHANGE_VALUE'; value: number }
        | { type: 'CLEAR_VALUE'; }

    const initialState: State = {
        translationCategory: [],
        loading: true,
    }

    const reducer = (state: State, action: Action): State => {
        switch (action.type) {
            case 'CHANGE_TRANSLATION_CATEGORY':
                return {
                    ...state,
                    translationCategory: action.translationCategory,
                    loading: false,
                }
            case 'CHANGE_VALUE':
                return {
                    ...state,
                    value: action.value,
                }
            case 'CLEAR_VALUE': {
                return {
                    ...state,
                    value: undefined,
                }
            }
        }
    }

    return {
        reducer,
        initialState
    }

}

export default translationSelectorReducer;