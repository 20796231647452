import React from "react";
import { Col, Divider, Grid, Panel, Placeholder, Row } from "rsuite";

interface FormProps {
    children: React.ReactNode,
    type: "Panel" | "Default",
    panelHeader?: string,
    panelCollapsible?: boolean,
    panelDefaultExpanded?: boolean,
}

interface ActionbarProps {
    children: React.ReactNode,
}

interface ToolbarProps {
    children: React.ReactNode,
}

const WriteCoForm = (props: FormProps) => {
    const PanelForm = () => <Panel header={props.panelHeader} collapsible={props.panelCollapsible} defaultExpanded={props.panelDefaultExpanded}><Grid fluid className="write-co-form">{props.children}</Grid></Panel>
    const DefaultForm = () => <div><Grid fluid className="write-co-form">{props.children}</Grid></div>

    switch (props.type) {
        case "Panel":
            return <PanelForm />

        case "Default":
            return <DefaultForm />
    }
}

const Actionbar = (props: ActionbarProps) => {
    return <div><Row><Col xs={24}>{props.children}</Col></Row></div>;
}

const Body = (props: ActionbarProps) => {
    return <div><Row><Col xs={24}>{props.children}</Col></Row></div>;
}
const Toolbar = (props: ToolbarProps) => {
    return <div><Row><Col xs={24}>{props.children}</Col></Row></div>;
}

WriteCoForm.Actionbar = Actionbar;
WriteCoForm.Body = Body;
WriteCoForm.Toolbar = Toolbar;

export default WriteCoForm;