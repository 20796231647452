import WriteCoGrid from "../Base/Grid/grid";
import IAdminPagingFilterViewModel from "../Interfaces/Admin/IAdminPagingFilterViewModel";
import IAdminViewModel from "../Interfaces/Admin/IAdminViewModel";

interface Props {
    filter: IAdminPagingFilterViewModel,
    onRowSelect: (rowData: IAdminViewModel) => void,
}

const AdminsTable = (props: Props) => {
    const { Data, Column } = WriteCoGrid;

    const onRowSelect = (rowData: any) => {
        props.onRowSelect(rowData as IAdminViewModel);
    }

    return <WriteCoGrid>
        <Data apiUrl="/admin/Search" filter={props.filter} showSelectableColumn onSelectRow={onRowSelect}>
            <Column dataKey='id' title="Code" width={100} type="regular" />
            <Column dataKey="firstName" title="FirstName" width={150} type="regular" />
            <Column dataKey="lastName" title="LastName" width={150} type="regular" />
            <Column dataKey="email" title="Email" width={200} type="regular" />
            <Column dataKey="isActive" title="IsActive" width={100} type="boolean" />
        </Data>
    </WriteCoGrid>
}

export default AdminsTable;