import { useEffect, useReducer } from "react";
import { InputPicker, Loader } from "rsuite";
import { ItemDataType } from "rsuite/esm/@types/common";
import translationCategoryHelper from "../../../Helper/translationCategoryHelper";
import translationSelectorReducer from "./Reducer/translationSelectorReducer";
import ProjectIcon from '@rsuite/icons/Project';

const TranslationCategoryPickerSelector = ({ onChange, isEditMode = false, value = 0, size }: any) => {
    const { getTranslationCategoryItemData } = translationCategoryHelper();
    const { reducer, initialState } = translationSelectorReducer();
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        getTranslationCategoryItemData()
            .then(handleResponse)
    }, [])


    useEffect(() => {
        if (value !== 0)
            dispatch({ type: "CHANGE_VALUE", value: value as number });
        else
            dispatch({ type: "CLEAR_VALUE" });
    }, [value]);

    const handleResponse = (model: ItemDataType[]) => {
        dispatch({ type: "CHANGE_TRANSLATION_CATEGORY", translationCategory: model });
    }

    const handleChangeValue = (value: any, event: React.SyntheticEvent) => {
        onChange(value);
    }

    return <InputPicker
        data={state.translationCategory}
        placeholder={!state.loading && "Select category"}
        block
        size={size}
        readOnly={isEditMode}
        value={state.value}
        onChange={handleChangeValue}
        renderValue={(value) => {
            if (state.loading)
                return <Loader center content="Loading..." />
            else
                return <div>
                    <span style={{ color: 'slategray' }}>
                        <ProjectIcon /> Category :
                    </span>{' '}
                    {state.translationCategory.find(i => i.value === value)?.label}
                </div>
        }}
    />
}

export default TranslationCategoryPickerSelector; 