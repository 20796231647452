import { ITranslatorModificationViewModel } from "../../Interfaces/TranslatorManager/ITranslatorModificationViewModel";

const defineReducer = () => {
    interface State {
        loading: boolean,
        formLoading: boolean,
        formValue: ITranslatorModificationViewModel,
    }

    type Action =
        | { type: 'CHANGE_LOADING'; loading: boolean }
        | { type: 'CHANGE_FORM_LOADING'; loading: boolean }
        | { type: 'ON_GET_FORM_VALUE'; formValue: ITranslatorModificationViewModel }
        | { type: 'CLEAR_FORM_VALUE'; }
        | { type: 'ON_ADD_MODE_FORM_VALUE'; }
        | { type: 'ON_CHANGE_FORM_VALUE'; formValue: ITranslatorModificationViewModel }

    const initialState: State = {
        loading: false,
        formLoading: true,
        formValue: {
            email: '',
            firstName: '',
            id: 0,
            isActive: true,
            lastName: '',
            translatorLanguages: [],
        }
    }

    const reducer = (state: State, action: Action): State => {
        switch (action.type) {
            case 'CHANGE_LOADING':
                return {
                    ...state,
                    loading: action.loading,
                }
            case 'CHANGE_FORM_LOADING':
                return {
                    ...state,
                    formLoading: action.loading,
                }
            case 'ON_GET_FORM_VALUE':
                return {
                    ...state,
                    formLoading: false,
                    formValue: action.formValue,
                }
            case 'CLEAR_FORM_VALUE':
                return initialState;
            case 'ON_CHANGE_FORM_VALUE':
                return {
                    ...state,
                    formValue: action.formValue,
                }
            case 'ON_ADD_MODE_FORM_VALUE':
                return {
                    ...state,
                    formLoading: false,
                    formValue: initialState.formValue,
                }
        }
    }

    return {
        reducer,
        initialState
    }

}

export default defineReducer;