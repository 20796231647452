import { useReducer, useRef } from "react";
import { Stack, Toggle } from "rsuite";
import WriteCoForm from "../Base/Form/form";
import ITranslationCategoryViewModel from "../Interfaces/TranslationCategory/ITranslationCategoryViewModel";
import translationCategoryManagerReducer from "./Reducer";
import ToolbarButtons from "./toolbarButtons";
import TranslationCategoryTable from "./translationCategoryTable";

const ListManager = () => {
    const { Toolbar, Body } = WriteCoForm;
    const { reducer, initialState } = translationCategoryManagerReducer();
    const [state, dispatch] = useReducer(reducer, initialState);
    const selectedData = useRef<ITranslationCategoryViewModel>();

    const onRowSelect = (rowData: ITranslationCategoryViewModel) => {
        selectedData.current = rowData;
    }

    const refreshData = () => {
        dispatch({ type: "REFRESH_DATA" });
    }

    const changeIncludeDeactive = (includeDeactive: boolean) => {
        dispatch({ type: "CHANGE_INCLUDE_DEACTIVE", includeDeactive: includeDeactive })
    }

    const handleToggleChange = (checked: boolean) => {
        changeIncludeDeactive(checked);
    }

    const TranslationCategoryToolbar = () => {
        return <Stack justifyContent="space-between">
            <ToolbarButtons selectedData={selectedData} refreshData={refreshData} />
            <Toggle checked={state.filter.includeDeactive} onChange={handleToggleChange} checkedChildren="Include deactive" unCheckedChildren="Active" />
        </Stack>
    }

    return <WriteCoForm type="Panel" panelHeader="Translation category manager">
        <Toolbar>
            <TranslationCategoryToolbar />
        </Toolbar>
        <Body>
            <TranslationCategoryTable filter={state.filter} onRowSelect={onRowSelect} />
        </Body>
    </WriteCoForm>
}

export default ListManager;