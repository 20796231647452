import ITranslationWorkflowViewModel from "../Components/Interfaces/TranslationOrderManager/ITranslationWorkflowViewModel";
import { TranslationRequestWorkflowState } from "./Enumeration/TranslationRequestWorkflowState";
import { TranslationWorkflowState } from "./Enumeration/TranslationWorkflowState";

const translationWorkflowHelper = () => {

    const getStateString = (state: TranslationWorkflowState) => {
        switch (state) {
            case TranslationWorkflowState.CheckUserDataState:
                return "Create";
            case TranslationWorkflowState.Complete:
                return "Complete";
            case TranslationWorkflowState.End:
                return "End";
            case TranslationWorkflowState.SendTranlatorState:
                return "Send to translators";
            case TranslationWorkflowState.Translating:
                return "Translating";
            case TranslationWorkflowState.UserCheckWordCountState:
                return "Send to user for changing data";
            case TranslationWorkflowState.CheckTranslateByAdminState:
                return "Check translate by admin"
            default:
                return state;
        }
    }

    const getRequestStateString = (state: TranslationRequestWorkflowState) => {
        switch (state) {
            case TranslationRequestWorkflowState.Request:
                return "Request";
            case TranslationRequestWorkflowState.Complete:
                return "Complete";
            case TranslationRequestWorkflowState.End:
                return "End";
            case TranslationRequestWorkflowState.SendAdminRequest:
                return "Send admin"
            default:
                return state;
        }
    }

    const getCurrentStatus = (state: TranslationWorkflowState) => {
        switch (state) {
            case TranslationWorkflowState.End:
                return "error";
            case TranslationWorkflowState.Complete:
                return "finish";
            default:
                return "process";
        }
    }

    const getGeneralState = (lastData: ITranslationWorkflowViewModel, data: ITranslationWorkflowViewModel[]) => {
        if (lastData.state === TranslationWorkflowState.End)
            return data[data.length - 2].generalState - 1;
        else
            return lastData.generalState - 1;
    }

    const getProgressStatus = (state: TranslationWorkflowState) => {
        switch (state) {
            case TranslationWorkflowState.End:
                return "fail";
            case TranslationWorkflowState.Complete:
                return "success";
            default:
                return "active";
        }
    }

    return {
        getStateString,
        getRequestStateString,
        getCurrentStatus,
        getGeneralState,
        getProgressStatus,
    }
}

export default translationWorkflowHelper;