import WriteCoGrid from "../Base/Grid/grid";
import { ITranslatorPagingFilterViewModel } from "../Interfaces/TranslatorManager/ITranslatorPagingFilterViewModel";
import ITranslatorViewModel from "../Interfaces/TranslatorManager/ITranslatorViewModel";

interface Props {
    filter: ITranslatorPagingFilterViewModel,
    onRowSelect: (rowData: ITranslatorViewModel) => void,
}

const TranslatorsTable = (props: Props) => {
    const { Data, Column } = WriteCoGrid;

    const onRowSelect = (rowData: any) => {
        props.onRowSelect(rowData as ITranslatorViewModel);
    }

    return <WriteCoGrid>
        <Data apiUrl="/translator/Search" filter={props.filter} showSelectableColumn onSelectRow={onRowSelect}>
            <Column dataKey='id' title="Code" width={100} type="regular" />
            <Column dataKey="firstName" title="FirstName" width={150} type="regular" />
            <Column dataKey="lastName" title="LastName" width={150} type="regular" />
            <Column dataKey="email" title="Email" width={200} type="regular" />
            <Column dataKey="isActive" title="IsActive" width={100} type="boolean" />
        </Data>
    </WriteCoGrid>
}

export default TranslatorsTable;