import { createRef, useEffect, useReducer } from "react";
import { Button, ButtonToolbar, Form, FormInstance, Loader, Schema, toaster } from "rsuite";
import apiHelper from "../../Helper/apiHelper";
import { EmailBodyParameters } from "../../Helper/Enumeration/EmailBodyParameters";
import { getNotificationElement } from "../../Helper/notificationHelper";
import HtmlEditor from "../Base/Editor/htmlEditor";
import { IConfigurationSignupConfirmViewModel } from "../Interfaces/Configuration/IConfigurationSignupConfirmViewModel";
import signupConfirmationEmailConfigReducer from "./Reducer/signupConfirmationEmailConfigReducer";


const SignupConfirmationEmailConfig = () => {

    const formRef = createRef<FormInstance>();
    const { getApi, postApi } = apiHelper();
    const { reducer, initialState } = signupConfirmationEmailConfigReducer();
    const [state, dispatch] = useReducer(reducer, initialState);

    const model = Schema.Model<IConfigurationSignupConfirmViewModel>({
        body: Schema.Types.StringType(),
        subject: Schema.Types.StringType(),
    });

    const bookmarkOptions: string[] = [
        EmailBodyParameters[EmailBodyParameters.UserFullName],
        EmailBodyParameters[EmailBodyParameters.ExpiredDate],
    ];

    const handleChange = (res: IConfigurationSignupConfirmViewModel | any) => {
        const data = (res as IConfigurationSignupConfirmViewModel)
        dispatch({ type: "ON_GET_DATA", data: data });
    }

    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {
        getApi<IConfigurationSignupConfirmViewModel>('/Configuration/GetSignupConfirmationEmail')
            .then((res: IConfigurationSignupConfirmViewModel) => {
                dispatch({ type: "ON_GET_DATA", data: res });
            })
            .catch((error: any) => {
                const message = error.response.status === 401 ? "Email or password is incorrect" : "Server error";

                const notificationElement = getNotificationElement("Load faild", message, "error");
                toaster.push(notificationElement, { placement: 'topEnd' });
            })
            .finally(() => setLoading(false));
    }

    const handleSubmit = () => {
        if (!formRef?.current?.check())
            return;

        setButtonLoading(true);

        postApi<boolean>('/Configuration/UpdateSignupConfirmationEmail', state.data)
            .then((res: boolean) => {
                const notificationElement = getNotificationElement("Update successful", "", "success");
                toaster.push(notificationElement, { placement: 'topEnd' });
            })
            .catch((error: any) => {
                const message = error.response.status === 401 ? "Email or password is incorrect" : "Server error";

                const notificationElement = getNotificationElement("Update faild", message, "error", handleRetry);
                toaster.push(notificationElement, { placement: 'topEnd' });
            })
            .finally(() => setButtonLoading(false))
    }

    const setLoading = (loading: boolean) => {
        dispatch({ type: "SET_LOADING", loading: loading });
    }

    const setButtonLoading = (loading: boolean) => {
        dispatch({ type: "SET_BUTTON_LOADING", loading: loading });
    }

    const handleRetry = () => {
        loadData();
    }

    return <>
        {(state.loading || !!!state.data) && <Loader backdrop content="loading..." vertical style={{ zIndex: 12 }} />}
        {!!state.data &&
            <Form fluid ref={formRef} model={model} formValue={state.data} onSubmit={handleSubmit} onChange={handleChange}>
                <Form.Group>
                    <Form.Control name="subject" placeholder="Subject" />
                </Form.Group>
                <Form.Group>
                    <Form.Control name="body" accepter={HtmlEditor} bookmarkOptions={bookmarkOptions} />
                </Form.Group>
                <Form.Group>
                    <ButtonToolbar>
                        <Button appearance="primary" type="submit" loading={state.buttonLoading}>Save</Button>
                    </ButtonToolbar>
                </Form.Group>
            </Form>}
    </>
}

export default SignupConfirmationEmailConfig;