import { PictorialLanguagePagingFilterViewModel } from "../../Interfaces/PictorialLanguage/PictorialLanguagePagingFilterViewModel";

const coreReducer = () => {

    interface State {
        filter: PictorialLanguagePagingFilterViewModel,
        selectedId: number,
    }

    const initialState: State = {
        filter: {
            pageIndex: 1,
            pageSize: 10,
            sold: false,
        },
        selectedId: 0,
    }

    type Action = { type: 'REFEREHS_TABLE'; }
        | { type: 'CHANGE_SOLD', sold: boolean; }



    const reducer = (state: State, action: Action): State => {
        switch (action.type) {
            case "REFEREHS_TABLE":
                return initialState
            case "CHANGE_SOLD":
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        sold: action.sold,
                    }
                };
        }
    }

    return {
        initialState,
        reducer
    }
}

export default coreReducer;