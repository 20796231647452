import { IconButton, Stack } from "rsuite";
import toolbarReducer from "./Reducer/toolbarReducer";
import { MutableRefObject, useReducer } from "react";
import GridIcon from '@rsuite/icons/Grid';
import { TranslationWorkflowManager } from "../TranslationWorkflowManager";
import ITranslationOrderSearchResultViewModel from "../Interfaces/TranslationOrderManager/ITranslationOrderSearchResultViewModel";

interface Props {
    refreshData: () => void,
    selectedData: MutableRefObject<ITranslationOrderSearchResultViewModel | undefined>,
}

const ToolbarButtons = (props: Props) => {
    const { reducer, initialState } = toolbarReducer();
    const [state, dispatch] = useReducer(reducer, initialState);

    const handleDetailClick = () => {
        if (props.selectedData.current && props.selectedData.current.id !== 0)
            dispatch({ type: "SHOW_FORM", id: props.selectedData.current.id });
    }

    const onClose = () => {
        dispatch({ type: "CLOSE_FORM" });
    }

    return <Stack spacing={6} justifyContent="flex-start">
        <IconButton onClick={handleDetailClick} size="sm" icon={<GridIcon />}></IconButton>
        <TranslationWorkflowManager onClose={onClose} open={state.showForm} translationOrderId={state.selectedId} refreshData={props.refreshData} />
    </Stack>
}

export default ToolbarButtons;