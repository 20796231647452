import { createRef, useEffect, useReducer } from "react";
import { Button, ButtonToolbar, Col, Form, FormInstance, Grid, Loader, Modal, Row, Schema, useToaster } from "rsuite";
import apiHelper from "../../Helper/apiHelper";
import { getNotificationElement } from "../../Helper/notificationHelper";
import ImageUploader from "../Base/FileManager/imageUploader";
import { PictorialLanguageCreationViewModel } from "../Interfaces/PictorialLanguage/PictorialLanguageCreationViewModel";
import defineReducer from "./Reducer/defineReducer";

interface Props {
    open: boolean,
    onClose: () => void,
    refreshData: () => void,
}

const Define = (props: Props) => {
    const addItemformRef = createRef<FormInstance>();
    const { reducer, initialState } = defineReducer();
    const [state, dispatch] = useReducer(reducer, initialState);
    const { postApi } = apiHelper();
    const toaster = useToaster();

    const formModel = Schema.Model<PictorialLanguageCreationViewModel>({
        id: Schema.Types.NumberType(),
        title: Schema.Types.StringType().isRequired('Title is required.'),
        description: Schema.Types.StringType().isRequired('Description is required'),
        price: Schema.Types.NumberType().isRequired('Price is required'),
        tempFileName: Schema.Types.StringType().isRequired('Image is required')
    });

    useEffect(() => {
        if (props.open)
            dispatch({ type: "CLEAR_FORM_VALUE" });
    }, [props.open])

    const handleResult = (res: string) => {
        props.onClose();
        props.refreshData();
    }

    const handleError = (error: any) => {
        const message = error.response.status === 401 ? "Email or password is incorrect" : "Server error";

        const notificationElement = getNotificationElement("Save failed", message, "error");
        toaster.push(notificationElement, { placement: 'topEnd' });
    }

    const handleAddFormChange = (value: PictorialLanguageCreationViewModel | any) => {
        dispatch({ type: 'SET_ADD_FORM_DATA', formData: value });
    }

    const setLoading = (loading: boolean) => {
        dispatch({ type: "SET_LOADING", loading });
    }

    const handleSaveNewItem = () => {
        if (!addItemformRef?.current?.check())
            return;

        setLoading(true);

        postApi<string>('/pictorialLanguage/Insert', state.formData)
            .then(handleResult)
            .catch(handleError)
            .finally(() => setLoading(false));
    }

    return <Modal size='md' open={props.open} onClose={props.onClose} backdrop="static" >
        <Modal.Header>
            <Modal.Title>Add new Item</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form fluid model={formModel} ref={addItemformRef} onChange={handleAddFormChange} onSubmit={handleSaveNewItem} formValue={state.formData}>
                <Grid fluid>
                    <Row>
                        <Col xs={6}>
                            <Form.Group>
                                <Form.Control name="tempFileName" accepter={ImageUploader} />
                            </Form.Group>
                        </Col>
                        <Col xs={18}>
                            <Form.Group>
                                <Form.ControlLabel>Title:</Form.ControlLabel>
                                <Form.Control name="title" />
                            </Form.Group>
                            <Form.Group>
                                <Form.ControlLabel>Price:</Form.ControlLabel>
                                <Form.Control name="price" />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24}>
                            <Form.Group>
                                <Form.ControlLabel>Description:</Form.ControlLabel>
                                <Form.Control name="description" />
                            </Form.Group>
                        </Col>
                    </Row>
                </Grid>
                <Form.Group>
                    <ButtonToolbar>
                        <Button loading={state.loading} type="submit" appearance="primary">
                            Save
                        </Button>
                    </ButtonToolbar>
                </Form.Group>
            </Form>
        </Modal.Body>
    </Modal>
}

export default Define;