import { PictorialLanguagePriceViewModel } from "../../Interfaces/PictorialLanguage/PictorialLanguagePriceViewModel";

const approvementReducer = () => {

    interface State {
        formValue: PictorialLanguagePriceViewModel,
        loading: boolean,
        formLoading: boolean,
    }

    const initialState: State = {
        formValue: {
            adminPrice: 0,
            id: 0,
            description: '',
            imageUrl: '',
            price: 0,
            title: ''
        },
        formLoading: true,
        loading: false,
    }

    type Action = { type: 'SET_FORM_VALUE', formValue: PictorialLanguagePriceViewModel }
        | { type: 'CLEAR_FORM_VALUE' }
        | { type: 'SET_SAVE_FORM_VALUE', formValue: PictorialLanguagePriceViewModel }
        | { type: 'SET_LOADING', loading: boolean }
        | { type: 'SET_FROM_LOADING', formLoading: boolean }

    const reducer = (state: State, action: Action) => {
        switch (action.type) {
            case 'SET_FORM_VALUE':
                return {
                    ...state,
                    formValue: action.formValue
                }
            case "SET_SAVE_FORM_VALUE":
                return {
                    ...state,
                    formValue: action.formValue
                }
            case "SET_LOADING":
                return {
                    ...state,
                    loading: action.loading
                }
            case "SET_FROM_LOADING":
                return {
                    ...state,
                    formLoading: action.formLoading
                }
            case "CLEAR_FORM_VALUE":
                return initialState
        }
    }

    return {
        initialState,
        reducer
    }
}

export default approvementReducer;