import { ConfigurationKeys } from "../../../Helper/Enumeration/ConfigurationKeys";

const configurationManagerReducer = () => {
    interface State {
        key: ConfigurationKeys,
    }

    const initialState: State = {
        key: ConfigurationKeys.Email,
    }

    type Action =
        | { type: 'ON_CHANGE_KEY'; key: ConfigurationKeys }

    const reducer = (state: State, action: Action): State => {
        switch (action.type) {
            case 'ON_CHANGE_KEY':
                return {
                    ...state,
                    key: action.key,
                }
        }
    }

    return {
        reducer,
        initialState
    }

}

export default configurationManagerReducer;