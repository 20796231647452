import { useReducer } from "react";
import { Nav } from "rsuite";
import { OuterExpressionKinds } from "typescript";
import { ConfigurationKeys } from "../../Helper/Enumeration/ConfigurationKeys";
import WriteCoForm from "../Base/Form/form";
import EmailConfig from "./emailConfig";
import ForgetPasswordConfirmationEmailConfig from "./forgetPasswordConfirmationEmailConfig";
import configurationManagerReducer from "./Reducer";
import SignupConfirmationEmailConfig from "./signupConfirmationEmailConfig";
import TranslatingEmailConfig from "./translatingEmailConfig";
import TranslatorEndEmailConfig from "./translatorEndEmailConfig";
import TranslatorRequestEmailConfig from "./translatorRequestEmailConfig";
import UserDataEmailConfig from "./userDataEmailConfig";
import SuccessfulPaymentEmailConfig from "./successfulPaymentEmailConfig";

const Manager = () => {
    const { Toolbar, Body } = WriteCoForm;
    const { reducer, initialState } = configurationManagerReducer();
    const [state, dispatch] = useReducer(reducer, initialState);

    const handleSelect = (value: string) => {
        const keyEnum: ConfigurationKeys = Number(value);
        dispatch({ type: 'ON_CHANGE_KEY', key: keyEnum });
    }

    const Detail = () => {
        switch (state.key) {
            case ConfigurationKeys.Email:
                return <EmailConfig />
            case ConfigurationKeys.TranslatingEmail:
                return <TranslatingEmailConfig />
            case ConfigurationKeys.TranslatorEndEmail:
                return <TranslatorEndEmailConfig />
            case ConfigurationKeys.TranslatorRequestEmail:
                return <TranslatorRequestEmailConfig />
            case ConfigurationKeys.UserDataEmail:
                return <UserDataEmailConfig />
            case ConfigurationKeys.SignupConfirmation:
                return <SignupConfirmationEmailConfig />
            case ConfigurationKeys.ForgetPasswordConfirmation:
                return <ForgetPasswordConfirmationEmailConfig />
            case ConfigurationKeys.SuccessfulPaymentEmail:
                return <SuccessfulPaymentEmailConfig/>
            default:
                return <></>
        }
    }

    return <WriteCoForm type="Panel" panelHeader="Configuarion manager">
        <Toolbar>
            <Nav appearance="subtle" activeKey={state.key.toString()} onSelect={handleSelect} style={{ marginBottom: 20 }}>
                <Nav.Item eventKey={ConfigurationKeys.Email.toString()}>Email config</Nav.Item>
                <Nav.Item eventKey={ConfigurationKeys.TranslatingEmail.toString()}>Translating email</Nav.Item>
                <Nav.Item eventKey={ConfigurationKeys.TranslatorEndEmail.toString()}>Translator end email</Nav.Item>
                <Nav.Item eventKey={ConfigurationKeys.TranslatorRequestEmail.toString()}>Translator request email</Nav.Item>
                <Nav.Item eventKey={ConfigurationKeys.UserDataEmail.toString()}>User data email</Nav.Item>
                <Nav.Item eventKey={ConfigurationKeys.SignupConfirmation.toString()}>Signup confirmation email</Nav.Item>
                <Nav.Item eventKey={ConfigurationKeys.ForgetPasswordConfirmation.toString()}>Forget password confirmation email</Nav.Item>
                <Nav.Item eventKey={ConfigurationKeys.SuccessfulPaymentEmail.toString()}>Successful payment email</Nav.Item>
            </Nav>
        </Toolbar>
        <Body>
            <Detail />
        </Body>
    </WriteCoForm>
}

export default Manager;