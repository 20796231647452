import { Input, InputGroup, Stack } from "rsuite";
import WriteCoForm from "../Base/Form/form";
import ToolbarButtons from "./toolbarButtons";
import SearchIcon from '@rsuite/icons/Search';
import DesignersTable from "./designersTable";
import listManagerReducer from "./Reducer/listManagerReducer";
import { createRef, useReducer, useRef } from "react";
import IDesignerViewModel from "../Interfaces/DesignerManager/IDesignerViewModel";

interface Props {

}
const ListManager = (props: Props) => {
    const { Toolbar, Body } = WriteCoForm;
    const { reducer, initialState } = listManagerReducer();
    const searchRef = createRef<HTMLInputElement>();
    const [state, dispatch] = useReducer(reducer, initialState);
    const selectedId = useRef(0);

    const handleSearchClick = () => {
        if (searchRef.current)
            dispatch({ type: "CHANGE_NAME_FILTER", nameFilter: searchRef.current.value });
    }

    const onRowSelect = (rowData: IDesignerViewModel) => {
        selectedId.current = rowData.id;
    }

    const refreshData = () => {
        dispatch({ type: "REFRESH_DATA" });
    }

    const DesignerToolbar = () => {
        return <>
            <Stack justifyContent="space-between">
                <ToolbarButtons selectedId={selectedId} refreshData={refreshData} />
                <Stack spacing={6} justifyContent="flex-end">
                    <InputGroup size="sm">
                        <Input defaultValue={state.nameFilter} ref={searchRef} />
                        <InputGroup.Button onClick={handleSearchClick}>
                            <SearchIcon />
                        </InputGroup.Button>
                    </InputGroup>
                </Stack>
            </Stack>
        </>
    }


    return <WriteCoForm type="Panel">
        <Toolbar>
            <DesignerToolbar />
        </Toolbar>
        <Body>
            <DesignersTable filter={state.filter} onRowSelect={onRowSelect} />
        </Body>
    </WriteCoForm>
}

export default ListManager;