import { IConfigurationSuccessfulPaymentViewModel } from "../../Interfaces/Configuration/IConfigurationSuccessfulPaymentViewModel";

const SuccessfulPaymentConfigReducer = () => {
    interface State {
        loading: boolean,
        buttonLoading: boolean,
        data?: IConfigurationSuccessfulPaymentViewModel,
    }

    const initialState: State = {
        buttonLoading: false,
        loading: true
    }

    type Action =
        | {type: 'ON_GET_DATA'; data: IConfigurationSuccessfulPaymentViewModel}
        | {type: 'SET_LOADING'; loading: boolean}
        | {type: 'SET_BUTTON_LOADING', loading: boolean}

    const reducer = (state: State, action: Action) => {
        switch(action.type){
            case 'ON_GET_DATA' :
                return{
                ...state,
                data: action.data,
                loading:  false
                }
            case 'SET_LOADING':
                return{
                    ...state,
                    loading: action.loading
                }
            case 'SET_BUTTON_LOADING':
                return{
                    ...state,
                    buttonLoading: action.loading
                }
        }
    }
    return{
        reducer,
        initialState
    }
}

export default SuccessfulPaymentConfigReducer;