import IConfigurationTranslatingEmailViewModel from "../../Interfaces/Configuration/IConfigurationTranslatingEmailViewModel";

const translatingEmailConfigReducer = () => {
    interface State {
        loading: boolean,
        buttonLoading: boolean,
        data?: IConfigurationTranslatingEmailViewModel,
    }

    const initialState: State = {
        loading: true,
        buttonLoading: false,
    }

    type Action =
        | { type: 'ON_GET_DATA'; data: IConfigurationTranslatingEmailViewModel }
        | { type: 'SET_LOADING'; loading: boolean }
        | { type: 'SET_BUTTON_LOADING'; loading: boolean }

    const reducer = (state: State, action: Action): State => {
        switch (action.type) {
            case 'ON_GET_DATA':
                return {
                    ...state,
                    data: action.data,
                    loading: false,
                }
            case 'SET_LOADING':
                return {
                    ...state,
                    loading: action.loading,
                }
            case 'SET_BUTTON_LOADING':
                return {
                    ...state,
                    buttonLoading: action.loading,
                }
        }
    }

    return {
        reducer,
        initialState
    }

}

export default translatingEmailConfigReducer;