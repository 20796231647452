import { FlexboxGrid, Panel } from "rsuite"
import Login from "../Components/Login/login"

const LoginPage = () => {
    return <div >
        <FlexboxGrid justify="center">
            <Panel header="WriteCo Admin panel - Login" bordered style={{ top: '30%', position: 'absolute' }}>
                <Login />
            </Panel>
        </FlexboxGrid >
    </div >
}

export default LoginPage