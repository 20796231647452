const toolbarReducer = () => {
    interface State {
        showForm: boolean,
        selectedId?: number,
    }

    type Action =
        | { type: 'SHOW_FORM'; selectedId?: number }
        | { type: 'CLOSE_FORM'; }

    const initialState: State = {
        showForm: false,
    }

    const reducer = (state: State, action: Action): State => {
        switch (action.type) {
            case 'SHOW_FORM':
                return {
                    ...state,
                    showForm: true,
                    selectedId: action.selectedId,
                }
            case 'CLOSE_FORM':
                return {
                    ...state,
                    showForm: false,
                }
        }
    }

    return {
        reducer,
        initialState
    }

}

export default toolbarReducer;