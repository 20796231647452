import { Input, InputGroup, Stack } from "rsuite";
import WriteCoForm from "../Base/Form/form";
import BlogsTable from "./blogsTable";
import SearchIcon from '@rsuite/icons/Search'
import { createRef, useReducer, useRef } from "react";
import blogManagerReducer from "./Reducer/listManagerReducer";
import ToolbarButtons from "./toolbarButtons";
import { IBlogViewModel } from "../Interfaces/Blog/IBlogViewModel";

export interface Props {

}

const ListManager = (props: Props) => {

    const { Toolbar, Body } = WriteCoForm;
    const { reducer, initialState } = blogManagerReducer();
    const searchRef = createRef<HTMLInputElement>();
    const [state, dispatch] = useReducer(reducer, initialState);
    const selectedId = useRef(0);

    const handleSearchClick = () => {
        if (searchRef.current)
            dispatch({ type: "CHANGE_NAME_FILTER", nameFilter: searchRef.current.value });
    }

    const onRowSelect = (rowData: IBlogViewModel) => {
        selectedId.current = rowData.id;
    }

    const refreshData = () => {
        dispatch({ type: "REFRESH_DATA" });
    }

    const BlogToolbar = () => {
        return <>
            <Stack justifyContent="space-between">
                <ToolbarButtons selectedId={selectedId} refreshData={refreshData} />
                <Stack spacing={6} justifyContent="flex-end">
                    <InputGroup size="sm">
                        <Input defaultValue={state.nameFilter} ref={searchRef} />
                        <InputGroup.Button onClick={handleSearchClick}>
                            <SearchIcon />
                        </InputGroup.Button>
                    </InputGroup>
                </Stack>
            </Stack>
        </>
    }

    return <WriteCoForm type="Panel">
        <Toolbar>
            <BlogToolbar />
        </Toolbar>
        <Body>
            <BlogsTable filter={state.filter} onRowSelect={onRowSelect} />
        </Body>
    </WriteCoForm>

}

export default ListManager;