import { IGuidViewModel } from "../Components/Interfaces/IGuidViewModel";

const convertorHelper = () => {

    const convertToPrice = (value: any) => {
        if (!!value)
            return "£" + value.toLocaleString(undefined, { maximumFractionDigits: 3 })
        else if (value === 0)
            return "£0"
        else
            return "-"
    }

    const convertToNumber = (value: any) => {
        if (!!value)
            return value.toLocaleString(undefined, { maximumFractionDigits: 3 });
        else if (value === 0)
            return "0"
        else
            return "-"
    }

    const convertToGuidViewModel = (value: string) => {
        const res: IGuidViewModel = {
            guidString: value,
        }

        return res;
    }

    const convertToDate = (value: any) => {
        if (value)
            return new Date(value).toLocaleDateString();
        else
            return '-'
    }

    const convertToDateTime = (value: any) => {
        if (value) {
            const _date = new Date(value);
            return _date.toLocaleDateString() + " " + _date.toLocaleTimeString();
        }
        else
            return ''
    }

    return {
        convertToPrice,
        convertToNumber,
        convertToGuidViewModel,
        convertToDate,
        convertToDateTime,
    }
}

export default convertorHelper;