import { MutableRefObject, useReducer } from "react";
import { IconButton, Stack, useToaster } from "rsuite";
import apiHelper from "../../Helper/apiHelper";
import ITranslationCategoryViewModel from "../Interfaces/TranslationCategory/ITranslationCategoryViewModel";
import toolbarReducer from "./Reducer/toolbarReducer";
import PlusIcon from '@rsuite/icons/Plus';
import EditIcon from '@rsuite/icons/Edit'
import Define from "./define";

interface Props {
    refreshData: () => void,
    selectedData: MutableRefObject<ITranslationCategoryViewModel | undefined>,
}

const ToolbarButtons = (props: Props) => {
    const { reducer, initialState } = toolbarReducer();
    const [state, dispatch] = useReducer(reducer, initialState);
    const { postApi } = apiHelper();
    const toaster = useToaster();

    const handleEditClick = () => {
        if (props.selectedData && props.selectedData.current && props.selectedData.current.id !== 0)
            dispatch({ type: "SHOW_FORM", selectedId: props.selectedData.current.id });
    }

    const handleAddClick = () => {
        dispatch({ type: "SHOW_FORM", selectedId: 0 });
    }

    const onClose = () => {
        dispatch({ type: "CLOSE_FORM" });
    }

    const handleFormResponse = () => props.refreshData();

    return <Stack spacing={6} justifyContent="flex-start">
        <IconButton onClick={handleAddClick} size="sm" appearance="primary" icon={<PlusIcon />}></IconButton>
        <IconButton onClick={handleEditClick} size="sm" icon={<EditIcon />}></IconButton>
        <Define onClose={onClose} open={state.showForm} id={state.selectedId} refreshData={handleFormResponse} />
    </Stack>
}

export default ToolbarButtons;